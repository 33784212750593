import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuthStore from 'stores/useAuthStore';

export default (hasLogin: boolean) => {
  const { pathname } = useLocation();
  const gotToken = pathname.includes("middlepage");

  const setAuth = useAuthStore(state => state.setAuth);
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasLogin && !gotToken) {
      navigate("/");
    }
  }, [pathname, navigate]);

  useEffect(() => {
    setAuth({ path: pathname });
  }, [pathname]);
}