import React from 'react';
import styled from "styled-components";
import LeaveLogo from "assets/logo.png";
import LeaveLogoWhite from "assets/logo_white.png";

import { getVersion } from "utils";

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60px;
  }

  span {
    margin-left: 10px;
    font-size: 26px;
    font-weight: 500;
  }
`

export default ({ color }: {
  color: string
}) => {
  return (
    <React.Fragment>
      <LogoWrapper>
        {
          color === "black" ? (
            <React.Fragment>
              <img src={LeaveLogo} alt="logo" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <img src={LeaveLogoWhite} alt="logo" />
            </React.Fragment>
          )
        }
      </LogoWrapper>

      <span style={{fontSize: "12px", fontWeight: "600", marginTop: "5px", color: color, textAlign: "center"}}>Version {getVersion()} </span>
    </React.Fragment>
  );
}