import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 36/36;
  height: auto;
  width: auto;
`;

export default ({ color = "black" }: {
  color?: string
}) => (
  <Wrapper
    width={36} height={36}
    viewBox="0 0 36 36"
    fill="none"
  >
    <g>
      <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M11.3555 3.9375C10.4272 3.9375 9.53697 4.30625 8.88059 4.96263C8.22422 5.619 7.85547 6.50924 7.85547 7.4375V28.4375C7.85547 29.3658 8.22422 30.256 8.88059 30.9124C9.53697 31.5688 10.4272 31.9375 11.3555 31.9375H25.3555C26.2837 31.9375 27.174 31.5688 27.8303 30.9124C28.4867 30.256 28.8555 29.3658 28.8555 28.4375V13.412C28.8553 12.4838 28.4864 11.5937 27.83 10.9375L21.8555 4.963C21.1992 4.30658 20.3092 3.9377 19.381 3.9375H11.3555ZM14.8555 21.4375C14.8555 20.9734 14.6711 20.5283 14.3429 20.2001C14.0147 19.8719 13.5696 19.6875 13.1055 19.6875C12.6413 19.6875 12.1962 19.8719 11.868 20.2001C11.5398 20.5283 11.3555 20.9734 11.3555 21.4375V26.6875C11.3555 27.1516 11.5398 27.5967 11.868 27.9249C12.1962 28.2531 12.6413 28.4375 13.1055 28.4375C13.5696 28.4375 14.0147 28.2531 14.3429 27.9249C14.6711 27.5967 14.8555 27.1516 14.8555 26.6875V21.4375ZM18.3555 16.1875C18.8196 16.1875 19.2647 16.3719 19.5929 16.7001C19.9211 17.0283 20.1055 17.4734 20.1055 17.9375V26.6875C20.1055 27.1516 19.9211 27.5967 19.5929 27.9249C19.2647 28.2531 18.8196 28.4375 18.3555 28.4375C17.8913 28.4375 17.4462 28.2531 17.118 27.9249C16.7898 27.5967 16.6055 27.1516 16.6055 26.6875V17.9375C16.6055 17.4734 16.7898 17.0283 17.118 16.7001C17.4462 16.3719 17.8913 16.1875 18.3555 16.1875ZM25.3555 14.4375C25.3555 13.9734 25.1711 13.5283 24.8429 13.2001C24.5147 12.8719 24.0696 12.6875 23.6055 12.6875C23.1413 12.6875 22.6962 12.8719 22.368 13.2001C22.0398 13.5283 21.8555 13.9734 21.8555 14.4375V26.6875C21.8555 27.1516 22.0398 27.5967 22.368 27.9249C22.6962 28.2531 23.1413 28.4375 23.6055 28.4375C24.0696 28.4375 24.5147 28.2531 24.8429 27.9249C25.1711 27.5967 25.3555 27.1516 25.3555 26.6875V14.4375Z" />
    </g>
  </Wrapper>
)