import React, { useState } from "react";
import styled from "styled-components/macro";
import { DropdownList } from "react-widgets/cjs";
import { toast } from "react-toastify";

import locale from "locale";
import api from "api";
import routes from "config/routes";
import User, { Organization } from "types/auth";
import useAuthStore from "stores/useAuthStore";
import useUserStore, { getRole } from "stores/useUserStore";
import useNavStore from "stores/useNavStore";

// import LeaveLogo from "components/svg/LeaveLogo";
import LeaveLogo from "components/common/LeaveLogo";
import Button, { Wrapper as _Button } from "components/lib/Button";
import Fields from "./Fields";
import SSOGroup from "./SSOGroup";

//REDUX
import { accessOrg, loginAsync } from "redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;

  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  background: inherit;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Form = styled.form`
  margin-top: 10rem;
  background: var(--container-bg-color);
  border-radius: 10rem;
  width: 500rem;

  padding: 40rem 60rem;
  padding-bottom: 30rem;

  ${_Button} {
    margin-top: 30rem;
    padding: 8rem 15rem;
    width: 100%;
    justify-content: center;
  }
`;

const Title = styled.h3`
  font-size: 18rem;
  text-align: center;
  margin-bottom: 35rem;
`;

const Or = styled.p`
  margin: 25rem 0;
  color: #aaa;
  text-align: center;
`;

export default ({
  setHasLogin,
  cacheLocal,
  setShowRegister,
  showRegister,
}: {
  setHasLogin: (b: boolean) => void;
  cacheLocal: [boolean, (b: boolean) => void];
  setShowRegister: (b: boolean) => void;
  showRegister: boolean;
}) => {
  const setAuth = useAuthStore((state) => state.setAuth);
  const _setUser = useUserStore((state) => state.setUser);
  const setRole = useUserStore((state) => state.setRole);
  const authState = useAuthStore.getState();
  // const orgId = useAuthStore.getState().orgId;

  const { user: userData } = useSelector((state: any) => state?.auth);

  const navigate = useNavStore((state) => state.navigate);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch<any>();

  const [user, setUser] = useState<User>();

  const [org, setOrg] = useState<Organization>();

  const loginUser = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Email and password required");
      return;
    }

    dispatch(
      loginAsync({ email, password, user, setUser, authState, setAuth })
    );
  };

  const loginOrg = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!user || !org || !navigate) return;

    setAuth({ ...authState, orgId: org.id });
    api.auth.accessOrg().then((data) => {
      setAuth({ ...authState, uId: data.id, role: getRole(data.roles) });
      setUser(data);
      setTimeout(() => window.location.reload(), 1000);
    });

    const role = getRole(user.roles);

    setRole(role);
    api.user.getById(user.id).then(_setUser);

    if (cacheLocal) {
      setAuth({
        tokenUser: user.bearerToken,
        orgId: org.id,
        uId: user.id,
        path: routes.dashboard.render.url,
        role,
      });
    }

    setHasLogin(true);
    navigate(routes.dashboard);
  };
  const registerPage = (e: React.MouseEvent) => {
    e.preventDefault();
    window.open(`${process.env.REACT_APP_EPROFILE_URL}/register`, "_blank");
    // setShowRegister(!showRegister);
    // navigate!()
  };

  return (
    <Wrapper>
      <Container>
        <LeaveLogo color="black" />

        <Form style={{marginTop: "20px"}}>
          <Title className="montserrat">
            {!user ? locale.en.login.title[1] : locale.en.login.title[2]}
          </Title>

          {!user && (
            <Fields
              email={[email, setEmail]}
              password={[password, setPassword]}
              remember={cacheLocal}
            />
          )}

          {user && (
            <DropdownList
              data={user.organizations}
              textField="name"
              onChange={setOrg}
            />
          )}

          <Button.Classic onClick={!user ? loginUser : loginOrg}>
            {locale.en.login.button.login}
          </Button.Classic>

          {/* {!user && (
            <>
              <Or>{locale.en.login.or}</Or>
              <Button.Classic onClick={!user ? registerPage : loginUser}>
                {locale.en.login.button.register}
              </Button.Classic>
            </>
          )} */}
        </Form>
      </Container>
    </Wrapper>
  );
};
