import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 1/1;
  height: auto;
  width: auto;
`;

export default ({ color = "black" }: {
  color?: string
}) => (
  <Wrapper
    width={36} height={36}
    viewBox="0 0 36 36"
  >
    <g>
      <path fill={color} d="M6.17773 21.9609V27.2109C6.17773 30.1107 11.6622 32.4609 18.4277 32.4609C25.1932 32.4609 30.6777 30.1107 30.6777 27.2109V21.9609C30.6777 24.8607 25.1932 27.2109 18.4277 27.2109C11.6622 27.2109 6.17773 24.8607 6.17773 21.9609Z" />
      <path fill={color} d="M6.17773 13.2109V18.4609C6.17773 21.3607 11.6622 23.7109 18.4277 23.7109C25.1932 23.7109 30.6777 21.3607 30.6777 18.4609V13.2109C30.6777 16.1107 25.1932 18.4609 18.4277 18.4609C11.6622 18.4609 6.17773 16.1107 6.17773 13.2109Z" />
      <path fill={color} d="M30.6777 9.71094C30.6777 12.6107 25.1932 14.9609 18.4277 14.9609C11.6622 14.9609 6.17773 12.6107 6.17773 9.71094C6.17773 6.81119 11.6622 4.46094 18.4277 4.46094C25.1932 4.46094 30.6777 6.81119 30.6777 9.71094Z" />
    </g>
  </Wrapper>
)