import React from "react";

import locale from "locale";
import type User from "types/user";

import Button from "components/lib/Button";
import { Wrapper, Description, ButtonGroup } from "components/lib/Modal/styled";
import api from "api";

export default ({
  setModalVisible,
  refetchUser,
  revokeUser,
}: {
  setModalVisible: (b: boolean) => void;
  refetchUser: () => {},
  revokeUser?: User;
}) => {
  const handleCancelClick = () => {
    setModalVisible(false);
  };

  const handleRevokeClick = () => {
    if (revokeUser) {
      api.user.revoke(revokeUser).then((data) => {
        refetchUser();
        console.log(data);
      });
    }

    handleCancelClick();
  };

  return (
    <Wrapper>
      <Description>{locale.en.users.revoke.description}</Description>

      <ButtonGroup>
        <Button.Ghost onClick={handleCancelClick}>
          {locale.en.common.button.cancel}
        </Button.Ghost>

        <Button.Classic onClick={handleRevokeClick}>
          {locale.en.users.revoke.button}
        </Button.Classic>
      </ButtonGroup>
    </Wrapper>
  );
};
