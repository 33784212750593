import React, { useRef, useState } from "react";
import styled from "styled-components/macro";
import { Calendar } from "react-widgets/cjs";
import { format } from "date-fns";

import datetime from "config/datetime";
import useDropdown from "hooks/common/useDropdown";

import CalIcon, { Wrapper as _CalIcon } from "components/svg/Calendar";
import Input from "components/lib/Input";

export const Wrapper = styled.div`
  position: relative;
  ${_CalIcon} {
    width: 18rem;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  width: 300rem;
`;

const workdayValue: { [key: string]: number } = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0
}

export default ({
  value,
  onChange,
  readOnly = false,
  placeholder = "",
  workdays,
  holidays,
}: {
  value?: Date | null;
  onChange?: (d: Date) => void;
  readOnly?: boolean;
  placeholder?: string;
  workdays?: string[];
  holidays?: string[];
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [expand, setExpand] = useState(false);

  useDropdown(ref, expand, setExpand);

  const handleWrapperClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    !readOnly && setExpand(true);
  };

  const handleCalendarClick = (d: Date) => {
    if (getDisabledDates(d) === false) return;
    if (getIsHoliday(d) === true) return;
    if (!onChange) return;
    // if (!(new Date() <= d)) return;

    // console.log("handle", );

    onChange(d);
    setExpand(false);
  };

  const getDisabledDates = (date: Date) => {
    let enable = false;
    const days = workdays ? workdays : [];

    if (days.length === 0) return true;
    if (workdays !== undefined) {
      days?.map((day) => {
        if (date.getDay() === workdayValue[day]) {
          enable = true;
        }
      });
    }

    return enable;
  }

  const getIsHoliday = (date: Date) => {
    const dateStr = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const monthStr = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const yearStr = date.getFullYear();

    return holidays?.includes(`${dateStr}-${monthStr}-${yearStr}`);
  }

  const renderDay = ({ date }: { date: Date }): React.ReactNode => {
    const isDisable = getDisabledDates(date);
    const isHoliday = getIsHoliday(date);

    if (isDisable === false) {
      return <div className="disabled-day">{date.getDate()}</div>;
    }

    if (isHoliday === true) {
      return <div className="disabled-day">{date.getDate()}</div>;
    }

    return date.getDate();
  };


  return (
    <Wrapper onClick={handleWrapperClick}>
      <Input
        icon={<CalIcon />}
        value={value ? format(value, datetime.format.datePicker) : ""}
        placeholder={placeholder}
        readOnly={readOnly}
      />

      <Container ref={ref} onClick={(e) => e.stopPropagation()}>
        <Calendar onChange={(d) => d && handleCalendarClick(d)} value={value} renderDay={renderDay} />
      </Container>
    </Wrapper>
  );
};
