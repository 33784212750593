export default {
  title: {
    1: "Login to your account",
    2: "Select Organization",
  },
  email: "Email",
  password: "Password",
  rememberMe: "Remember me",
  forgot: "Forgot password?",
  or: "OR",
  button: {
    login: "Login",
    google: "Continue with Google",
    fb: "Continue with Facebook",
    register: "Register",
  },
};
