import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { isAfter } from "date-fns";
import { useSelector } from "react-redux";

import locale from "locale";
import Label, { Wrapper as _Label } from "components/lib/Label";
import DatePicker from "./DatePicker";
import Input from "components/lib/Input";
import Multiselect from "./Multiselect";
import { DropdownList } from "react-widgets/cjs";

import api from "api";

export const Wrapper = styled.div`
  display: flex;
  gap: var(--gap, 20rem);

  ${_Label} {
    flex-grow: 1;
  }
`;

export type DateType = {
  value?: Date | null | undefined;
  onChange?: (d: Date | null | undefined) => void;
  readOnly?: boolean;
};

export default ({
  start,
  end,
  className,
  workdays,
  holidays
}: {
  start?: DateType;
  end?: DateType;
  className?: string;
  workdays?: string[];
  holidays?: string[];
}) => {
  /**
   * Hooks
   */
  const [_start, setStart] = useState<Date | null>();
  const [_end, setEnd] = useState<Date | null>();
  const [firstRun, setFirstRun] = useState(false);
  
  useEffect(() => {
    if (start && end && start.value && end.value && firstRun === false) {
      handleChangeStart(start.value);
      handleChangeEnd(end.value);
      setFirstRun(true);
      // verifyDate(start.value, end.value);
      // setStart(start.value);
      // setEnd(end.value);
    }
  }, [start, end, firstRun]);

  // useEffect(() => {
  //   if (_start && _end) {
  //     verifyDate(_start, _end);
  //   }
  // }, [_start, _end]);

  /**
   * Not hooks
   */

  // const verifyDate = (dawn: Date, dusk: Date) => {
  //   let _start = dawn;
  //   let _end = dusk;

  //   if (isAfter(_start, _end)) {
  //     _end = _start;
  //   }

  //   setStart(_start);
  //   setEnd(_end);

  //   start && start.onChange && start.onChange(dawn);
  //   end && end.onChange && end.onChange(dusk);
  // };

  const handleChangeStart = (dawn: Date) => {
    setStart(dawn);
    start && start.onChange && start.onChange(dawn);
  }

  const handleChangeEnd = (dusk: Date) => {
    setEnd(dusk);
    end && end.onChange && end.onChange(dusk);
  }

  /**
   * Render
   */
  return (
    <Wrapper className={className}>
      <Label title={locale.en.common.date.start}>
        <DatePicker
          value={_start}
          onChange={(e) => handleChangeStart(e)}
          readOnly={start?.readOnly}
          placeholder={locale.en.common.date.placeholder}
          workdays={workdays}
          holidays={holidays}
        />
      </Label>

      <Label title={locale.en.common.date.end}>
        <DatePicker
          value={_end}
          onChange={(e) => handleChangeEnd(e)}
          readOnly={end?.readOnly}
          placeholder={locale.en.common.date.placeholder}
          workdays={workdays}
          holidays={holidays}
        />
      </Label>
    </Wrapper>
  );
};
