import axios from "./axios";
import backend from "config/backend";

import type { Role as ServerRole } from "./user";
import type User from "types/auth";
import type { Organization } from "types/auth";
import type { Role } from "types/user";
import routes from "config/routes";
import { getRole } from "stores/useUserStore";

/**
 * Mapping
 */
type ServerSchema = {
  uId: string;
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  organizations: Array<{
    corpName: string;
    corpId: string;
  }>;
  token: string;
  profile: string;
  roles: ServerRole[];
};

type VerifyTokenSchema = {
  userId: string;
  orgId: string;
  token: string;
  role: string;
};

function mapToReact(response: ServerSchema) {
  const roles: Role[] = response.roles.map((r) => {
    switch (r) {
      case "hrAdmin":
        return "hr-admin";
      case "superAdmin":
        return "superadmin";
      case "user":
        return "user";
    }
  });

  let organizations: Organization[] = [];
  if (
    Array.isArray(response.organizations) &&
    response.organizations.length > 0
  ) {
    organizations = response.organizations.map((o) => ({
      id: o.corpId,
      name: o.corpName,
    }));
  }

  return {
    id: response.uId,
    firstName: response.firstName,
    lastName: response.lastName,
    email: response.email,
    bearerToken: response.token,
    pfpUrl: response.profile,
    roles,
    organizations,
  };
}

/**
 * API Implementation
 */

async function register(userData: any) {
  const response = await axios.post<{ data: ServerSchema }>(
    backend.auth.register,
    userData
  );
  return response.data.data;
}

async function login(email: string, password: string) {
  const response = await axios.post<{ data: ServerSchema }>(
    backend.auth.login,
    { email, password }
  );
  return response.data.data;
}

async function verifyToken(bearerToken: string, orgId: string) {
  const response = await axios.post<{ data: VerifyTokenSchema }>(
    backend.auth.verifyToken,
    {
      bearerToken, orgId
    }
  );
  const user = response.data.data;

  return {
    userId: user?.userId,
    orgId: user?.orgId,
    role: user?.role,
    bearerToken: user?.token,
  };
}

async function accessOrg() {
  const response = await axios.get<{ data: ServerSchema }>(
    backend.auth.accessOrg
  );

  const reactData = mapToReact(response.data.data);
  return reactData;
}

export default {
  login,
  verifyToken,
  accessOrg,
  register,
};
