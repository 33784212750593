import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  display: block;
  aspect-ratio: 1/1;
  height: auto;
  width: auto;
`;

export default ({ color = "black" }: { color?: string }) => {
  return (
    <Wrapper
      width={23} height={23}
      viewBox="0 0 23 23"
      fill="none"
    >
      <path fill={color} d="M14.8493 14.4586L13.8642 15.4436C13.8006 15.5101 13.7242 15.5628 13.6395 15.5986C13.5548 15.6345 13.4637 15.6526 13.3717 15.652C13.1864 15.6504 13.0094 15.5755 12.8792 15.4436L10.0944 12.621C10.03 12.5562 9.97902 12.4794 9.94434 12.3949C9.90967 12.3104 9.89201 12.2198 9.89237 12.1285V5.58027C9.89237 5.39605 9.96555 5.21937 10.0958 5.08911C10.2261 4.95884 10.4028 4.88566 10.587 4.88566H11.9762C12.1599 4.88731 12.3356 4.96102 12.4655 5.09093C12.5954 5.22084 12.6691 5.39656 12.6708 5.58027V10.9792C12.6704 11.0706 12.6881 11.1611 12.7228 11.2456C12.7574 11.3301 12.8084 11.407 12.8729 11.4718L14.8809 13.4798C15.006 13.614 15.0728 13.7923 15.0669 13.9757C15.061 14.1591 14.9828 14.3327 14.8493 14.4586ZM11.25 0.0234375C9.07441 0.0234375 6.94767 0.668576 5.13873 1.87727C3.32979 3.08597 1.91989 4.80393 1.08733 6.81392C0.254766 8.8239 0.0369294 11.0356 0.461367 13.1694C0.885804 15.3032 1.93345 17.2632 3.47183 18.8016C5.01021 20.34 6.97022 21.3876 9.10401 21.8121C11.2378 22.2365 13.4495 22.0187 15.4595 21.1861C17.4695 20.3535 19.1875 18.9436 20.3962 17.1347C21.6049 15.3258 22.25 13.199 22.25 11.0234C22.25 8.10605 21.0911 5.30816 19.0282 3.24526C16.9653 1.18236 14.1674 0.0234375 11.25 0.0234375Z" />
    </Wrapper>
  )
}