import Numeric from "./variants/Numeric";
import Statistic from "./variants/Statistic";

import { Wrapper as _Wrapper } from "./styled";

export const Wrapper = _Wrapper;

export default {
  Numeric,
  Statistic
}