import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import locale from "locale";
import useNavStore from "stores/useNavStore";
import useUserStore from "stores/useUserStore";
import { mapRole } from "stores/useUserStore";

import Button from "components/lib/Button";
import Dot3x3, { Wrapper as _Dot3x3 } from "components/svg/Dot3x3";
import AppIcon from "./app.svg";
import Switcheroo from "components/svg/Switcheroo";
import Profile from "./Profile";
import SwitchUserModal from "./SwithUserModal";
import api from 'api';

const { AppDrawer } = require("@bsilobook/component")

const Wrapper = styled.header`
  position: relative;
  padding: 15rem 0;
  border-bottom: 1rem solid #ccc;

  display: flex;
  align-items: center;
  gap: 20rem;

  ${_Dot3x3} {
    width: 18rem;
  }
`;

const Title = styled.h1`
  flex-grow: 1;
`;

const Divider = styled.div`
  position: relative;
  width: 1rem;
  height: 35rem;
  background: #ccc;
`;

export default ({ setHasLogin }: { setHasLogin: (b: boolean) => void }) => {
  const currentRoute = useNavStore((state) => state.currentRoute);

  const currentRole = useUserStore((state) => state.role);
  const user = useUserStore((state) => state.user);

  const [visible, setVisible] = useState(false);
  const [apps, setApps] = useState([]);

  const token = localStorage.getItem("tokenUser");
  const orgId = localStorage.getItem("orgId");

  const possibleRole = user?.roles
    .map((r) => mapRole(r))
    .filter((r) => r !== currentRole)[0];

  useEffect(() => {
    api.user.getAllApps()
    .then((res: any) => {
      const mapRes = res.map((x: any) => (
        {
          code: x.code,
          label: x.name,
          link: x.link,
          image: x.image ? x.image : AppIcon
        }
      ))
      setApps(mapRes);
    })
  }, []);

  return (
    <>
      <Wrapper>
        <Title>{currentRoute?.display.header}</Title>

        <Button.Classic
          SVGElement={Switcheroo}
          disabled={!possibleRole}
          onClick={() => setVisible(!visible)}
        >
          {locale.en.header.switchUser}
        </Button.Classic>

        {/* <Dot3x3 color="var(--primary-color)" /> */}
        <AppDrawer 
          apps={apps}
          token={token}
          orgId={orgId}
          width="40px"
          height="40px"
          popupRight="20px"
          popupTop="70px"
          bgColor="#ddd"
        />

        <Divider />

        <Profile
          imgSrc={user?.thumbnail ? user.thumbnail : "/static/blank_profile_pic.jpg"}
          name={user?.userName || "Geovanni"}
          position={currentRole || "Superadmin"}
          setHasLogin={setHasLogin}
        />
      </Wrapper>

      <SwitchUserModal
        visible={visible}
        setVisible={setVisible}
        possibleRole={possibleRole}
      />
    </>
  );
};
