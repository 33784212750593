import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import locale from "locale";
import type { Setter } from "./useForm";

import type { Role, Status, Supervisor } from "types/user";

import General from "./components/General";
import Contact from "./components/Contact";
import Others from "./components/Others";
import Button from "components/lib/Button";
import useUserStore from "stores/useUserStore";

const Wrapper = styled.form`
  --gap: 20rem;
  --gap-gutter: 50rem;

  position: relative;
  padding: var(--container-padding);
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);
`;

const Form = styled.div`
  max-width: 1000rem;

  display: flex;
  flex-direction: column;
  gap: var(--gap-gutter);
`;

const ButtonGroup = styled.div`
  margin-top: 20rem;

  display: flex;
  justify-content: end;
  gap: 15rem;
`;

export default ({
  firstName,
  lastName,
  userName,
  email,
  contactNo,
  designation,
  departmentId,
  status,
  supervisors,
  nationalId,
  dob,

  country,
  stateRegion,
  city,
  postalCode,
  address,

  workDayIds,
  roles,

  setForm,
  okText,
  onOk,
}: {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  contactNo: string;
  designation: string;
  departmentId: string;
  supervisors: Supervisor[];
  status: Status;
  nationalId: string;
  dob?: Date | null;

  country: string;
  stateRegion: string;
  city: string;
  postalCode: string;
  address: string[];
  setForm: Setter;

  workDayIds: string[];
  roles: Role[];

  okText: string;
  onOk?: (e: React.MouseEvent) => void;
}) => {
  const nativeNavigate = useNavigate();
  const userRole = useUserStore((state) => state.role);

  const handleCancel = (e: React.MouseEvent) => {
    e.preventDefault();
    nativeNavigate(-1);
  };

  return (
    <Wrapper>
      <Form>
        <General
          firstName={firstName}
          lastName={lastName}
          userName={userName}
          email={email}
          contactNo={contactNo}
          departmentId={departmentId}
          designation={designation}
          status={status}
          supervisors={supervisors}
          nationalId={nationalId}
          dob={dob}
          setForm={setForm}
        />

        <Contact
          country={country}
          city={city}
          stateRegion={stateRegion}
          postalCode={postalCode}
          address={address}
          setForm={setForm}
        />

        <Others
          workDayIds={workDayIds}
          roles={roles}
          setForm={setForm}
          readonly={userRole === "admin" ? false : true}
        />
      </Form>

      <ButtonGroup>
        <Button.Ghost onClick={handleCancel}>
          {locale.en.common.button.cancel}
        </Button.Ghost>

        <Button.Classic onClick={onOk}>{okText}</Button.Classic>
      </ButtonGroup>
    </Wrapper>
  );
};
