import React, { useMemo, useRef } from 'react';
import styled from 'styled-components/macro';

import locale from 'locale';
import type { Checks } from 'types/leaveBalance';

import Label from 'components/lib/Label';
import TextArea, { Wrapper as _TextArea } from 'components/lib/TextArea';
import Input, { Input as _Input } from 'components/lib/Input';
import { ButtonGroup } from 'components/lib/Modal/styled';
import Button from 'components/lib/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20rem;

  padding-top: 25rem;

  ${_TextArea} { width: 400rem }
  ${ButtonGroup} { align-self: end }
`;

const Title = styled.h3`
  font-weight: 600;
  text-align: center;
  margin-bottom: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;

  ${_Input} {
    width: 200px;
    text-align: center;
  }
`;

const AdjustButton = styled.button`
  position: relative;  
  outline: none;
  border: 1rem solid #ccc;
  border-radius: 4rem;

  aspect-ratio: 1/1;
  height: 35rem;
  width: auto;

  font-size: 30rem;
  cursor: pointer;
  color: var(--primary-color);
  background: transparent;

  & > * {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
  }
`;


export default ({ checkboxes, leave, annualLeave, comments, setVisible, setDoubleConfirm }: {
  checkboxes: Checks,
  leave: [string, (s: string) => void],
  annualLeave: [string, (s: string) => void],
  comments: [string, (s: string) => void]
  setVisible: (b: boolean) => void,
  setDoubleConfirm: (b: boolean) => void
}) => {
  /**
   * Hooks
   */
  const input = useRef<HTMLInputElement>(null);
  const secondInput = useRef<HTMLInputElement>(null);

  const nameList = useMemo(() => {
    const list = [];

    for (const [k, v] of Object.entries(checkboxes)) {
      list.push(v.username);
    }

    return list;
  }, [Object.keys(checkboxes).length]);

  /**
   * Not hooks
   */
  const resolveAddtLeave = (n: string | number) => {
    const num = typeof n === "number" ? n : parseInt(n);

    if (isNaN(num)) leave[1]("0");
    else leave[1](num.toString());
  }

  const resolveAnnLeave = (n: string | number) => {
    const num = typeof n === "number" ? n : parseInt(n);

    if (isNaN(num)) annualLeave[1]("0");
    else annualLeave[1](num.toString());
  }

  const plusClick = (type: string) => {
    if (type === "Additional") {
      resolveAddtLeave(parseInt(leave[0]) + 1);
    } else {
      resolveAnnLeave(parseInt(annualLeave[0]) + 1);
    }
  }

  const minusClick = (type: string) => {
    if (type === "Additional") {
      resolveAddtLeave(parseInt(leave[0]) - 1);
    } else {
      resolveAnnLeave(parseInt(annualLeave[0]) - 1);
    }
  }
  

  const handleKeyDown = (e: React.KeyboardEvent) => {
    e.key === "Enter" && input.current?.blur();
  }
  /**
   * Render
   */
  return (
    <Wrapper>
      {/* <div>
        <Title>
          {locale.en.adjustLeave.modal.adjust.annualLeave
            .replace("{{ 1 }}", nameList.join(", "))
          }
        </Title>

        <InputContainer>
          <AdjustButton onClick={() => minusClick("Annual")}>
            <p>{locale.en.adjustLeave.modal.adjust.decrement}</p>
          </AdjustButton>

          <Input
            ref={input}
            value={annualLeave[0]}
            onChange={annualLeave[1]}
            onBlur={() => resolveAnnLeave(annualLeave[0])}
            onKeyDown={handleKeyDown}
          />

          <AdjustButton onClick={() => plusClick("Annual")}>
            <p>{locale.en.adjustLeave.modal.adjust.increment}</p>
          </AdjustButton>
        </InputContainer>
      </div> */}

      <div>
        <Title>
          {locale.en.adjustLeave.modal.adjust.additionalLeave
            .replace("{{ 1 }}", nameList.join(", "))
          }
        </Title>

        <InputContainer>
          <AdjustButton onClick={() => minusClick("Additional")}>
            <p>{locale.en.adjustLeave.modal.adjust.decrement}</p>
          </AdjustButton>

          <Input
            ref={secondInput}
            value={leave[0]}
            onChange={leave[1]}
            onBlur={() => resolveAddtLeave(leave[0])}
            onKeyDown={handleKeyDown}
          />

          <AdjustButton onClick={() => plusClick("Additional")}>
            <p>{locale.en.adjustLeave.modal.adjust.increment}</p>
          </AdjustButton>
        </InputContainer>
      </div>

      <Label title={locale.en.adjustLeave.modal.adjust.comments}>
        <TextArea
          value={comments[0]}
          onChange={comments[1]}
        />
      </Label>

      <ButtonGroup>
        <Button.Ghost onClick={() => setVisible(false)}>
          {locale.en.adjustLeave.modal.button.cancel}
        </Button.Ghost>

        <Button.Classic onClick={() => setDoubleConfirm(true)}>
          {locale.en.adjustLeave.modal.button.ok}
        </Button.Classic>
      </ButtonGroup>
    </Wrapper>
  );
}