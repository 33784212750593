import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import locale from 'locale';
import api from 'api';
import type { Checks } from 'types/leaveBalance';

import Adjust from './Adjust';
import DoubleConfirm from './DoubleConfirm';
import LeaveBalance from 'types/leaveBalance';

export default ({ yearNow, checkboxes, setCheckboxes, setVisible }: {
  yearNow: string,
  checkboxes: Checks,
  setCheckboxes: (c: Checks) => void,
  setVisible: (b: boolean) => void
}) => {
  /**
   * Hooks
   */
  const [doubleConfirm, setDoubleConfirm] = useState(false);

  const [leave, setLeave] = useState("0");
  const [annualLeave, setAnnualLeave] = useState("0");
  const [comments, setComments] = useState("");

  const queryClient = useQueryClient();

  const mutation = useMutation(api.leaveBalance.updates, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.leaveBalance.queryKey);

      setLeave("0");
      setAnnualLeave("0");
      setComments("");
      setCheckboxes({});

      const message = locale.en.toastMessages.updateSuccess.replace("{{ 1 }}", "Leave balance(s)");
      toast.success(message);

      setDoubleConfirm(false);
      setVisible(false);
    }
  });

  /**
   * Not hook
   */
  const onDoubleConfirm = () => {
    const ids = Object.keys(checkboxes);
    mutation.mutate({ ids, additionalLeave: parseInt(leave), annualLeave: parseInt(annualLeave) })
  }

  /**
   * Render
   */
  return (
    <div>
      {!doubleConfirm ?
        <Adjust
          checkboxes={checkboxes}
          leave={[leave, setLeave]}
          annualLeave={[annualLeave, setAnnualLeave]}
          comments={[comments, setComments]}
          setVisible={setVisible}
          setDoubleConfirm={setDoubleConfirm}
        /> :
        <DoubleConfirm
          setDoubleConfirm={setDoubleConfirm}
          onDoubleConfirm={onDoubleConfirm}
        />
      }
    </div>
  );
}