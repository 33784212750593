import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 36/35;
  height: auto;
  width: auto;
`;

export default ({ color = "black" }: {
  color?: string
}) => (
  <Wrapper
    width={36} height={35}
    viewBox="0 0 36 35"
    fill="none"
  >
    <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M7.85547 7C7.85547 6.07174 8.22422 5.1815 8.88059 4.52513C9.53697 3.86875 10.4272 3.5 11.3555 3.5H19.381C20.3092 3.5002 21.1992 3.86908 21.8555 4.5255L27.83 10.5C28.4864 11.1562 28.8553 12.0463 28.8555 12.9745V28C28.8555 28.9283 28.4867 29.8185 27.8303 30.4749C27.174 31.1313 26.2837 31.5 25.3555 31.5H11.3555C10.4272 31.5 9.53697 31.1313 8.88059 30.4749C8.22422 29.8185 7.85547 28.9283 7.85547 28V7ZM11.3555 17.5C11.3555 17.0359 11.5398 16.5908 11.868 16.2626C12.1962 15.9344 12.6413 15.75 13.1055 15.75H23.6055C24.0696 15.75 24.5147 15.9344 24.8429 16.2626C25.1711 16.5908 25.3555 17.0359 25.3555 17.5C25.3555 17.9641 25.1711 18.4092 24.8429 18.7374C24.5147 19.0656 24.0696 19.25 23.6055 19.25H13.1055C12.6413 19.25 12.1962 19.0656 11.868 18.7374C11.5398 18.4092 11.3555 17.9641 11.3555 17.5ZM13.1055 22.75C12.6413 22.75 12.1962 22.9344 11.868 23.2626C11.5398 23.5908 11.3555 24.0359 11.3555 24.5C11.3555 24.9641 11.5398 25.4092 11.868 25.7374C12.1962 26.0656 12.6413 26.25 13.1055 26.25H23.6055C24.0696 26.25 24.5147 26.0656 24.8429 25.7374C25.1711 25.4092 25.3555 24.9641 25.3555 24.5C25.3555 24.0359 25.1711 23.5908 24.8429 23.2626C24.5147 22.9344 24.0696 22.75 23.6055 22.75H13.1055Z" />
  </Wrapper>
)