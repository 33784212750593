import { useState, useEffect } from 'react';

import locale from 'locale';
import type { Column } from 'components/lib/Table/types';
import type LeaveBalance from 'types/leaveBalance';

export default (yearNow: string) => {
  const [columns, setColumns] = useState<Column<LeaveBalance>[]>([]);

  useEffect(() => {
    const columns: Column<LeaveBalance>[] = [
      {
        title: locale.en.myLeaveBal.table.annualEntitlement,
        render: r => r.balance[yearNow]?.annualEntitlement ?? 0
      },
      {
        title: locale.en.myLeaveBal.table.currentBalToDate,
        render: r => r.balance[yearNow]?.currentBalance ?? 0
      },
      {
        title: locale.en.myLeaveBal.table.additionalLeave,
        render: r => r.balance[yearNow]?.additionalLeave ?? 0
      },
      {
        title: locale.en.myLeaveBal.table.consumed,
        render: r => r.balance[yearNow]?.consumed ?? 0
      },
      {
        title: locale.en.myLeaveBal.table.totalCurrentBal,
        render: r => r.balance[yearNow]?.totalCurrentBalance ?? 0
      },
      {
        title: locale.en.myLeaveBal.table.projectedBal,
        render: r => r.balance[yearNow]?.projectedBalance ?? 0
      }
    ];

    setColumns(columns);
  }, [yearNow]);

  return columns;
}