import axios from "./axios";
import backend from "config/backend";
import type LeaveSetting from "types/leaveSetting";

/**
 * Mapping
 */
type ServerSchema = {
  _id: string;
  annualLeaveDefault: number;
  additionalLeaveDefault: number;
  perAnnualMonthGain: number;
  annualLeaveMaxCarryOver: number;
  additionalMaxCarryOver: number;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

function mapToServer(setting: LeaveSetting): Partial<ServerSchema> {
  return {
    _id: setting.id,
    annualLeaveDefault: setting.annualLeaveDefault,
    additionalLeaveDefault: setting.additionalLeaveDefault,
    perAnnualMonthGain: setting.perAnnualMonthGain,
    annualLeaveMaxCarryOver: setting.annualLeaveMaxCarryOver,
    additionalMaxCarryOver: setting.additionalMaxCarryOver,
  };
}

function mapToReact(response: ServerSchema): LeaveSetting {
  return {
    id: response._id,
    annualLeaveDefault: response.annualLeaveDefault,
    additionalLeaveDefault: response.additionalLeaveDefault,
    perAnnualMonthGain: response.perAnnualMonthGain,
    annualLeaveMaxCarryOver: response.annualLeaveMaxCarryOver,
    additionalMaxCarryOver: response.additionalMaxCarryOver,
  };
}

/**
 * API Implementation
 */
const queryKey = "leaveSetting";

async function get() {
  const response = await axios.get<ServerSchema>(backend.leaveSetting.get);
  return mapToReact(response.data);
}

async function update(setting: LeaveSetting) {
  const request = mapToServer(setting);

  const response = await axios.put<ServerSchema>(
    backend.leaveSetting.update,
    request
  );
  return response.data;
}

export default {
  queryKey,
  get,
  update,
};
