import common from "./common";
import dashboard from "./dashboard";
import header from "./header";
import myRequest from "./myRequest";
import myApproval from "./myApproval";
import users from "./users";
import adjustmentHistory from "./adjustmentHistory";
import settings from "./settings";
import adjustLeave from "./adjustLeave";
import pendingMod from "./pendingMod";
import error404 from "./error404";
import toastMessages from "./toastMessages";
import login from "./login";
import myLeaveBal from "./myLeaveBal";
import register from "./register";

export default {
  common,
  dashboard,
  header,
  myRequest,
  myApproval,
  users,
  adjustmentHistory,
  settings,
  adjustLeave,
  pendingMod,
  error404,
  toastMessages,
  login,
  myLeaveBal,
  register,
};
