import React, { useEffect, useState } from 'react';
import { differenceInDays, isSameDay, endOfDay,startOfDay } from 'date-fns';

import type { FormState } from '.';
import useForm from 'hooks/common/useForm';

const workdayValue: { [key: string]: number } = {
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
  Sunday: 0
}

export default ({
  workdays,
  holidays
}: {
  workdays?: string[];
  holidays?: string[];
}) => {
  const { form, setForm } = useForm<FormState>({
    type: "",
    deductOn: "",
    start: new Date(),
    startAPM: "AM",
    end: new Date(),
    endAPM: "PM",
    leaveReason: "",
    approvers: [],
    recipients: []
  });

  const [dayDiff, setDiff] = useState<number>(0);

  const getDisabledDates = (date: Date) => {
    let enable = false;
    const days = workdays ? workdays : [];
    if (workdays !== undefined) {
      days?.map((day) => {
        if (date.getDay() === workdayValue[day]) {
          enable = true;
        }
      });
    }

    return enable;
  }

  const getIsHoliday = (date: Date) => {
    const dateStr = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const monthStr = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const yearStr = date.getFullYear();

    return holidays?.includes(`${dateStr}-${monthStr}-${yearStr}`);
  }
  
  useEffect(() => {
    if (form.start && form.end) {
      let diff = differenceInDays(endOfDay(form.end), startOfDay(form.start));
      
      if (diff >= 0) {
        diff += 1
      }
      if (form.startAPM === form.endAPM) {
        if (isSameDay(form.start, form.end)) {
          diff -= 0.5
        } else {
          if (diff > 1) {
            diff -= 0.5
          }
        }
      }

      let currentDate = new Date(form.start);
      while (currentDate <= form.end) {
        const isWorkday = getDisabledDates(currentDate);
        const isHoliday = getIsHoliday(currentDate);

        if (isWorkday === false) {
          diff -= 1;
        } else if (isHoliday === true) {
          diff -= 1;
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }

      setDiff(diff);
    }
  }, [form.start, form.end, form.startAPM, form.endAPM, workdays, holidays]);


  return { form, dayDiff, setForm };
}