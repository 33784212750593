import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import locale from 'locale';
import api from 'api';
import type LeaveType from 'types/leaveType';

import { Wrapper, Description, ButtonGroup } from 'components/lib/Modal/styled';
import Button from 'components/lib/Button';

export default ({ setModalVisible, leaveType }: {
  setModalVisible: (b: boolean) => void,
  leaveType: [LeaveType | undefined, (d?: LeaveType) => void]
}) => {
  /**
   * Hooks
   */
  const queryClient = useQueryClient();

  const mutation = useMutation(api.leaveType.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.leaveType.queryKey);

      const message = locale.en.toastMessages.deleteSuccess.replace("{{ 1 }}", leaveType[0]?.name || '');
      toast.success(message);
      leaveType[1](undefined);
      setModalVisible(false);
    }
  });

  /**
   * Not hook
   */
  const handleCancelClick = () => { setModalVisible(false) }

  const handleDeleteClick = () => { leaveType[0]?.id && mutation.mutate(leaveType[0].id) }

  let description = locale.en.settings.leaveType.deleteDesc;
  if (leaveType[0]) { description = description.replace('{{ 1 }}', leaveType[0].name) }

  /**
   * Render
   */
  return (
    <Wrapper>
      <Description>
        {description}
      </Description>

      <ButtonGroup>
        <Button.Ghost onClick={handleCancelClick}>
          {locale.en.common.button.cancel}
        </Button.Ghost>

        <Button.Classic onClick={handleDeleteClick}>
          {locale.en.common.button.delete}
        </Button.Classic>
      </ButtonGroup>
    </Wrapper>
  );
}