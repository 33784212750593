import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import useAuthStore from 'stores/useAuthStore';
import useUserStore, { Role } from 'stores/useUserStore';
import routes from 'config/routes';

import api from 'api';

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export default ({ setHasLogin, cacheLocal }: {
  setHasLogin: (b: boolean) => void,
  cacheLocal: [boolean, (b: boolean) => void]
}) => {
  const { search } = useLocation();
  const setAuth = useAuthStore(state => state.setAuth);
  const _setUser = useUserStore(state => state.setUser);
  const setRole = useUserStore(state => state.setRole);

  const navigate = useNavigate();

  const setValues = (userId: string, orgId: string, role: string, bearerToken: string) => {
    if (!userId || !role || !bearerToken) {
      navigate("/");
    };
    
    api.user.getById(userId).then(_setUser);
    setRole(role as Role);
    
    if (cacheLocal) {
      setAuth({
        tokenUser: bearerToken,
        orgId: orgId,
        uId: userId,
        path: routes.dashboard.render.url,
        role
      });
    }

    setHasLogin(true);
    navigate("/");
  }

  const searchParams = useMemo(
    () => new URLSearchParams(search) as ReadOnlyURLSearchParams,
    [search]
  );

  useEffect(() => {
    const bearerToken = searchParams.get("token");
    const splitOrg = search?.toString().split("organizationId=");
    const organizationId = splitOrg.length > 0 ? splitOrg[splitOrg.length - 1] : "";

    api.auth.verifyToken(bearerToken ? bearerToken : "", organizationId ? organizationId : "").then(values => setValues(
      values.userId, 
      values.orgId,
      values.role, 
      values.bearerToken
    ));
  }, [searchParams]);
  
  return (
    <div></div>
  )
}