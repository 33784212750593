import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { DropdownList } from 'react-widgets/cjs';

import locale from 'locale';
import api from 'api';
import backend from 'config/backend';
import type { ServerSchema } from 'api/geolocation';

import Label from 'components/lib/Label';

export default ({ country, stateRegion, city, onChange, readOnly = false }: {
  country: string,
  stateRegion: string,
  city: string,
  onChange?: (country: string, stateRegion: string, city: string) => void
  readOnly?: boolean
}) => {

  const staleTime = backend.staleTime.geolocation; //30min
  const cacheTime = backend.staleTime.geolocation; //30min


  /**
   * Hooks
   */
  const [_country, setCountry] = useState<ServerSchema>();
  const [_state, setState] = useState<Required<ServerSchema>>();

  const [stateOptions, setStateOptions] = useState<Required<ServerSchema>[]>([]);
  const [cityOptions, setCityOptions] = useState<Required<ServerSchema>[]>([]);

  const { data: countryData } = useQuery({
    queryKey: api.geolocation.queryKey.country,
    queryFn: api.geolocation.getCountries,
    staleTime,
    cacheTime
  });

  const { data: stateData } = useQuery({
    queryKey: [api.geolocation.queryKey.region, _country?.country_code],
    queryFn: () => api.geolocation.getStates(_country!.country_code),
    enabled: !!_country?.country_code,
    staleTime,
    cacheTime
  });

  const { data: cityData } = useQuery({
    queryKey: [api.geolocation.queryKey.city, _country?.country_code, _state?.state_code],
    queryFn: () => api.geolocation.getCities(_country!.country_code, _state!.state_code),
    enabled: !!_country?.country_code && !!_state?.state_code,
    staleTime,
    cacheTime
  });

  useEffect(() => {
    if (_country && stateData) {
      if (stateData.length) {
        setStateOptions(stateData);
      }
      else {
        setStateOptions([{
          name: _country.name,
          country_code: _country.country_code,
          state_code: "00"
        }]);
      }
    }
  }, [stateData, _country]);

  useEffect(() => {
    if (_state && cityData) {
      if (cityData.length) {
        setCityOptions(cityData);
      }
      else {
        setCityOptions([{
          name: _state.name,
          country_code: _state.country_code,
          state_code: _state.state_code
        }]);
      }
    }
  }, [cityData, _state]);

  /**
   * Not hook
   */
  const handleCountryChange = (s: ServerSchema) => {
    setCountry(s);
    setState(undefined);

    onChange && onChange(s.name, "", "");
  }

  const handleStateChange = (s: Required<ServerSchema>) => {
    setState(s);
    onChange && onChange(_country!.name, s.name, "");
  }

  const handleCityChange = (s: Required<ServerSchema>) => {
    onChange && onChange(_country!.name, _state!.name, s.name);
  }

  /**
   * Render
   */
  return (
    <>
      <Label title={locale.en.common.csc.country}>
        <DropdownList
          data={countryData}
          dataKey="country_code"
          textField="name"
          value={country}
          onChange={handleCountryChange}
          readOnly={readOnly}
        />
      </Label>

      <Label title={locale.en.common.csc.stateRegion}>
        <DropdownList
          data={stateOptions}
          dataKey="state_code"
          textField="name"
          value={stateRegion}
          onChange={handleStateChange}
          readOnly={readOnly}
        />
      </Label>

      <Label title={locale.en.common.csc.city}>
        <DropdownList
          data={cityOptions}
          dataKey="name"
          textField="name"
          value={city}
          onChange={handleCityChange}
          readOnly={readOnly}
        />
      </Label>
    </>
  );
}