import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";

import api from "api";
import type Department from "types/department";
import useColumns from "./useColumns";

import Table from "components/lib/Table";
import Modal from "components/lib/Modal";
import DeleteDept from "./DeleteDept";
import { useDispatch } from "react-redux";
import { fetchDepartments } from "redux/features/department/departmentSlice";

const Wrapper = styled.div`
  position: relative;
  padding: var(--container-padding);
  padding-top: 10rem;

  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);
`;

export default () => {
  /**
   * Hooks
   */
  const { data } = useQuery({
    queryKey: api.department.queryKey,
    queryFn: () => api.department.list(),
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [delDept, setDelDept] = useState<Department>();

  const dispatch = useDispatch<any>();

  const columns = useColumns((d: Department) => {
    setDelDept(d);
    setModalVisible(true);
  });

  useEffect(() => {
    dispatch(fetchDepartments());
  }, []);

  /**
   * Render
   */
  return (
    <>
      <Wrapper>
        <Table data={data ?? []} columns={columns} pageSize={5} />

        <Modal visible={modalVisible} setVisible={setModalVisible}>
          <DeleteDept
            setModalVisible={setModalVisible}
            department={[delDept, setDelDept]}
          />
        </Modal>
      </Wrapper>
    </>
  );
};
