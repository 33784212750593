import React, { useEffect, useState } from 'react';

import locale from 'locale';
import type { Column } from 'components/lib/Table/types';
import type LeaveBalance from 'types/leaveBalance';
import type { Checks } from 'types/leaveBalance';

import Checkbox from 'components/lib/Checkbox';

export default (
  checkboxes: Checks,
  setCheckboxes: (c: Checks) => void,
  yearNow: string
) => {

  const [columns, setColumns] = useState<Column<LeaveBalance>[]>([]);

  const handleCheck = (id: string, r: LeaveBalance) => {
    const keys = Object.keys(checkboxes);

    const clone: Checks = { ...checkboxes };
    if (keys.includes(id)) delete clone[id];
    else clone[id] = r;

    setCheckboxes(clone);
  }

  useEffect(() => {
    const keys = Object.keys(checkboxes);

    const columns: Column<LeaveBalance>[] = [
      {
        title: locale.en.adjustLeave.table.check,
        width: "60rem",
        render: r => (
          <Checkbox
            active={keys.includes(r.id)}
            onClick={() => handleCheck(r.id, r)}
          />
        )
      },
      {
        title: locale.en.adjustLeave.table.username,
        render: r => r.username
      },
      {
        title: locale.en.adjustLeave.table.department,
        render: r => r.department
      },
      {
        title: locale.en.adjustLeave.table.annEntitlement,
        render: r => r.balance[yearNow].annualEntitlement ?? "-"
      },
      {
        title: locale.en.adjustLeave.table.currBalance,
        render: r => r.balance[yearNow].currentBalance ?? "-"
      },
      {
        title: locale.en.adjustLeave.table.extraLeave,
        render: r => r.balance[yearNow].additionalLeave ?? "-"
      },
      {
        title: locale.en.adjustLeave.table.consumed,
        render: r => r.balance[yearNow].consumed ?? "-"
      },
      {
        title: locale.en.adjustLeave.table.totalCurrBalance,
        render: r => r.balance[yearNow].totalCurrentBalance ?? "-"
      },
      {
        title: locale.en.adjustLeave.table.projectedBalance,
        render: r => r.balance[yearNow].projectedBalance ?? "-"
      }
    ];

    setColumns(columns);
  }, [checkboxes]);

  return columns;
}