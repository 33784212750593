import React, { useMemo } from "react";
import styled from "styled-components/macro";

import locale from "locale";
import routes from "config/routes";
import useNavStore from "stores/useNavStore";
import useUserStore from "stores/useUserStore";
import useAuthStore from "stores/useAuthStore";
import { generateAccordion } from "./utils";

import Logout from "components/svg/Logout";
import Accordion, {
  Wrapper as _Accordion,
  Title as _Accordion_Title,
} from "./Accordion";
// import LeaveLogo, { Wrapper as _LeaveLogo } from "components/svg/LeaveLogo";
import LeaveLogo from "components/common/LeaveLogo";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logoutAction } from "redux/features/auth/authSlice";

export const Wrapper = styled.div`
  --top-level-accordion-margin: 8rem;
  --top-level-accordion-title-padding: 12rem 10rem;
  --top-level-accordion-title-border-radius: 4rem;

  padding-top: 30rem;
  padding-bottom: 40rem;
  background: var(--primary-color);
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > ${_Accordion} {
    margin: var(--top-level-accordion-margin);
  }

  & > ${_Accordion} > ${_Accordion_Title} {
    padding: var(--top-level-accordion-title-padding);
    border-radius: var(--top-level-accordion-title-border-radius);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Nav = styled.div`
  margin: 40rem 0;
  align-self: stretch;

  & > ${_Accordion} {
    margin: var(--top-level-accordion-margin);
  }

  & > ${_Accordion} > ${_Accordion_Title} {
    padding: var(--top-level-accordion-title-padding);
    border-radius: var(--top-level-accordion-title-border-radius);
  }
`;

export default ({ setHasLogin }: { setHasLogin: (b: boolean) => void }) => {
  /**
   * Hooks
   */
  const nativeNavigate = useNavigate();
  const dispatch = useDispatch<any>();

  const setAuth = useAuthStore((state) => state.setAuth);

  const navigate = useNavStore((state) => state.navigate);
  const currentRoute = useNavStore((state) => state.currentRoute);

  const role = useUserStore((state) => state.role);
  const setUser = useUserStore((state) => state.setUser);

  const nodes = useMemo(() => {
    if (navigate) {
      return generateAccordion(routes);
    }
  }, [navigate, currentRoute, role]);
  

  /**
   * Not hooks
   */
  const logout = () => {
    //Clear local storage
    setAuth({
      tokenUser: "",
      orgId: "",
      uId: "",
      role: "",
    });

    setUser(undefined);

    window.localStorage.clear();
    dispatch(logoutAction());

    setHasLogin(false);
    nativeNavigate("/");
  };

  /**
   * Render
   */
  return (
    <Wrapper>
      <Container>
        <LeaveLogo color="white" />

        <Nav>{nodes}</Nav>
      </Container>

      <Accordion
        title={locale.en.common.logout}
        logout
        SVGElement={Logout}
        onClick={logout}
      />
    </Wrapper>
  );
};
