import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

import Header from "./components/Header";
import Body from "./components/Body";
import Legends, { Wrapper as _Legends } from "components/common/Legends";
import api from "api";
import type Department from "types/department";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { fetchChartLeaves } from "redux/features/leave/leaveSlice";
import { format, lastDayOfMonth } from "date-fns";
import useUserStore from "stores/useUserStore";
import useAuthStore from "stores/useAuthStore";
import { fetchLeaveTypes } from "redux/features/leaveType/leaveTypeSlice";

export const Wrapper = styled.div`
  flex-grow: 1;

  position: relative;
  padding: 15rem;
  display: flex;
  flex-direction: column;

  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);

  ${_Legends} {
    margin-top: 20rem;
    align-self: end;
  }
`;

export default () => {
  const [date, setDate] = useState(new Date());
  const [department, setDepartment] = useState<Department[]>([]);
  const [showDept, setShowDept] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const dispatch = useDispatch<any>();
  const user: any = useUserStore((state) => state.user);
  const state = useAuthStore((state) => state);

  const { data } = useQuery({
    queryKey: api.department.queryKey,
    queryFn: () => api.department.list(),
  });

  const { data: dataLeaveType } = useQuery({
    queryKey: api.leaveType.queryKey,
    queryFn: () => api.leaveType.list(),
  });

  const firstDateOfMonth = format(date, "MM-1-yyyy");
  const lastDateOfMonth = format(lastDayOfMonth(date), "MM-dd-yyyy");
  console.log("departments", showDept);

  // let myString = "";

  // showDept.map((item) => {
  //   myString += `departmentId=${item}&`;
  //   // console.log(myString)
  // });
  useEffect(() => {
    if (firstDateOfMonth && lastDateOfMonth) {
      const params = {
        startDate: firstDateOfMonth,
        endDate: lastDateOfMonth,
        searchByName: search,
        department: showDept,
        approverUid: state.role === "user" ? state.uId : "",
        userUid: state.role === "user" ? state.uId : "",
        role: state.role
      };
      dispatch(fetchChartLeaves(params));
    }
  }, [date, search, showDept]);

  useEffect(() => {
    dispatch(fetchLeaveTypes());
  }, []);

  const colors = ["#ff0000", "#0000ff", "#9c27b0", "#2196f3", "#ff9800", "#009688", "#607d8b"];

  return (
    <Wrapper>
      <Header
        date={date}
        setDate={setDate}
        showDept={showDept}
        setShowDept={setShowDept}
        search={search}
        setSearch={setSearch}
      />

      <Body
        date={date}
        // showDept={showDept}
        search={search}
        dataLeaveType={dataLeaveType ? dataLeaveType : []}
      />
      
      <Legends 
        data={dataLeaveType ? dataLeaveType.map((x, index) => (
          {
            color: colors[index],
            label: x.name
          }
        )) : []}
      />

      {/* <Legends
        data={[
          { color: "red", label: "Sick Leave" },
          { color: "blue", label: "Annual Leave" },
        ]}
      /> */}
    </Wrapper>
  );
};
