import axios from './axios';
import backend from 'config/backend';
import type LeaveType from 'types/leaveType';

/**
 * Mapping
 */
type ServerSchema = {
  _id: string,
  organizationId: string,
  name: string,
  status: string,
  deleteAble: boolean,
  createdAt: string,
  updatedAt: string
}

function mapToServer(leaveType: LeaveType): Partial<ServerSchema> {
  return {
    _id: leaveType.id,
    name: leaveType.name,
    status: leaveType.status,
    deleteAble: leaveType.deleteAble
  }
}

function mapToReact(response: ServerSchema): LeaveType {
  return {
    id: response._id,
    name: response.name,
    status: response.status,
    deleteAble: response.deleteAble
  }
}

/**
 * API Implementation
 */
//For react-query
const queryKey = "leaveType";

async function list() {
  const response = await axios.get<{ data: ServerSchema[] }>(backend.leaveType.list, {
    params: { isDeleted: false }
  });

  const data: LeaveType[] = [];

  response.data.data.forEach((item) => {
    data.push(mapToReact(item));
  });

  return data;
}

//getById
async function getById(uId: string) {
  const url = backend.leaveType.getById.replace("{{ 1 }}", uId);

  const response = await axios.get<{ data: ServerSchema }>(url);
  return mapToReact(response.data.data);
}

//create
async function create(leaveType: LeaveType) {
  const request = mapToServer(leaveType);
  delete request._id;

  const response = await axios.post<{ data: ServerSchema }>(backend.leaveType.create, request);
  return mapToReact(response.data.data);
}

//update
async function update(leaveType: Required<LeaveType>) {
  const url = backend.leaveType.update.replace("{{ 1 }}", leaveType.id);
  const request = mapToServer(leaveType);

  const response = await axios.put<{ data: ServerSchema }>(url, request);
  return mapToReact(response.data.data);
}

//delete is a reserved keyword for JS
async function destroy(uId: string) {
  const url = backend.leaveType.delete.replace("{{ 1 }}", uId);

  await axios.delete<{ data: "success" }>(url);
}

export default {
  queryKey,
  list,
  getById,
  create,
  update,
  delete: destroy
}