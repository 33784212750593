import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import locale from 'locale';
import api from 'api';
import routes from 'config/routes';

import useNavStore from 'stores/useNavStore';
import useFormState from '../Form/useForm';

import Form from '../Form';

export default () => {
  /**
   * Hooks
   */
  const { id } = useParams<{ id: string }>();

  const nativeNavigate = useNavigate();
  const navigate = useNavStore(state => state.navigate);

  const { form, dayDiff, setForm } = useFormState();

  const { data, error } = useQuery({
    queryKey: [api.holiday.queryKey, id],
    queryFn: () => api.holiday.getById(id!),
    enabled: !!id && !!navigate
  });

  const queryClient = useQueryClient();

  const mutation = useMutation(api.holiday.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.holiday.queryKey);
      queryClient.invalidateQueries([api.holiday.queryKey, id]);

      const message = locale.en.toastMessages.updateSuccess.replace("{{ 1 }}", "Holiday");
      toast.success(message);

      navigate!(routes.settings.routes!.holiday);
    }
  });

  useEffect(() => {
    if (data) {
      setForm({ ...data });
    }
  }, [data]);

  useEffect(() => {
    if (error && navigate) {
      const route = routes.error;
      navigate(route);
    }
  }, [error, navigate]);

  /**
   * Not hook
   */
  const handleCancel = () => {
    nativeNavigate(-1);
  }

  const handleOk = () => {
    if (!navigate || !id || !form.start || !form.end) return;

    mutation.mutate({ id, ...form });
  }

  /**
   * Render
   */
  return (
    <Form
      holiday={form}
      dayDiff={dayDiff}
      setForm={setForm}

      okText={locale.en.common.button.update}
      onOk={handleOk}

      cancelText={locale.en.common.button.cancel}
      onCancel={handleCancel}
    />
  );
}