import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg(({ $onClick }: {
  $onClick: boolean
}) => `
  aspect-ratio: 20/21;
  height: auto;
  width: auto;
  display: block;

  cursor: ${$onClick ? "pointer" : "auto"};
`);

export default ({ color = "black", onClick }: {
  color?: string,
  onClick?: () => void
}) => {
  return (
    <Wrapper
      width={20} height={21}
      viewBox="0 0 20 21"
      fill="none"
      onClick={onClick}
      $onClick={onClick !== undefined}
    >
      <path fill={color} d="M14 0.738281H6C2 0.738281 0 2.73828 0 6.73828V19.7383C0 20.2883 0.45 20.7383 1 20.7383H14C18 20.7383 20 18.7383 20 14.7383V6.73828C20 2.73828 18 0.738281 14 0.738281ZM13.5 11.4883H10.75V14.2383C10.75 14.6483 10.41 14.9883 10 14.9883C9.59 14.9883 9.25 14.6483 9.25 14.2383V11.4883H6.5C6.09 11.4883 5.75 11.1483 5.75 10.7383C5.75 10.3283 6.09 9.98828 6.5 9.98828H9.25V7.23828C9.25 6.82828 9.59 6.48828 10 6.48828C10.41 6.48828 10.75 6.82828 10.75 7.23828V9.98828H13.5C13.91 9.98828 14.25 10.3283 14.25 10.7383C14.25 11.1483 13.91 11.4883 13.5 11.4883Z" />
    </Wrapper>
  )
}