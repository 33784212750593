import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";

export const initialState = {
  loading: false,
  hasErrors: false,
  leavePending: [],
  leaveHistory: [],
  chartLeaves: [],
};

const leaveSlice = createSlice({
  name: "leave",
  initialState,
  reducers: {
    getleaveSucess: (state, action) => {
      state.leavePending = action.payload.pending;
      state.leaveHistory = action.payload.history;
    },
    create: (state, action) => {},
    getChartLeaves: (state, action) => {
      state.chartLeaves = action.payload;
    },
  },
});

export const { getleaveSucess, getChartLeaves } = leaveSlice.actions;

// The reducer
export default leaveSlice.reducer;

export const fetchLeaves = createAsyncThunk(
  "leave/fetchLeaves",
  async (user: any, thunkAPI: any) => {
    try {
      if (user.role.userRole === "admin") {
        const response = await api.leave.list();
        let pending = response?.filter((leave) => leave.hr.status == "pending");
        let history = response?.filter(
          (leave) => leave.hr.status !== "pending"
        );
        user?.setState(response);
        thunkAPI.dispatch(getleaveSucess({ pending, history }));
      } else {
        const response = await api.leave.list({ approverUid: user.role.uId });
        let pending = response?.filter((leave) => leave.hr.status == "pending");
        let history = response?.filter(
          (leave) => leave.hr.status !== "pending"
        );
        user?.setState(response);
        thunkAPI.dispatch(getleaveSucess({ pending, history }));
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchChartLeaves = createAsyncThunk(
  "leave/fetchChartLeaves",
  async (params: any, thunkAPI: any) => {
    // console.log("params", params);

    try {
      const {
        startDate,
        endDate,
        searchByName,
        department,
        approverUid,
        userUid,
        role
      } = params;
      let myString = "";

      department.map((item: any) => {
        myString += `departmentId=${item}&`;
        // console.log(myString)
      });

      const response = await api.leave.chartList({
        startDate,
        endDate,
        searchByName,
        department: myString,
        approverUid,
        userUid,
        role
      });

      console.log("redux", response);

      let history = response?.filter((leave) => leave.hr.status !== "pending");
      thunkAPI.dispatch(getChartLeaves(history));
    } catch (error) {
      console.log(error);
    }
  }
);

export const fetchLeaveById = createAsyncThunk(
  "leave/fetchLeaveById",
  async (reqId: any, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());
    // console.log({ thunkAPI });

    try {
      //   console.log("dispatching now");
      const response = await api.leave.getById(reqId);
      // Set the data
      // thunkAPI.dispatch(getleaveSucess({ pending, history }));
      //   thunkAPI.dispatch(getdeptSucess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);

export const createLeave = createAsyncThunk(
  "holiday/create",
  async (data: any, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());

    try {
      //   console.log("dispatching now");
      //   const response = await api.workingDay.create(data);
      //   console.log(response);
      // Set the data
      //   thunkAPI.dispatch(getdeptSucess(response));
      //   thunkAPI.dispatch(getworkSucess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);
