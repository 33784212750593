import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 1/1;
  height: auto;
  width: auto;
`;

export default ({ color = "black" }: {
  color?: string
}) => (
  <Wrapper
    width={36} height={36}
    viewBox="0 0 36 36"
  >
    <g>
      <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M18.3555 31.9375C22.0685 31.9375 25.6295 30.4625 28.255 27.837C30.8805 25.2115 32.3555 21.6505 32.3555 17.9375C32.3555 14.2245 30.8805 10.6635 28.255 8.03801C25.6295 5.4125 22.0685 3.9375 18.3555 3.9375C14.6424 3.9375 11.0815 5.4125 8.45597 8.03801C5.83046 10.6635 4.35547 14.2245 4.35547 17.9375C4.35547 21.6505 5.83046 25.2115 8.45597 27.837C11.0815 30.4625 14.6424 31.9375 18.3555 31.9375ZM24.8427 15.6747C25.1615 15.3447 25.3379 14.9026 25.3339 14.4438C25.3299 13.985 25.1459 13.546 24.8214 13.2216C24.4969 12.8971 24.058 12.7131 23.5992 12.7091C23.1403 12.7051 22.6983 12.8815 22.3682 13.2003L16.6055 18.963L14.3427 16.7003C14.0127 16.3815 13.5706 16.2051 13.1118 16.2091C12.6529 16.2131 12.214 16.3971 11.8895 16.7216C11.5651 17.046 11.381 17.485 11.377 17.9438C11.3731 18.4026 11.5494 18.8447 11.8682 19.1747L15.3682 22.6747C15.6964 23.0028 16.1414 23.1871 16.6055 23.1871C17.0695 23.1871 17.5145 23.0028 17.8427 22.6747L24.8427 15.6747Z" />
    </g>
  </Wrapper>
);