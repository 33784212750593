import React from "react";
import styled from "styled-components/macro";

import ExclaminationCircle, {
  Wrapper as _ExclamationCircle,
} from "components/svg/ExclaminationCircle";
import Tooltip from "./Tooltip";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7rem;

  width: 100%;
`;

export const Label = styled.label`
  font-size: 12rem;
  color: #717579;

  display: flex;
  align-items: center;
  gap: 6rem;

  ${_ExclamationCircle} {
    height: 11rem;
    cursor: pointer;
  }
`;

export default ({
  title,
  children,
  error,
  className,
}: {
  title: string | JSX.Element;
  children: Array<JSX.Element | string> | JSX.Element | string;
  error?: string;
  className?: string;
}) => (
  <Wrapper className={className}>
    <Label>
      {title}

      {error && (
        <Tooltip text={error}>
          <ExclaminationCircle />
        </Tooltip>
      )}
    </Label>

    <div>{children}</div>
  </Wrapper>
);
