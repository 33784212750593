import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import locale from 'locale';
import holiday from 'api/holiday';
import type Holiday from 'types/holiday';

import { Wrapper, Description, ButtonGroup } from 'components/lib/Modal/styled';
import Button from 'components/lib/Button';

export default ({
  setModalVisible,
  holiday: _holiday
}: {
  setModalVisible: (b: boolean) => void,
  holiday: [Holiday | undefined, (h?: Holiday) => void]
}) => {
  /**
   * Hooks
   */
  const queryClient = useQueryClient();

  const mutation = useMutation(holiday.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(holiday.queryKey);

      const message = locale.en.toastMessages.deleteSuccess.replace("{{ 1 }}", "Holiday");
      toast.success(message);
      _holiday[1](undefined);
      setModalVisible(false);
    }
  });

  /**
   * Not hook
   */
  const handleCancelClick = () => {
    setModalVisible(false);
  }

  const handleDeleteClick = () => {
    if (_holiday[0]?.id) {
      mutation.mutate(_holiday[0].id);
    }
  }

  /**
   * Render
   */
  return (
    <Wrapper>
      <Description>
        {locale.en.settings.holiday.deleteDesc}
      </Description>

      <ButtonGroup>
        <Button.Ghost onClick={handleCancelClick}>
          {locale.en.common.button.cancel}
        </Button.Ghost>

        <Button.Classic onClick={handleDeleteClick}>
          {locale.en.common.button.delete}
        </Button.Classic>
      </ButtonGroup>
    </Wrapper>
  );
}