import React from 'react';

import locale from 'locale';
import routes from 'config/routes';
import type { Role } from 'stores/useUserStore';
import useUserStore from 'stores/useUserStore';
import useAuthStore from 'stores/useAuthStore';

import Modal from 'components/lib/Modal';
import { Wrapper, Description, ButtonGroup } from 'components/lib/Modal/styled';
import Button from 'components/lib/Button';
import useNavStore from 'stores/useNavStore';

export default ({ visible, setVisible, possibleRole }: {
  visible: boolean,
  setVisible: (b: boolean) => void,
  possibleRole?: Role
}) => {

  const navigate = useNavStore(state => state.navigate);
  const setRole = useUserStore(state => state.setRole);
  const setAuth = useAuthStore(state => state.setAuth);

  const handleYes = () => {
    if (!possibleRole || !navigate) return;

    setRole(possibleRole);
    setAuth({ role: possibleRole });
    navigate(routes.dashboard);
    setVisible(false);
  }

  const handleNo = () => {
    setVisible(false);
  }

  const role = possibleRole ?
    possibleRole.charAt(0).toUpperCase() + possibleRole.slice(1) :
    "error";

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Wrapper>
        <Description>
          {locale.en.header.doubleConfirmSwitch.replace("{{ 1 }}", role)}
        </Description>

        <ButtonGroup>
          <Button.Ghost onClick={handleNo}>
            {locale.en.common.button.cancel}
          </Button.Ghost>

          <Button.Classic onClick={handleYes}>
            {locale.en.common.button.yes}
          </Button.Classic>
        </ButtonGroup>
      </Wrapper>
    </Modal>
  );
}