export default {
  username: "Username",
  type: "Leave Type",
  duration: "Leave Duration",
  reason: "Leave Reason",
  deductType: "Deduct Type",
  comments: "Add comments",
  button: {
    reject: "Reject",
    approve: "Approve"
  }
}