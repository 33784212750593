import { useEffect, useState } from 'react';
import { differenceInHours } from 'date-fns';

import locale from 'locale';
import type { Column } from 'components/lib/Table/types';
import type WorkDay from 'types/workday';
import { prependZero } from 'utils';

import TimePicker from 'components/common/TimePicker';

export default (workDays: [WorkDay[], (w: WorkDay[]) => void]) => {

  const [columns, setColumns] = useState<Column<WorkDay>[]>([]);

  const handleTimeChange = (r: WorkDay, time: Date, isStart?: boolean) => {
    const clone = [...workDays[0]];

    for (let i = 0; i < clone.length; i++) {
      if (clone[i].type === r.type) {
        isStart ?
          clone[i].start = time :
          clone[i].end = time;
      }
    }

    workDays[1](clone);
  }

  useEffect(() => {
    const columns: Column<WorkDay>[] = [
      {
        title: locale.en.settings.workingDay.tableColumn.day,
        render: r => r.type
      },
      {
        title: locale.en.settings.workingDay.tableColumn.startTime,
        render: r => (
          <TimePicker
            date={r.start}
            onChange={d => handleTimeChange(r, d, true)}
          />
        )
      },
      {
        title: locale.en.settings.workingDay.tableColumn.endTime,
        render: r => (
          <TimePicker
            date={r.end}
            onChange={d => handleTimeChange(r, d, false)}
          />
        )
      },
      {
        title: locale.en.settings.workingDay.tableColumn.totalHour,
        width: "100rem",
        render: r => `${prependZero(differenceInHours(r.end, r.start))} hours`
      }
    ];

    setColumns(columns);
  }, [workDays[0].length]);

  return columns;
}