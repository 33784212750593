import React from 'react';
import styled from 'styled-components/macro';
import { TimePicker } from 'react-next-dates';
import { enUS } from 'date-fns/locale';
import { format } from 'date-fns';

import datetime from 'config/datetime';

import Input from 'components/lib/Input';
import Clock, { Wrapper as _Clock } from 'components/svg/Clock';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 200rem;

  .react-next-dates {
    margin-top: 3rem;
    top: 100% !important;
  }

  .clock-selection,
  .clock-selection::before,
  .clock-selection::after {
    background: var(--primary-color) !important;
  }

  ${_Clock} { height: 14rem }
`;

export default ({ date, onChange }: {
  date: Date,
  onChange?: (d: Date) => void
}) => {
  return (
    <Wrapper>
      <TimePicker
        locale={enUS}
        onChange={d => d && onChange && onChange(d)}
        date={date}
      >
        {({ openTimePicker }) =>
          <Input
            icon={<Clock color="var(--primary-color)" />}
            value={format(date, datetime.format.timePicker)}
            onClick={openTimePicker}
          />
        }
      </TimePicker>
    </Wrapper>
  );
}