import create from "zustand";
import type { Key } from "config/localStorage";

type StoreSchema = { [k in Key]: string } & {
  setAuth: (arg: { [k in Key]?: string }) => void
}

export default create<StoreSchema>(set => ({
  tokenUser: "",
  orgId: "",
  uId: "",
  path: "",
  role: "",

  setAuth: (arg: { [k in Key]?: string }) => set(state => {
    const clone = { ...state };

    for (const [k, v] of Object.entries(arg)) {
      clone[k as Key] = v;
    }

    return clone;
  })
}));