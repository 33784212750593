import Company from "./variants/Company";
import WorkingDay from "./variants/WorkingDay";
import Holiday from "./variants/Holiday";
import Department from "./variants/Department";
import LeaveType from "./variants/LeaveType";
import LeaveSetting from "./variants/LeaveSetting";

export default {
  Company,
  WorkingDay,
  Holiday,
  Department,
  LeaveType,
  LeaveSetting
}