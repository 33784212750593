import React from 'react';
import styled from 'styled-components/macro';
import { format } from 'date-fns';

export const Wrapper = styled.div`
  span { font-size: 12rem }
`;

export default ({ date }: { date: Date }) => {
  return (
    <Wrapper>
      <p>{format(date, 'MMM do, yyyy')}</p>
      <span>{format(date, 'a')}</span>
    </Wrapper>
  );
}