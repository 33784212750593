export default {
  leaveType: "Leave Type",

  table: {
    check: "Check",
    username: "Username",
    department: "Department",
    annEntitlement: "Annual Entitlement",
    currBalance: "Current Balance to Date",
    extraLeave: "Additional Leaves",
    consumed: "Consumed",
    totalCurrBalance: "Total Current Balance",
    projectedBalance: "Projected Balance to Year End"
  },

  modal: {
    adjust: {
      additionalLeave: "Adjust Additional Leave for {{ 1 }}",
      annualLeave: "Adjust Annual Leave for {{ 1 }}",
      title: "Adjust Leave Balance for {{ 1 }}",
      comments: "Comments",
      decrement: "-",
      increment: "+"
    },
    doubleConfirm: "Are you sure to adjust holiday balances for these users?",
    button: {
      ok: "Adjust",
      cancel: "Cancel"
    }
  },

  error: {
    emptyCheckbox: "Please select user(s) to adjust leave balance"
  }
}