import React from "react";
import { Route } from "react-router-dom";

import locale from "locale";
import type { Route as RouteType } from "./config/routes";

import useNavStore from "./stores/useNavStore";
import packageJson from "../package.json";

export function generateRoute(
  routes: { [k: string]: RouteType },
  currentPath: string
) {
  const nodes: React.ReactNode[] = [];

  Object.entries(routes).forEach(([, item]) => {
    if (item.render.component) {
      nodes.push(
        <Route
          key={item.render.url}
          path={item.render.url}
          element={item.render.component}
        />
      );

      if (item.render.url === currentPath) {
        const navigate = useNavStore.getState().navigate;
        navigate && navigate(item);
      }
    }

    if (item.routes) {
      const childNodes = generateRoute(item.routes, currentPath);
      nodes.push(...childNodes);
    }
  });

  return nodes;
}

export function fillRouteParam(
  paramUrl: string,
  replace: { param: string; value: string }[]
) {
  let normalUrl = paramUrl; //clone

  replace.forEach(({ param, value }) => {
    normalUrl = normalUrl.replace(`:${param}`, value);
  });

  return normalUrl;
}

export function prependZero(v: number) {
  return v > 0 && v < 10 ? (/^0*$/.test(v.toString()) ? `0${v}` : v.toString()) : v.toString();
}

export function getLeaveDuration(days: number) {
  let duration = prependZero(days);
  return locale.en.common.leaveDuration.replace("{{ 1 }}", duration);
}

export function getName(firstName: string, lastName: string) {
  return `${firstName} ${lastName}`;
}

export const getVersion = () => {
  return packageJson?.version ? packageJson.version : "1.0.0";
}
