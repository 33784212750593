import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components/macro";
import { Routes, useLocation, Route, Navigate } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import backend from "config/backend";
import routes from "./config/routes";
import { generateRoute } from "./utils";
import useFixRoute from "hooks/useFixRoute";
import useRoutePermission from "hooks/useRoutePermission";
import useSetupNavStore from "hooks/useSetupNavStore";
import useLocalStorage from "hooks/useLocalStorage";
import usePageChange from "hooks/usePageChange";

import Sidebar, { Wrapper as _Sidebar } from "./components/Sidebar";
import Header from "./components/Header";
import Login from "pages/Login";
import Register from "pages/Register";

import JumpAuthorize from "pages/JumpAuthorize";

import useAuthStore from "./stores/useAuthStore";
import { verifyToken, verifyTokenAsync } from "./redux/features/auth/authSlice";
// import { loginAsync } from "redux/features/auth/authSlice";
import { useDispatch } from "react-redux";

import api from "api";
import { getYear } from "date-fns";
import { fetchHolidays } from "redux/features/holidays/holidaySlice";

const Wrapper = styled.main`
  --split-threshold: 230rem;

  position: relative;
  min-height: 100vh;
  width: 100%;

  ${_Sidebar} {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: var(--split-threshold);
    min-height: 100vh;
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: var(--split-threshold);
  right: 0;

  background: inherit;

  display: flex;
  flex-direction: column;
  padding: 0 30rem;
`;

const Body = styled.div`
  padding: 20rem 0;
`;

function App() {
  /**
   * Hooks
   */
  //react-router-dom
  const location = useLocation();
  const gotToken = location.pathname.includes("middlepage");
  const setAuth = useAuthStore((state) => state);
  const [year, setYear] = useState(new Date());
  const [showRegister, setShowRegister] = useState(false);

  const [hasLogin, setHasLogin] = useState(false);
  const [cacheLocal, setCacheLocal] = useState(false);

  const nodes = useMemo(() => generateRoute(routes, location.pathname), []);
  const dispatch = useDispatch<any>();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            cacheTime: 0,
            staleTime: backend.staleTime.general, //2min
            retry: false,
          },
        },
      }),
    []
  );

  useFixRoute();
  useSetupNavStore();
  useRoutePermission();
  useLocalStorage(setHasLogin, cacheLocal);
  usePageChange(hasLogin);

  // const login = async () => {
  //   //   api.auth.verifyToken(setAuth.tokenUser, setAuth.orgId).then((data) => {
  //   //     dispatch(login(data));
  //   //     setCacheLocal(true);
  //   // }
  //   // const res = await api.auth.verifyToken(setAuth.tokenUser, setAuth.orgId);
  //   // dispatch(login(res));
  //   setCacheLocal(true);
  // };

  useEffect(() => {
    if (setAuth.uId && setAuth.orgId) {
      dispatch(fetchHolidays(getYear(year)));
    }
  }, [setAuth]);

  /**
   * Render
   */
  return (
    <Wrapper>
      {!hasLogin && !gotToken && showRegister && (
        <Register
          setHasLogin={setHasLogin}
          setShowRegister={setShowRegister}
          showRegister={showRegister}
        />
      )}
      {!hasLogin && !gotToken && !showRegister && (
        <Login
          setHasLogin={setHasLogin}
          cacheLocal={[cacheLocal, setCacheLocal]}
          setShowRegister={setShowRegister}
          showRegister={showRegister}
        />
      )}

      {!hasLogin && gotToken && (
        <JumpAuthorize
          setHasLogin={setHasLogin}
          cacheLocal={[cacheLocal, setCacheLocal]}
        />
      )}

      {hasLogin && (
        <>
          <Container>
            <Header setHasLogin={setHasLogin} />
            <Sidebar setHasLogin={setHasLogin} />

            <Body>
              <QueryClientProvider client={queryClient}>
                <Routes>
                  {nodes}

                  <Route
                    path="*"
                    element={<Navigate to={routes.error.render.url} replace />}
                  />
                </Routes>
              </QueryClientProvider>
            </Body>
          </Container>
        </>
      )}
    </Wrapper>
  );
}

export default App;
