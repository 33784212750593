import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

import locale from "locale";
import api from "api";
import routes from "config/routes";
import useForm from "../Form/useForm";

import Form from "../Form";
import useNavStore from "stores/useNavStore";

export default () => {
  /**
   * Hooks
   */
  const { id } = useParams<{ id: string }>();
  const navigate = useNavStore((state) => state.navigate);
  const nativeNavigate = useNavigate();

  const queryClient = useQueryClient();

  const { data, error } = useQuery({
    queryKey: [api.user.queryKey, id],
    queryFn: () => api.user.getById(id!),
    enabled: !!id && !!navigate,
  });

  const mutation = useMutation(api.user.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.user.queryKey);
      queryClient.invalidateQueries([api.user.queryKey, id]);

      const message = locale.en.toastMessages.updateSuccess.replace(
        "{{ 1 }}",
        "User profile"
      );
      toast.success(message);

      // navigate!(routes.users);
      nativeNavigate(-1);
    },
  });

  const { form, setForm } = useForm();

  useEffect(() => {
    data && setForm({ ...data });
  }, [data]);

  useEffect(() => {
    if (error && navigate) {
      const route = routes.error;
      navigate(route);
    }
  }, [error]);

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  /**
   * Not hook
   */
  const handleUpdate = (e: React.MouseEvent) => {
    console.log("ON UPDate")
    e.preventDefault();
    if (!validateEmail(form.email)) {
      return toast.error("enter valid email!");
    }
    // if (id) {
    //   const user = { id, ...form };
    //   api.user.update(user).then((data) => {
    //     // console.log(data);
    //   });
    // }
    if (!navigate) return;

    id && mutation.mutate({ id, ...form });
  };

  /**
   * Render
   */
  return (
    <Form
      {...form}
      setForm={setForm}
      okText={locale.en.common.button.update}
      onOk={handleUpdate}
    />
  );
};
