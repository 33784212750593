import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";

export const initialState = {
  loading: false,
  hasErrors: false,
  approvals: [],
};

const getMyApprovalSlice = createSlice({
  name: "approval",
  initialState,
  reducers: {
    // getRepos: (state) => {
    //   state.loading = true;
    // },
    getMyApproval: (state, action) => {
      state.approvals = action.payload;
      //   state.loading = false;
      //   state.hasErrors = false;
    },
    create: (state, action) => {},
    // getReposFailure: (state) => {
    //   state.loading = false;
    //   state.hasErrors = true;
    // },
  },
});

export const {
  // getRepos,
  getMyApproval,
  // getReposFailure
} = getMyApprovalSlice.actions;

// The reducer
export default getMyApprovalSlice.reducer;

export const fetchApprovals = createAsyncThunk(
  "approval/fetchApprovals",
  async (data: any, thunkAPI: any) => {
    try {
      //   console.log("dispatching now");
      const response = await api.leave.list({ approverUid: data.approverUid });
      // console.log("response", response);
      // Set the data
      data.setState(response);
      thunkAPI.dispatch(getMyApproval(response));
      //   thunkAPI.dispatch(getdeptSucess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);
