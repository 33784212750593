import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";

export const initialState = {
  loading: false,
  hasErrors: false,
  requests: [],
};

const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    // getRepos: (state) => {
    //   state.loading = true;
    // },
    getRequest: (state, action) => {
      state.requests = action.payload;
      //   state.loading = false;
      //   state.hasErrors = false;
    },
    create: (state, action) => {},
    // getReposFailure: (state) => {
    //   state.loading = false;
    //   state.hasErrors = true;
    // },
  },
});

export const {
  // getRepos,
  getRequest,
  // getReposFailure
} = requestSlice.actions;

// The reducer
export default requestSlice.reducer;

export const fetchRequest = createAsyncThunk(
  "request/fetchRequest",
  async (userUid: string, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());

    try {
      //   console.log("dispatching now");
      const response = await api.leave.list({ userUid });
      console.log("REsponse", response)
      //   console.log(response);
      // Set the data
      thunkAPI.dispatch(getRequest(response));
      //   thunkAPI.dispatch(getdeptSucess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);
