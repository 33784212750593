import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Login from "../../../api/auth";
import { Dispatch } from "redux";
// import authService from "./authService";
import User from "../../../types/auth";
import api from "api";

// get user from LC

const initialState = {
  users: [],
};

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    list: (state, action) => {
      state.users = action.payload;
    },
  },
});

// export const listUser =
//   (email: string, password: string) => async (dispatch: Dispatch) => {
//     try {
//       // console.log(data);
//       const response = await Login.login(email, password);
//       // console.log(response);
//       dispatch(list(response));
//     } catch (err: any) {
//       throw new Error(err);
//     }
//   };

export const { list } = userSlice.actions;

export default userSlice.reducer;

export const listUsersAsync = createAsyncThunk(
  "user/listUser",
  async (check, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());
    console.log({ thunkAPI });

    try {
      //   console.log("dispatching now");

      const response = await api.user.list();
      //   console.log(response);

      // Set the data
      //   thunkAPI.dispatch(getdeptSucess(response));
      thunkAPI.dispatch(list(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);
