import React, { useState } from "react";
import styled from "styled-components/macro";

import locale from "locale";
import LabelRegister from "components/lib/LabelRegister";
import Input from "components/lib/Input";
import InputDate from "components/lib/InputDate";
import Checkbox from "components/lib/Checkbox";
import DatePicker from "components/common/DatePicker";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Fields = styled.div`
  display: flex;
  flex-direction: column;

  gap: 15rem;
`;

const Extra = styled.div`
  margin-top: 7rem;
  display: flex;
  justify-content: space-between;

  font-size: 11rem;
  color: #777;
`;

const RememberMe = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  cursor: pointer;
`;

export default ({
  firstName,
  lastName,
  userName,
  email,
  password,
  confirmPassword,
  uen,
  orgName,
  date,
}: // remember,
{
  firstName: [string, (s: string) => void];
  lastName: [string, (s: string) => void];
  email: [string, (s: string) => void];
  userName: [string, (s: string) => void];
  orgName: [string, (s: string) => void];
  uen: [string, (s: string) => void];
  date: [Date | undefined, (s: Date) => void];
  password: [string, (s: string) => void];
  confirmPassword: [string, (s: string) => void];

  // remember: [boolean, (b: boolean) => void];
}) => {
  /**
   * Hooks
   */
  const [errorEmail, setErrorEmail] = useState<string>();
  const [errorUserName, SetErrorUserName] = useState<string>();
  const [errorConfirmPassword, SetErrorConfirmPasssword] = useState<string>();
  // const [errorUserName, SetErrorUserName] = useState<string>();

  const [errorPw, setErrorPw] = useState<string>();

  /**
   * Not hook
   */

  const handleFirstName = (s: string) => {
    firstName[1](s);
  };
  const handleLastName = (s: string) => {
    lastName[1](s);
  };
  const handleUserName = (s: string) => {
    if (!s) SetErrorUserName(locale.en.common.formValidation.required);
    else SetErrorUserName(undefined);
    userName[1](s);
  };
  const handleOrgName = (s: string) => {
    orgName[1](s);
  };
  const handleUENName = (s: string) => {
    uen[1](s);
  };
  const handleDate = (s: Date) => {
    date[1](s);
  };
  const handleEmailChange = (s: string) => {
    if (!s) setErrorEmail(locale.en.common.formValidation.required);
    else setErrorEmail(undefined);

    email[1](s);
  };

  const handlePasswordChange = (s: string) => {
    if (!s) setErrorPw(locale.en.common.formValidation.required);
    else setErrorPw(undefined);

    password[1](s);
  };
  const handleConfirmPasswordChange = (s: string) => {
    if (!s) SetErrorConfirmPasssword(locale.en.common.formValidation.required);
    else SetErrorConfirmPasssword(undefined);

    confirmPassword[1](s);
  };

  /**
   * Render
   */
  return (
    <>
      <Fields>
        <Wrapper>
          <LabelRegister title={locale.en.register.firstName}>
            <Input value={firstName[0]} onChange={handleFirstName} />
          </LabelRegister>
          <LabelRegister title={locale.en.register.lastName}>
            <Input value={lastName[0]} onChange={handleLastName} />
          </LabelRegister>
        </Wrapper>

        <Wrapper>
          <LabelRegister title={locale.en.register.email}>
            <Input value={email[0]} onChange={handleEmailChange} />
          </LabelRegister>
          <LabelRegister
            title={locale.en.register.userName}
            error={errorUserName}
          >
            <Input value={userName[0]} onChange={handleUserName} />
          </LabelRegister>
        </Wrapper>

        <Wrapper>
          <LabelRegister title={locale.en.login.password} error={errorPw}>
            <Input value={password[0]} onChange={handlePasswordChange} />
          </LabelRegister>
          <LabelRegister
            title={locale.en.register.confirmPassword}
            error={errorConfirmPassword}
          >
            <Input
              value={confirmPassword[0]}
              onChange={handleConfirmPasswordChange}
            />
          </LabelRegister>
        </Wrapper>

        <Wrapper>
          <LabelRegister title={locale.en.register.organizationName}>
            <Input value={orgName[0]} onChange={handleOrgName} />
          </LabelRegister>
          <LabelRegister title={locale.en.register.uen}>
            <Input value={uen[0]} onChange={handleUENName} />
          </LabelRegister>
        </Wrapper>

        <Wrapper>
          <LabelRegister title={locale.en.register.dateIncorporation}>
            <DatePicker value={date[0]} onChange={handleDate} />
          </LabelRegister>
        </Wrapper>
      </Fields>
    </>
  );
};
