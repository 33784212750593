import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import locale from "locale";
import api from "api";

import Label from "components/lib/Label";
import Input from "components/lib/Input";
import AddressInputs from "components/common/AddressInputs";
import DatePicker from "components/common/DatePicker";
import EditPen, { Wrapper as _EditPen } from "components/svg/EditPen";
import Button from "components/lib/Button";
import TagInputs from "components/common/TagInputs";
import RegionInputs from "components/common/RegionInputs";
import useForm from "hooks/common/useForm";
import Corporate from "types/corporate";

const Wrapper = styled.div`
  --gap: 20rem;

  position: relative;
  max-width: 800rem;
  padding: var(--container-padding);
  padding-bottom: 30rem;

  margin: 0 auto;
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  ${_EditPen} {
    height: 20rem;
    align-self: end;
    cursor: pointer;
  }
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  gap: 10rem;
`;

export default () => {
  /**
   * Hooks
   */
  const [editMode, setEditMode] = useState(false);

  const { form, setForm } = useForm<Corporate>({
    name: "",
    alias: [],
    incorporation: new Date(),
    contact: "",
    country: "",
    stateRegion: "",
    city: "",
    postalCode: "",
    address: [""],
  });

  const queryClient = useQueryClient();

  const { data } = useQuery(api.corporate.queryKey, api.corporate.get);

  const mutation = useMutation(api.corporate.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.corporate.queryKey);

      const message = locale.en.toastMessages.updateSuccess.replace(
        "{{ 1 }}",
        "Company information"
      );
      toast.success(message);

      setEditMode(false);
    },
  });

  useEffect(() => {
    data && setForm({ ...data });
  }, [data]);

  useEffect(() => {
    if (!editMode) handleReset();
  }, [editMode]);

  /**
   * Not hooks
   */
  const handleReset = () => {
    if (!data) return;
    setForm({ ...data });
  };

  const handleUpdate = () => {
    if (!editMode) return;
    mutation.mutate({ ...form });
  };

  /**
   * Render
   */
  return (
    <Wrapper>
      <EditPen
        color={editMode ? "var(--primary-color)" : "#ccc"}
        onClick={() => setEditMode(!editMode)}
      />

      <Fields>
        <Label title={locale.en.settings.company.name}>
          <Input
            readOnly={!editMode}
            value={form.name}
            onChange={(s) => setForm({ name: s })}
          />
        </Label>

        <Label title={locale.en.settings.company.alias}>
          <TagInputs
            readOnly={!editMode}
            value={form.alias}
            onChange={(t) => setForm({ alias: t })}
          />
        </Label>

        <Label title={locale.en.settings.company.incorporationDate}>
          <DatePicker
            readOnly={!editMode}
            value={form.incorporation}
            onChange={(d) => setForm({ incorporation: d })}
          />
        </Label>

        <Label title={locale.en.settings.company.contactNo}>
          <Input
            readOnly={!editMode}
            value={form.contact}
            onChange={(s) => {
              const re = /^[0-9\b]+$/;
              if (s === "" || re.test(s)) {
                setForm({ contact: s });
              }
            }}
          />
        </Label>

        <RegionInputs
          readOnly={!editMode}
          country={form.country}
          stateRegion={form.stateRegion}
          city={form.city}
          onChange={(country, stateRegion, city) =>
            setForm({
              country,
              stateRegion,
              city,
            })
          }
        />

        <Label title={locale.en.settings.company.postalCode}>
          <Input
            readOnly={!editMode}
            value={form.postalCode}
            onChange={(s) => setForm({ postalCode: s })}
          />
        </Label>

        <AddressInputs
          readOnly={!editMode}
          value={form.address}
          onChange={(a) => setForm({ address: a })}
        />
      </Fields>

      {editMode && (
        <ButtonGroup>
          <Button.Ghost onClick={handleReset}>
            {locale.en.common.button.reset}
          </Button.Ghost>

          <Button.Classic onClick={handleUpdate}>
            {locale.en.common.button.update}
          </Button.Classic>
        </ButtonGroup>
      )}
    </Wrapper>
  );
};
