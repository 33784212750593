import React, { useEffect, useRef, useState } from 'react';
import Input from './Input';

export default ({ value, format, onChange, readOnly = false }: {
  value: number,
  format: (n: number) => string,
  onChange?: (n: number) => void,
  readOnly?: boolean
}) => {

  const ref = useRef<HTMLInputElement>(null);

  const [editMode, setEditMode] = useState(false);
  const [_value, setValue] = useState(value.toString());

  useEffect(() => {
    setValue(value.toString());
  }, [value]);

  const handleChange = (s: string) => {
    if (!editMode) {
      setEditMode(true);
      setValue(s.slice(-1));
    }
    else {
      setValue(s);
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && ref.current) {
      ref.current.blur();
    }
  }

  const handleBlur = () => {
    if (!onChange) return;

    setEditMode(false);

    if (/^[\d]*$/.test(_value)) {
      onChange(Number(_value));
    }
    else {
      setValue(value.toString());
    }
  }

  return (
    <Input
      ref={ref}
      value={editMode ? _value : format(value)}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      readOnly={readOnly}
    />
  )
}