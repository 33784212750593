import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";

import type { Column, Object } from "./types";
import Pagination from "./Pagination";
import { getDay, getMonth, parseISO, getDate } from "date-fns";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

export const Grid = styled.div(
  ({ $gridCol }: { $gridCol: string }) => `
  display: grid;
  grid-auto-rows: 1fr;
  grid-template-rows: min-content 1fr;
  grid-template-columns: ${$gridCol};
`
);

export const Cell = styled.div(
  ({ $lastRow }: { $lastRow: boolean }) => `
  padding: 10px;
  white-space: pre;

  display: flex;
  align-items: center;

  ${
    !$lastRow
      ? `
  border-bottom: 1px solid #ddd;
  `
      : ""
  }
`
);

export const Header = styled(Cell)`
  font-weight: 600;
  white-space: normal;
  align-items: start;
`;

export const Empty = styled.div`
  padding: 20rem 0;
  font-size: 16rem;
  color: #aaa;

  display: flex;
  justify-content: center;
`;

export default <T extends Object>({
  data,
  columns,
  totalData,
  currentPage,
  setCurrentPage,
  pageSize,
  date,
}: {
  data: T[];
  columns: Column<T>[];
  totalData: number;
  currentPage: number;
  setCurrentPage: any;
  pageSize?: number;
  date?: Date;
}) => {
  const gridCol = useMemo(() => {
    const width = columns.reduce((prev, current) => {
      return `${prev} ${current.width ?? "1fr"}`;
    }, "");

    return width;
  }, [columns.length]);

  /**
   * Render
   */
  return (
    <Wrapper>
      <Grid $gridCol={gridCol} className="table">
        {columns.map((c, i) => (
          <Header key={i} style={c.style} $lastRow={false}>
            {c.title}
          </Header>
        ))}

        {data.map((d, i) => {
          let myLeaves = getLeavesData(d?.leaves, date);
          return columns.map((c, j) => {
            const day = c.render(d);

            return (
              <Cell
                style={{
                  marginRight: i * columns.length + j === 0 ? "24px" : "0px",
                }}
                key={i * columns.length + j}
                $lastRow={i === data.length - 1}
              >
                <div
                  style={{
                    width: myLeaves.find((item: any) => item.leave === day)
                      ? "15px"
                      : "",
                    backgroundColor:
                      myLeaves.find((item: any) => item.leave === day)?.type ===
                      "sick"
                        ? "red"
                        : myLeaves.find((item: any) => item.leave === day)
                            ?.type === "annual"
                        ? "blue"
                        : "transparent",
                    color: myLeaves.find((item: any) => item.leave === day)
                      ? "white"
                      : "",
                  }}
                >
                  {c.render(d)}
                </div>
              </Cell>
            );
          });
        })}
      </Grid>

      {!data.length && <Empty>No data found</Empty>}

      {data.length > 0 && pageSize && (
        <Pagination
          totalCount={totalData}
          pageSize={pageSize}
          currentPage={currentPage}
          onBoxClick={setCurrentPage}
        />
      )}
    </Wrapper>
  );
};

const getLeavesData = (leaves: any, date: any) => {
  let leaveCopy: any = [];
  leaves?.map((item: any) => {
    for (let loopDate = new Date(item.start); loopDate <= new Date(item.end); loopDate.setDate(loopDate.getDate() + 1)) {
      let hello = new Date(loopDate);
      let hello1 = new Date(date);
  
      if (
        hello.getDate() &&
        hello1.getMonth() === hello.getMonth() &&
        hello.getFullYear() === hello1.getFullYear()
      ) {
        const leavee = hello.getDate() < 10 ? "0" + hello.getDate() : hello.getDate();
        leaveCopy.push({ leave: leavee, type: item.type });
      }
    }
  });
  return leaveCopy;
};
