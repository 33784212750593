import React, { useState } from "react";
import styled from "styled-components/macro";

export const Wrapper = styled.div(
  ({ $focus, $readOnly }: { $focus: boolean; $readOnly: boolean }) => `
  width: 100%;
  padding: 7rem 15rem;

  display: flex;
  align-items: center;
  gap: 5rem;

  border-radius: 4rem;
  border: 1rem solid #ccc;
  background: white;
  
  transition: 0.25s border-color;
  
  border-color: ${$focus ? "var(--primary-color)" : "#ccc"};
  cursor: ${$readOnly ? "not-allowed" : "auto"};
`
);

export const Input = styled.input`
  width: 100%;

  outline: none;
  border: none;
  font-family: var(--font-family);
  background: transparent;

  cursor: inherit;
`;

const Icon = styled.picture(
  ({ $readOnly }: { $readOnly: boolean }) => `
  cursor: ${$readOnly ? "not-allowed" : "pointer"};
  display: block;
  vertical-align: center;
`
);

export default React.forwardRef(
  (
    {
      value,
      placeholder,
      onChange,
      onChangeNumber,
      onClick,
      onBlur,
      onKeyDown,
      readOnly = false,
      icon,
      type,
    }: {
      value?: string | number;
      placeholder?: string;
      onChange?: (s: string) => void;
      onChangeNumber?: (s: number) => void;
      onClick?: () => void;
      onBlur?: () => void;
      onKeyDown?: (e: React.KeyboardEvent) => void;
      readOnly?: boolean;
      icon?: JSX.Element;
      type?: string;
    },
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const [focus, setFocus] = useState(false);

    const handleBlur = () => {
      onBlur && onBlur();
      setFocus(false);
    };

    return (
      <Wrapper
        tabIndex={-1}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onClick={onClick}
        $readOnly={readOnly}
        $focus={focus}
      >
        <Input
          ref={ref}
          value={value}
          placeholder={placeholder}
          onChange={(e) => {
            onChange && onChange(e.currentTarget.value);
            onChangeNumber && onChangeNumber(Number(e.currentTarget.value));
          }}
          onKeyDown={onKeyDown}
          readOnly={readOnly}
          onFocus={() => setFocus(true)}
          onBlur={handleBlur}
          type={type}
        />

        <Icon $readOnly={readOnly}>{icon}</Icon>
      </Wrapper>
    );
  }
);
