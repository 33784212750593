import React from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";

import locale from "locale";
import api from "api";
import type { Status } from "types/leave";
import { getLeaveDuration, getName } from "utils";

import Label from "components/lib/Label";
import Input from "components/lib/Input";
import TextArea from "components/lib/TextArea";
import DateRangePicker from "components/common/DateRangePicker";
import NumberInput from "components/lib/NumberInput";

import DocumentText from "components/svg/DocumentText";

export const Wrapper = styled.div`
  --gap: 20rem;

  padding: var(--container-padding);
  padding-top: 30rem;
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);

  & .row {
    grid-column: 1 / span 2;
  }
`;

export default ({
  start,
  end,
  duration,
  leaveReason,
  approverComment,
  statusHr,
  statusSv,
  recipient,
  approver,
  typeName,
  deductOn,
  proof
}: {
  start: Date;
  end: Date;
  duration: number;
  leaveReason: string;
  approverComment: string;
  statusHr: Status;
  statusSv: Status;
  recipient: any;
  approver: any;
  typeName: string,
  deductOn: string,
  proof: string
}) => {
  const { data: userData } = useQuery({
    queryKey: api.user.queryKey,
    queryFn: api.user.list,
  });

  const approverData = userData?.filter((u) => u.id === approver)[0];
  const recipientData = userData?.filter((u) => u.id === recipient)[0];

  return (
    <Wrapper>
      <DateRangePicker
        className="row"
        start={{ value: start, readOnly: true }}
        end={{ value: end, readOnly: true }}
      />

      <Label title={locale.en.myRequest.form.duration}>
        <NumberInput value={duration} format={getLeaveDuration} readOnly />
      </Label>

      <Label title={locale.en.myRequest.form.type}>
        <Input value={typeName} readOnly />
      </Label>

      <Label title={locale.en.myRequest.form.deductType}>
        <Input value={deductOn} readOnly />
      </Label>

      <Label title={locale.en.myRequest.form.reason}>
        <Input value={leaveReason} readOnly />
      </Label>

      <Label title={locale.en.myRequest.form.status}>
        <Input
          value={
            statusHr === "approved" && statusSv === "approved"
              ? "approved"
              : "pending"
          }
          readOnly
        />
      </Label>

      <Label title={locale.en.myRequest.form.recipient}>
        <Input
          value={
            recipient && recipient.length
              ? getName(recipient[0]?.firstName, recipient[0]?.lastName)
              : ""
          }
          readOnly
        />
      </Label>

      <Label title={locale.en.myRequest.form.approver}>
        <Input
          value={
            approver && approver.length
              ? getName(approver[0]?.firstName, approver[0]?.lastName)
              : ""
          }
          readOnly
        />
      </Label>

      <span />

      <Label className="row" title={locale.en.myRequest.form.comment}>
        <TextArea value={approverComment} readOnly />
      </Label>

      {
        (proof && proof !== "") && (
          <Label title={"Attachment"}>
            <a style={{cursor: "pointer", display: "flex", alignItems: "center"}} onClick={() => window.open(proof, "_blank")}>
              <DocumentText /> Click to open file
            </a>
          </Label>
        )
      }
    </Wrapper>
  );
};
