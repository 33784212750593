import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import routes from "config/routes";

import Table from "./Table";
import useColumns from "./useColumns";
import api from "api";
import useAuthStore from "stores/useAuthStore";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { fetchApprovals } from "redux/features/myApproval/myApprovalSlice";
import useUserStore from "stores/useUserStore";
import { generateRegex } from "components/common/SearchInput/utils";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40rem;
`;

export default () => {
  const uId = useAuthStore.getState().uId || undefined;
  const user = useUserStore((state) => state.user);

  const [state, setState] = useState<any>([]);
  const [department, setDepartment] = useState<any>([]);

  const columns = {
    pending: useColumns(routes.myApprovals.routes!.pending),
    approved: useColumns(routes.myApprovals.routes!.approved),
  };

  const { data: userData } = useQuery({
    queryKey: api.user.queryKey,
    queryFn: api.user.list,
  });

  const { data: deptData } = useQuery({
    queryKey: [api.department.queryKey, "active"],
    queryFn: () => api.department.list("active"),
  });

  const [modalVisible, setModalVisible] = useState(false);
  // const [revokeUser, setRevokeUser] = useState<User>();

  // const columns = useColumns((u: User) => {
  //   setRevokeUser(u);
  //   setModalVisible(true);
  // });

  //Filter
  const [showDept, setShowDept] = useState<string[]>([]);
  const [search, setSearch] = useState("");
  const [showDept1, setShowDept1] = useState<string[]>([]);
  const [search1, setSearch1] = useState("");
  const dispatch = useDispatch<any>();

  // const dispatch = useDispatch<any>();

  let pending = state.filter(
    (leave: any) => leave.supervisor.status == "pending"
  );
  let history = state.filter(
    (leave: any) => leave.supervisor.status !== "pending"
  );

  let pendingCopy = pending.map((item: any) => ({
    id: item.id,
    name: item?.userId?.userName,
    supervisor: item?.supervisor?.name,
    start: item?.start,
    startAPM: item?.startAPM,
    end: item?.end,
    endAPM: item?.endAPM,
    type: item?.type,
    typeName: item?.typeName,
    duration: item?.duration,
    reason: item?.leaveReason,
    comments: item?.approverComment,
    thumbnail:
      item?.userId?.profile && item?.userId?.profile !== ""
        ? item?.userId?.profile
        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png",
    status: item.supervisor.status,
  }));

  let historyCopy = history?.map((item: any) => ({
    id: item.id,
    name: item?.userId?.userName,
    supervisor: item?.supervisor?.name,
    start: item?.start,
    startAPM: item?.startAPM,
    end: item?.end,
    endAPM: item?.endAPM,
    type: item?.type,
    typeName: item?.typeName,
    duration: item?.duration,
    reason: item?.leaveReason,
    comments: item?.approverComment,
    thumbnail:
      item?.userId?.profile && item?.userId?.profile !== ""
        ? item?.userId?.profile
        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png",
    status: item.supervisor.status,
  }));
  const searchRegex = generateRegex(search);

  const filteredData = pendingCopy?.filter((d: any) => {
    const matchDept =
      showDept.includes(d.departmentId) || d.departmentId === "";
    const matchSearch = `${d.firstName} ${d.lastName}`.match(searchRegex);

    return matchDept && matchSearch;
  });

  // console.log("showdept", showDept);

  useEffect(() => {
    if (deptData && deptData.length) {
      setShowDept(deptData.map((d) => d.id!));
      setShowDept1(deptData.map((d) => d.id!));
      setDepartment(deptData.map((d) => ({ id: d.id, display: d.name })));
    }
  }, [deptData]);

  useEffect(() => {
    dispatch(fetchApprovals({ approverUid: user?.id, setState }));
  }, [user?.id, dispatch]);

  return (
    <Wrapper>
      <Table
        title="Pending Approvals"
        options={department}
        users={pendingCopy}
        columns={columns.pending}
        setSearch={setSearch}
        search={search}
        showDept={showDept}
        setShowDept={setShowDept}
      />

      <Table
        title="Approval History"
        options={department}
        users={historyCopy}
        columns={columns.approved}
        setSearch={setSearch1}
        search={search1}
        showDept={showDept1}
        setShowDept={setShowDept1}
      />
    </Wrapper>
  );
};
