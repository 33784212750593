import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import locale from "locale";

import XWithRing, { Wrapper as _XWithRing } from "components/svg/XWithRing";
import Label from "components/lib/Label";
import Input from "components/lib/Input";
import DateRangePicker from "components/common/DateRangePicker";
import TextArea from "components/lib/TextArea";
import Button from "components/lib/Button";
import { useNavigate } from "react-router-dom";

import DocumentText from "components/svg/DocumentText";

const Wrapper = styled.div`
  --gap: 20rem;

  position: relative;
  max-width: var(--form-max-width);
  padding: var(--container-padding);
  padding-top: 35rem;

  margin: 0 auto;
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);

  display: flex;
  flex-direction: column;
  gap: 25rem;

  & > ${_XWithRing} {
    position: absolute;
    top: 12rem;
    right: 12rem;
    height: 20rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: var(--gap);
`;

const ButtonGroup = styled.div(
  ({ $hide }: { $hide: boolean }) => `
  margin-top: 30rem;

  display: flex;
  justify-content: end;
  gap: 10rem;

  ${
    $hide &&
    `
    visibility: hidden;
  `
  }
`
);

type Defaults = {
  page?: string;
  username?: string;
  type?: string;
  typeName?: string;
  start?: Date;
  end?: Date;
  duration?: string;
  reason?: string;
  comments?: string;
  deductOn?: string;
  proof?: string;
  setComment?: any;
  handleModify?: () => void;
  handleReject?: () => void;
};

export default ({
  readOnly = false,
  defaults,
}: {
  readOnly?: boolean;
  defaults?: Defaults;
}) => {
  const [username, setUsername] = useState("");
  const [type, setType] = useState("");
  const [typeName, setTypeName] = useState("");
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [duration, setDuration] = useState("");
  const [reason, setReason] = useState("");
  const [deductOn, setDeductOn] = useState("");
  const [comments, setComments] = useState("");
  const [proof, setProof] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setUsername(defaults?.username || "");
    setType(defaults?.type || "");
    setTypeName(defaults?.typeName || "");
    setStart(defaults?.start);
    setEnd(defaults?.end);
    setDuration(defaults?.duration || "");
    setReason(defaults?.reason || "");
    setDeductOn(defaults?.deductOn || "");
    setComments(defaults?.comments || "");
    setProof(defaults?.proof || "");
  }, [defaults]);

  return (
    <Wrapper>
      <XWithRing color="var(--primary-color)" onClick={() => navigate(-1)} />

      <FlexContainer>
        <Label title={locale.en.myApproval.username}>
          <Input value={username} onChange={setUsername} readOnly={readOnly} />
        </Label>

        <Label title={locale.en.myApproval.type}>
          <Input value={typeName} onChange={setType} readOnly={readOnly} />
        </Label>
      </FlexContainer>

      <DateRangePicker
        start={{
          value: start,
          readOnly,
        }}
        end={{
          value: end,
          readOnly,
        }}
      />

      <FlexContainer>
        <Label title={locale.en.myApproval.duration}>
          <Input value={duration} onChange={setDuration} readOnly={readOnly} />
        </Label>

        <Label title={locale.en.myApproval.reason}>
          <Input value={reason} onChange={setReason} readOnly={readOnly} />
        </Label>
      </FlexContainer>

      <FlexContainer>
        <Label title={locale.en.myApproval.deductType}>
          <Input value={deductOn} onChange={setDeductOn} readOnly={readOnly} />
        </Label>
      </FlexContainer>

      <Label title={locale.en.myApproval.comments}>
        <TextArea
          value={comments}
          onChange={defaults?.setComment}
          readOnly={defaults?.page === "pending" ? false : true}
        />
      </Label>

      {
          (proof && proof !== "") && (
            <div>
              <Label title={"Attachment"}>
                <a style={{cursor: "pointer", display: "flex", alignItems: "center"}} onClick={() => window.open(proof, "_blank")}>
                  <DocumentText /> Click to open file
                </a>
              </Label>
            </div>
          )
        }

      <ButtonGroup $hide={defaults?.page === "pending" ? false : true}>
        <Button.Ghost onClick={defaults?.handleReject}>
          {locale.en.myApproval.button.reject}
        </Button.Ghost>

        <Button.Classic onClick={defaults?.handleModify}>
          {locale.en.myApproval.button.approve}
        </Button.Classic>
      </ButtonGroup>
    </Wrapper>
  );
};
