import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import locale from 'locale';

import Input from 'components/lib/Input';
import Label, { Wrapper as _Label } from 'components/lib/Label';
import PlusCircle, { Wrapper as _PlusCircle } from 'components/svg/PlusCircle';
import PlusBubble, { Wrapper as _PlusBubble } from 'components/svg/PlusBubble';

const Container = styled.div`
  position: relative;

  ${_PlusCircle} {
    height: 18rem;
    transform: rotate(45deg);
    cursor: pointer;
  }
`;

const Title = styled.label`
  display: flex;
  align-items: center;
  gap: 5rem;
`;

const AddButton = styled.div(({ $readOnly }: { $readOnly: boolean }) => `
  position: absolute;
  top: calc(100% + 5rem);
  display: flex;
  gap: 5rem;

  cursor: ${$readOnly ? "not-allowed" : "pointer"};

  ${_PlusBubble} {
    height: 16rem;
    cursor: inherit;
  }
`);

export default ({ value, onChange, readOnly = false }: {
  value: string[],
  onChange?: (v: string[]) => void,
  readOnly?: boolean
}) => {
  /**
   * Not hook
   */
  const _value = !value.length ? [""] : value;

  const handleNewAddress = (index: number) => {
    if (readOnly || !onChange) return;

    const clone = [...value];
    clone.splice(index + 1, 0, "");

    onChange(clone);
  }

  const handleDeleteAddress = (index: number) => {
    if (readOnly || !onChange) return;

    const clone = [...value];
    clone.splice(index, 1);

    onChange(clone);
  }

  const handleAddressesChange = (s: string, index: number) => {
    if (readOnly || !onChange) return;

    const clone = [...value];
    clone[index] = s;

    onChange(clone);
  }

  /**
   * Render
   */
  return (
    <>
      {_value.map((ad, i) => {
        const title = (
          <Title>
            {`${locale.en.common.address.label} ${i > 0 ? (i + 1) : ''}`}
          </Title>
        );

        const crossIcon = (
          <PlusCircle
            color="#E53F9B"
            onClick={() => handleDeleteAddress(i)}
          />
        );

        return (
          <Label key={i} title={title}>
            <Container>
              <Input
                value={ad}
                onChange={s => handleAddressesChange(s, i)}
                icon={!readOnly && value.length > 1 ? crossIcon : undefined}
                readOnly={readOnly}
              />

              {i === _value.length - 1 && !readOnly ? (
                <AddButton
                  onClick={() => handleNewAddress(i)}
                  $readOnly={readOnly}
                >
                  {locale.en.common.address.add}
                  <PlusBubble color="var(--primary-color)" />
                </AddButton>
              ) : ""}
            </Container>
          </Label>
        );
      })}
    </>
  );
}