import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import api from 'api';
import { Supervisor } from 'types/user';
import useUserStore from 'stores/useUserStore';

export default () => {

  const [approvers, setApprovers] = useState<Supervisor[]>([]);
  const [recipients, setRecipients] = useState<Supervisor[]>([]);

  const user = useUserStore(state => state.user);

  const { data } = useQuery({
    queryKey: api.user.queryKey,
    queryFn: api.user.list
  });

  useEffect(() => {
    if (user && data && data.length > 0) {

      setApprovers(user.supervisors);

      const svIds = user.supervisors.map(s => s.id);
      setRecipients(data.filter(u => !svIds.includes(u.id) && u.id !== user.id));
    }
  }, [data, user]);

  return { approvers, recipients };
}