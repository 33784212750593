import React from 'react';
import styled from 'styled-components/macro';

import X, { Wrapper as _X } from 'components/svg/X';

export const Wrapper = styled.div(({ $color, $background }: {
  $color: string,
  $background: string
}) => `
  color: ${$color};
  background: ${$background};
  font-size: 10rem;

  padding: 2.5rem 10rem;
  border-radius: 4rem;

  display: inline-flex;
  align-items: center;
  gap: 7rem;

  ${_X} {
    width: 12rem;
    transform: translateY(1.5rem);
  }
`);

export default ({ children, color, background, onDelete }: {
  children: string,
  color: string,
  background: string,
  onDelete?: () => void
}) => (
  <Wrapper
    $color={color}
    $background={background}
  >
    {children}
    {onDelete && <X color="white" onClick={onDelete} />}
  </Wrapper>
);
