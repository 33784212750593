import axios from './axios';
import backend from 'config/backend';
import type LeaveBalance from 'types/leaveBalance';
import type { Balance as ClientBalance } from 'types/leaveBalance';

/**
 * Mapping
 */
type Balance = {
  annualEntitlement?: number,
  additionalLeave?: number,
  consumed?: unknown,
  currentBalanceTodate?: number,
  consumedLeave?: number,
  totalCurrentBalance?: number,
  projectedBalanceToYearEnd?: number,
  createdAt?: string,
  updatedAt?: string
}

type ServerSchema = {
  _id: string,
  balance: { [year: string]: Balance },
  createdAt: string,
  updatedAt: string,
  userData: {
    uId: string,
    firstName: string,
    lastName: string,
    departmentId: string,
    departmentDetail: {
      name: string
    }
  }
}

function mapToReact(response: ServerSchema): LeaveBalance {
  const balance: { [year: string]: ClientBalance } = {};

  for (const [year, detail] of Object.entries(response.balance)) {
    balance[year] = {
      annualEntitlement: detail.annualEntitlement ?? null,
      additionalLeave: detail.additionalLeave ?? null,
      currentBalance: detail.currentBalanceTodate ?? null,
      consumed: detail.consumedLeave ?? null,
      totalCurrentBalance: detail.totalCurrentBalance ?? null,
      projectedBalance: detail.projectedBalanceToYearEnd ?? null
    }
  }

  return {
    id: response._id,
    uId: response.userData.uId,
    username: response.userData.firstName,
    departmentId: response.userData.departmentId,
    department: response.userData.departmentDetail.name,
    balance
  }
}

/**
 * API Implementation
 */
const queryKey = "leaveBalance";

async function list(params?: {
  userUid?: string,
  year?: string
}) {
  const response = await axios.get<{ data: ServerSchema[] }>(backend.leaveBalance.list, { params });
  return response.data.data.map(v => mapToReact(v));
}

async function updates(args: {
  ids: string[],
  additionalLeave: number,
  annualLeave: number
}) {
  const yearNow = new Date().getFullYear().toString();

  await axios.put(backend.leaveBalance.updates, {
    ids: args.ids,
    balance: {
      [yearNow]: {
        additionalLeave: args.additionalLeave,
        annualLeave: args.annualLeave
      }
    }
  });
}


export default {
  queryKey,
  list,
  updates
}