import React, { useState, useEffect, ChangeEvent } from "react";
import { format } from "date-fns";
import styled from "styled-components/macro";
import { useNavigate, useParams } from "react-router-dom";
import FormBootsrap from 'react-bootstrap/Form';
import { useQuery } from "react-query";

import locale from "locale";
import api from "api";
import type Leave from "types/leave";
import useNavStore from "stores/useNavStore";
import useUserStore from "stores/useUserStore";
import type { Type } from "types/leave";
import useForm from "./useForm";

import { Wrapper as _Form } from "./Form";
import Card, { Wrapper as _Card } from "components/common/Card";
import XWithRing, { Wrapper as _XWithRing } from "components/svg/XWithRing";
import Input from "components/lib/Input";
import Label from "components/lib/Label";
import Button from "components/lib/Button";
import _Button from "components/lib/Button/styled";
import DateRangePicker from "components/common/DateRangePicker";
import TextArea from "components/lib/TextArea";
import Multiselect from "components/common/Multiselect";
import routes from "config/routes";
import { getLeaveDuration } from "utils";
import { DropdownList } from "react-widgets/cjs";
import { deductType } from "../../data";
import useApprovers from "./useApprovers";
import useLeaveTypes from "./useLeaveTypes";
import type { Supervisor } from "types/user";
import { toast } from "react-toastify";
import backend from "config/backend";
// import Card, { Wrapper as _Card } from "components/common/Card";

const Wrapper1 = styled.div`
  --gap: 20rem;

  position: relative;
  max-width: var(--form-max-width);
  padding: var(--container-padding);
  padding-top: 35rem;

  margin: 0 auto;
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  & > ${_XWithRing} {
    position: absolute;
    top: 12rem;
    right: 12rem;
    height: 20rem;
  }

  ${_Button} {
    align-self: end;
  }
`;
const Wrapper = styled.div`
  position: relative;
  padding: var(--container-padding);

  margin: 0 auto;

  display: flex;
  align-items: start;
  justify-content: center;
  gap: 25rem;

  ${_Form} {
    width: 100%;
  }
  ${_Form} {
    max-width: var(--form-max-width);
  }
  ${_Card} {
    max-width: 550rem;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);

  & .row {
    grid-column: 1 / span 2;
  }
`;
export type FormState = {
  type: string;
  deductOn: string;
  start: Date | null;
  startAPM: string;
  end: Date | null;
  endAPM: string;
  leaveReason: string;
  approvers: Supervisor[];
  recipients: Supervisor[];
};

export default () => {
  /**
   * Hooks
   */
  const { id } = useParams<{ id: string }>();

  const user = useUserStore((state) => state.user);
  const navigate = useNavStore((state) => state.navigate);

  const [leave, setLeave] = useState<Leave>();
  const [year, setYear] = useState<Date>(new Date());
  const [proof, setProof] = useState<File | null>(null);

  const yearNow = year.getFullYear().toString();
  const nativeNavigate = useNavigate();
  const { form, dayDiff, setForm } = useForm();
  const { approvers, recipients } = useApprovers();
  const { leaveTypes } = useLeaveTypes();

  const { data: leaveData, error } = useQuery({
    queryKey: [api.leave.queryKey, user!.id],
    queryFn: () => api.leave.list({ userUid: user!.id }),
    enabled: !!navigate && !!user,
  });
  // console.log(form);

  const { data: leaveBalData } = useQuery({
    queryKey: [api.leaveBalance.queryKey, yearNow, user!.id],
    queryFn: () => api.leaveBalance.list({ userUid: user!.id, year: yearNow }),
    enabled: !!user,
  });

  useEffect(() => {
    if (error && navigate) {
      const route = routes.error;
      navigate(route);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (id) {
      api.leave.getById(id).then((data) => {
        setLeave(data);
        const approver = data?.approvers?.map((item: any) => {
          return {
            id: item.uId,
            firstName: item.firstName,
            lastName: item.lastName,
          };
        });
        const recipients = data?.recipients?.map((item: any) => {
          return {
            id: item.uId,
            firstName: item.firstName,
            lastName: item.lastName,
          };
        });
        setForm({
          ...form,
          leaveReason: data.leaveReason,
          deductOn: data.deductOn,
          type: data.type,
          start: data.start,
          startAPM: data.startAPM ? data.startAPM : "AM",
          end: data.end,
          endAPM: data.endAPM ? data.endAPM :"PM",
          approvers: approver,
          recipients,
        });
      });
      // setLeave(leaveData.filter((l) => l.id === id)[0]);
    }
  }, [leaveData, id]);

  /**
   * Not hook
   */
  const handleViewClick = () => {
    if (!navigate) return;

    const route = routes.myLeaveBal;
    navigate(route);
  };

  const balance =
    leaveBalData && leaveBalData.length && leaveBalData[0].balance
      ? leaveBalData[0].balance[yearNow]
      : null;

  const consumed = balance?.consumed ?? 0;
  const additionalLeave = balance?.additionalLeave ?? 0;
  const annualEntitlement = balance?.annualEntitlement ?? 0;

  const total = consumed + additionalLeave + annualEntitlement;

  const handleSubmit = () => {
    if (!user || !navigate) return;

    let list = form.recipients.map((r: any) => r.id);

    const formData = new FormData();
    formData.append("leaveType", form.type ?? "");
    formData.append("deductOn", form.deductOn ?? "annual");
    formData.append("startDate", JSON.stringify(format(form.start ?? new Date(), backend.format.date)));
    formData.append("startAPM", form.startAPM ?? "AM");
    formData.append("endDate", JSON.stringify(format(form.end ?? new Date(), backend.format.date)));
    formData.append("endAPM", form.endAPM ?? "PM");
    formData.append("duration", JSON.stringify(dayDiff));
    formData.append("reason", form.leaveReason);
    formData.append("recipients", JSON.stringify({
      approvers: form.approvers?.map((a) => ({ uId: a.id })) ?? [],
      recipients: form.recipients?.map((r) => ({ uId: r.id })) ?? []
    }));
    if (proof) {
      formData.append("proof", proof);
    }

    api.leave.updateFormData(formData, id).then((data) => {
      const message = locale.en.toastMessages.updateSuccess.replace(
        "{{ 1 }}",
        "Leave request"
      );
      toast.success(message);

      navigate && navigate(routes.myRequest);
    });

    // mutation.mutate({
    //   userId: user.id,
    //   type: form.type ?? "annual",
    //   start: form.start ?? new Date(),
    //   end: form.end ?? new Date(),
    //   duration: dayDiff,
    //   leaveReason: form.leaveReason,
    //   approvers: form.approvers.map((a) => a.id),
    //   recipients: form.recipients.map((r) => r.id),
    // });
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files  = event.target.files;
    if (files && files.length > 0) {
      setProof(files[0]);
    }
  }

  /**
   * Render
   */
  return (
    <Wrapper>
      {leave && (
        <Wrapper1>
          {/* <> */}
          <XWithRing
            color="var(--primary-color)"
            onClick={() => nativeNavigate(-1)}
          />

          <Form>
            <DateRangePicker
              className="row"
              start={{
                value: form.start,
                onChange: (d) => setForm({ start: d }),
              }}
              end={{
                value: form.end,
                onChange: (d) => setForm({ end: d }),
              }}
            />

          <DropdownList
            data={["AM","PM"]}
            value={form.startAPM ?? "AM"}
            onChange={(d) => setForm({ startAPM: d})}
          />
          <DropdownList
            data={["AM","PM"]}
            value={form.endAPM ?? "PM"}
            onChange={(d) => setForm({ endAPM: d})}
          />
            <Label title={locale.en.myRequest.form.duration}>
              <Input
                value={dayDiff !== 0 ? getLeaveDuration(dayDiff) : "-"}
                readOnly
              />
            </Label>

            <Label title={locale.en.myRequest.form.type}>
              <DropdownList
                placeholder={locale.en.myRequest.form.reasonPlaceholder}
                data={leaveTypes}
                dataKey="id"
                textField="name"
                value={form.type ?? null}
                onChange={(d) => setForm({ type: d.id })}
              />
            </Label>

            <Label title={locale.en.myRequest.form.deductType}>
              <DropdownList
                placeholder={locale.en.myRequest.form.deductType}
                data={deductType}
                dataKey="id"
                textField="name"
                value={form.deductOn ?? null}
                onChange={(d) => setForm({ deductOn: d.id })}
              />
            </Label>
            
            <Label title={"Attachment"} className="row">
              <FormBootsrap.Group controlId="formFileMultiple" onChange={handleFileInputChange}>
                <FormBootsrap.Control type="file" multiple />
              </FormBootsrap.Group>
            </Label>

            <Label title={locale.en.myRequest.form.reason} className="row">
              <TextArea
                value={form.leaveReason}
                onChange={(s) => setForm({ leaveReason: s })}
              />
            </Label>

            <Label title={locale.en.myRequest.form.approver}>
              <Multiselect
                dataKey="id"
                textField="firstName"
                data={approvers}
                bgColor="#45BF4322"
                color="#45BF43"
                value={form.approvers}
                onChange={(s) => setForm({ approvers: s })}
              />
            </Label>

            <Label title={locale.en.myRequest.form.recipient}>
              <Multiselect
                dataKey="id"
                textField="firstName"
                data={recipients}
                bgColor="#FF424222"
                color="#FF4242"
                value={form.recipients}
                onChange={(s) => setForm({ recipients: s })}
              />
            </Label>
          </Form>

          <Button.Classic onClick={handleSubmit}>
            {locale.en.common.button.update}
          </Button.Classic>
          {/* </> */}
        </Wrapper1>
      )}
      <Card.Statistic.Leave
        title={locale.en.common.statCard.leaveRecord}
        consumed={consumed}
        remaining={additionalLeave + annualEntitlement}
        leaves={[
          {
            legend: locale.en.myRequest.cardLegend.annLeave,
            color: "#E5429E",
            percentage: balance
              ? Math.floor((annualEntitlement / total) * 100)
              : 0,
          },
          {
            legend: locale.en.myRequest.cardLegend.extraLeave,
            color: "#44C2DE",
            percentage: balance
              ? Math.ceil((additionalLeave / total) * 100)
              : 0,
          },
          {
            legend: locale.en.myRequest.cardLegend.consumed,
            color: "#B9B9B9",
            percentage: balance ? Math.floor((consumed / total) * 100) : 0,
          },
        ]}
        onViewClick={handleViewClick}
        year={year}
        setYear={setYear}
      />
    </Wrapper>
  );
};
