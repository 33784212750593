import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import type { User } from "pages/MyApprovals/types";
import mock from "../mock-data";

import Form from "pages/MyApprovals/Form";
import api from "api";

export default () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<any>();

  const fetchById = async (id: string) => {
    const res = await api.leave.getById(id);
    setData(res);
  };

  useEffect(() => {
    if (id) {
      fetchById(id);
    }
  }, [id]);

  return (
    <Form
      readOnly
      defaults={{
        page: "approved",
        username: data?.userId?.userName,
        type: data?.type,
        typeName: data?.typeName,
        start: data?.start,
        end: data?.end,
        duration: data?.duration.toString(),
        reason: data?.leaveReason,
        deductOn: data?.deductOn,
        comments: data?.approverComment,
        proof: data?.proof
      }}
    />
  );
};
