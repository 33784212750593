export default {
  button: {
    new: "New Request"
  },

  form: {
    type: "Leave type",
    deductType: "Deduct type",
    duration: "Leave duration",
    reason: "Leave reason",
    reasonPlaceholder: "Select a reason",
    comment: "Approver's comments",
    approver: "Approver",
    status: "Status",
    recipient: "Recipient",
  },

  schedules: "Schedules",

  table: {
    type: "Leave Type",
    start: "Start Time",
    end: "End Time",
    duration: "Leave duration",
    supervisor: "Status Supervisor",
    hr: "Status HR/Admin"
  },

  cardLegend: {
    annLeave: "Annual Leave",
    extraLeave: "Additional Leave",
    consumed: "Consumed"
  }
}