import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import departmentReducer from "./features/department/departmentSlice";
import userReducer from "./features/users/userSlice";
import holidayReducer from "./features/holidays/holidaySlice";
import leaveReducer from "./features/leave/leaveSlice";
import approvalReducer from "./features/myApproval/myApprovalSlice";
import requestReducer from "./features/myRequest/myRequestSlice";
import adjustmentHistoryReducer from "./features/adjustmentHistory/adjustmentSlice";


// import {  } from '@reduxjs/toolkit';

const customizedMiddleware = getDefaultMiddleware({
  serializableCheck: false,
});

export const store = configureStore({
  reducer: {
    auth: authReducer,
    department: departmentReducer,
    users: userReducer,
    holiday: holidayReducer,
    leave: leaveReducer,
    approval: approvalReducer,
    request: requestReducer,
    adjustmentHistory: adjustmentHistoryReducer,
  },
  // devTools: false,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
