import React from 'react';
import styled from 'styled-components/macro';

import Check, { Wrapper as _Check } from 'components/svg/Check';

export const Wrapper = styled.div(({ $active, $activeColor, $disabled }: {
  $active: boolean,
  $activeColor: string,
  $disabled: boolean
}) => `
  position: relative;
  aspect-ratio: 1/1;
  height: 13rem;
  width: auto;

  border: 1rem solid #aaa;
  border-radius: 2rem;
  cursor: pointer;

  transition: 0.25s background;

  ${$active ? `
  background: ${$activeColor};
  border-color: ${$activeColor};
  ` : ''}

  ${$disabled ? `
    background: #ddd;
    border-color: #bbb;
    cursor: not-allowed;
  `: ''}
  
  ${_Check} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    opacity: 0;
    transition: 0.25s opacity;

    ${$active ? `
    opacity: 1;
    `: ''}
  }
`);

export default ({
  active = false,
  activeColor = "var(--primary-color)",
  onClick,
  disabled = false
}: {
  active?: boolean,
  activeColor?: string,
  onClick?: () => void,
  disabled?: boolean
}) => {
  return (
    <Wrapper
      onClick={onClick}
      $active={active}
      $activeColor={disabled ? "#777" : activeColor}
      $disabled={disabled}
    >
      <Check color="white" />
    </Wrapper>
  );
}