export default {
  title: {
    1: "Registration",
    2: "Registration",
  },
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  userName: "User Name",
  password: "Password",
  confirmPassword: "Confirm Password",
  organizationName: "Organization Name",
  uen: "UEN",
  dateIncorporation: "Date of Incorporation",
  button: {
    cancel: "Cancel",
    register: "Register",
  },
};
