import React from 'react';
import styled from 'styled-components/macro';

import type { Leave } from './types';

import { Wrapper as _Wrapper } from '../../../../styled';
import CircularProgress, { Wrapper as _CircularProgress } from 'components/lib/CircularProgress';
import Footer from './Footer';
import BodyContent from './BodyContent';

export const Wrapper = styled(_Wrapper)`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

const Body = styled.div`
  display: flex;
`;

const ProgressContainer = styled.div`
  flex-grow: 1;

  margin: 0 20rem;
  display: flex;
  justify-content: end;

  ${_CircularProgress} { height: 120rem }
`;

export default ({ title, consumed, remaining, leaves, onViewClick, year, setYear }: {
  title: string,
  consumed: string | number,
  remaining: string | number,
  leaves: Leave[],
  onViewClick?: () => void,
  year: Date,
  setYear: (d: Date) => void
}) => {
  return (
    <Wrapper>
      <Body>
        <BodyContent
          title={title}
          consumed={consumed.toString()}
          remaining={remaining.toString()}
          year={year}
          setYear={setYear}
        />

        <ProgressContainer>
          <CircularProgress lines={leaves} />
        </ProgressContainer>
      </Body>

      <Footer
        legends={leaves}
        onViewClick={onViewClick}
      />
    </Wrapper>
  );
}
