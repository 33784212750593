import React from "react";
import styled from "styled-components/macro";

import CaretDownSlim, {
  Wrapper as _CaretDownSlim,
} from "components/svg/CaretDownSlim";
import { Link, useNavigate } from "react-router-dom";
import useAuthStore from "stores/useAuthStore";
import useUserStore from "stores/useUserStore";
import { useDispatch } from "react-redux";
import { logoutAction } from "redux/features/auth/authSlice";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;

  ${_CaretDownSlim} {
    width: 12rem;
  }
`;

const Image = styled.img`
  aspect-ratio: 1/1;
  height: 45rem;
  width: auto;

  border-radius: 50%;
`;

const Container = styled.div`
  .name {
    font-weight: 600;
  }
  .position {
    font-size: 10rem;
  }
`;

export default ({
  imgSrc,
  name,
  position,
  setHasLogin,
}: {
  imgSrc: string;
  name: string;
  position: string;
  setHasLogin: (b: boolean) => void;
}) => {
  const uId = useAuthStore((state) => state.uId);
  const role = useUserStore((state) => state.role);
  const setUser = useUserStore((state) => state.setUser);
  const dispatch = useDispatch<any>();

  const setAuth = useAuthStore((state) => state.setAuth);
  const nativeNavigate = useNavigate();

  const logout = () => {
    //Clear local storage
    setAuth({
      tokenUser: "",
      orgId: "",
      uId: "",
      role: "",
    });

    setUser(undefined);

    window.localStorage.clear();
    dispatch(logoutAction());

    setHasLogin(false);
    nativeNavigate("/");
  };

  return (
    <Wrapper>
      <Image
        src={
          imgSrc
            ? imgSrc
            : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
        }
      />
        <div className="dropdown">
          <div className="dropbtn">
            <Container>
              <p className="name">{name}</p>
              <p className="position">{position}</p>
            </Container>
            <CaretDownSlim />
          </div>
          <div className="dropdown-content">
            <Link to={`/users/edit/${uId}`}>My Profile</Link>
            <Link to={"/"} onClick={logout}>
              <span>Logout</span>
              {/* Logout */}
            </Link>
          </div>
        </div>
    </Wrapper>
  );
};
