import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import useUserStore from "stores/useUserStore";

import CardContainer from "./components/CardContainer";
import MovementChart from "./components/MovementChart";
import Calendar from "./components/Calendar";
import { fetchLeaves } from "redux/features/leave/leaveSlice";
import { useDispatch } from "react-redux";
import useAuthStore from "stores/useAuthStore";

const Wrapper = styled.div`
  --gap: 25rem;
`;

const ChartContainer = styled.div`
  margin-top: var(--gap);

  display: flex;
  gap: var(--gap);
`;

export default () => {
  const userRole = useUserStore((state) => state.role);
  const uId = useAuthStore.getState().uId;
  const dispatch = useDispatch<any>();
  const [state, setState] = useState();

  useEffect(() => {
    dispatch(fetchLeaves({ setState, role: { userRole, uId } }));
  }, [userRole]);

  return (
    <Wrapper>
      {userRole === "admin" ? <CardContainer.Admin /> : <CardContainer.User />}

      <ChartContainer>
        <MovementChart />
        <Calendar />
      </ChartContainer>
    </Wrapper>
  );
};
