import axios from './axios';
import backend from 'config/backend';
import type Department from 'types/department';

/**
 * Mapping
 */
type Status = "active" | "inActive";

type ServerSchema = {
  uId: string,
  organizationId: string,
  name: string,
  description: string,
  status: Status,
  _id: string,
  createdAt: string,
  updatedAt: string
}

function mapToServer(department: Department): Partial<ServerSchema> {
  return {
    uId: department.id,
    name: department.name,
    status: department.isActive ? "active" : "inActive",
    description: department.description
  }
}

function mapToReact(response: ServerSchema): Department {
  return {
    id: response.uId,
    name: response.name,
    description: response.description,
    isActive: response.status === "active"
  }
}

/**
 * API Implementation
 */
//For react-query
const queryKey = "department";

async function list(status?: Status) {
  const response = await axios.get<{ data: ServerSchema[] }>(backend.department.list, {
    params: { status }
  });

  const data: Department[] = [];

  response.data.data.forEach((item) => {
    data.push(mapToReact(item));
  });

  return data;
}

//getById
async function getById(uId: string) {
  const url = backend.department.getById.replace("{{ 1 }}", uId);

  const response = await axios.get<{ data: ServerSchema }>(url);
  return mapToReact(response.data.data);
}

//create
async function create(department: Department) {
  const request = mapToServer(department);
  delete request.uId;

  const response = await axios.post<{ data: ServerSchema }>(backend.department.create, request);
  return mapToReact(response.data.data);
}

//update
async function update(department: Required<Department>) {
  const url = backend.department.update.replace("{{ 1 }}", department.id);
  const request = mapToServer(department);

  const response = await axios.put<{ data: ServerSchema }>(url, request);
  return mapToReact(response.data.data);
}

//delete is a reserved keyword for JS
async function destroy(uId: string) {
  const url = backend.department.delete.replace("{{ 1 }}", uId);

  await axios.delete<{ data: "success" }>(url);
}

export default {
  queryKey,
  list,
  getById,
  create,
  update,
  delete: destroy
}