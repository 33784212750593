import React from "react";
import styled from "styled-components/macro";
import { Calendar as WidgetCalendar } from "react-widgets/cjs";
import Legends, { Wrapper as _Legends } from "components/common/LegendsHoliday";
import { useSelector, useDispatch } from "react-redux";
import { format, getMonth, getYear, getDay, getDate } from "date-fns";
import { compareAsc } from "date-fns";
import { fetchHolidays } from "redux/features/holidays/holidaySlice";

const Wrapper = styled.div`
  width: 300rem;
  padding-bottom: 15rem;
  height: 100%;

  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);
  overflow: hidden;

  ${_Legends} {
    padding: 0 15rem;
  }
`;

const Calendar = styled(WidgetCalendar)`
  flex-grow: 1;
  margin-bottom: 10rem;

  display: flex;
  flex-direction: column;
  border-radius: 0;
  border: none;

  .rw-calendar-transition-group {
    flex-grow: 1;
  }

  .rw-calendar-transition-group > div {
    height: 100%;
  }

  .rw-cell {
    padding: 0;
    border: none;
  }
`;

const CalendarCell = styled.div(
  ({ $bg = "transparent" }: { $bg?: string }) => `
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${$bg};

  border-radius: 4px;
`
);
export default () => {
  const { holidays } = useSelector((state: any) => state?.holiday);
  const dispatch = useDispatch<any>();
  let days: any = [],
    months: any = [],
    years: any = [];
  let legend: any = [];
  let colors: any = ["yellow", "purple", "pink", "green", "golden"];
  holidays?.map((item: any, index: any) => {
    // if (!legend?.includes(item.name)) {
    const startDate = getDate(item.start);
    const endDate = getDate(item.end);
    const dateHoliday = startDate === endDate ? `${startDate}` : `${startDate} - ${endDate}`;

    legend.push({
      id: item?.id,
      label: item?.name,
      color: colors[index],
      day: dateHoliday,
      month: format(item.start, 'LLLL'),
    });
    // }
    days.push(getDate(item.start));
    months.push(getMonth(item.start));
    years.push(getYear(item));
  });
  console.log("legend", holidays);

  const handleCalender = (value: any) => {};
  const handleChange = (value: any) => {
    console.log(getYear(value));
    dispatch(fetchHolidays(getYear(value)));
  };

  const checkCompareDate = (date:any, start:any, end:any) => {
    let checkValue = false;
    for (let loopDate = new Date(start); loopDate <= new Date(end); loopDate.setDate(loopDate.getDate() + 1)) {
      if (date.toDateString() == loopDate.toDateString()) {
        checkValue = true;
        return checkValue;
      }
      // if (!compareAsc(date, loopDate)) {
      //   checkValue = true;
      //   return checkValue;
      // }
    }
    return checkValue;
  }

  return (
    <Wrapper>
      <Calendar
        onChange={handleCalender}
        onCurrentDateChange={handleChange}
        value={null}
        renderDay={({ date, label }) => {
          for (let i = 0; i < days.length; i++) {
            if (checkCompareDate(date, holidays[i].start, holidays[i].end)) {
              return <CalendarCell $bg={legend[i].color}>{label}</CalendarCell>;
            }
          }
          return <CalendarCell>{label}</CalendarCell>;
        }}
      />

      <Legends data={legend} />
    </Wrapper>
  );
};
