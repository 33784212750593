import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

import locale from "locale";
import api from "api";
import type { Checks } from "types/leaveBalance";
import { generateRegex } from "components/common/SearchInput/utils";
import useColumns from "./useColumns";

import Table from "components/lib/Table";
import Modal from "components/lib/Modal";
import UpdateLeave from "./components/UpdateLeave";
import Header from "./components/Header";

const Wrapper = styled.div`
  padding: var(--container-padding);
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);
`;

export default () => {
  /**
   * Hooks
   */
  const yearNow = useMemo(() => new Date().getFullYear().toString(), []);

  const { data } = useQuery({
    queryKey: [api.leaveBalance.queryKey, yearNow],
    queryFn: () => api.leaveBalance.list({ year: yearNow }),
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [checkboxes, setCheckboxes] = useState<Checks>({});

  const columns = useColumns(checkboxes, setCheckboxes, yearNow);

  const [search, setSearch] = useState("");

  /**
   * Not hook
   */
  const handlePenClick = () => {
    if (Object.keys(checkboxes).length) setModalVisible(true);
    else toast.error(locale.en.adjustLeave.error.emptyCheckbox);
  };

  const searchRegex = generateRegex(search);

  const filteredData = data?.filter((d) => d.username.match(searchRegex));

  /**
   * Render
   */
  return (
    <>
      <Wrapper>
        <Header
          onPenClick={handlePenClick}
          search={search}
          setSearch={setSearch}
        />

        <Table data={filteredData ?? []} columns={columns} pageSize={10} />
      </Wrapper>

      <Modal visible={modalVisible} setVisible={setModalVisible}>
        <UpdateLeave
          yearNow={yearNow}
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
          setVisible={setModalVisible}
        />
      </Modal>
    </>
  );
};
