import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useQuery } from 'react-query';

import locale from 'locale';
import api from 'api';
import routes from 'config/routes';
import type User from 'types/user';
import type { Column } from 'components/lib/Table/types';
import data from 'pages/Users/data';
import { fillRouteParam } from 'utils';

import Username from 'components/lib/Table/plugins/Username';
import EditPen, { Wrapper as _EditPen } from 'components/svg/EditPen';
import UserRemove, { Wrapper as _UserRemove } from 'components/svg/UserRemove';
import useNavStore from 'stores/useNavStore';

const ActionCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 13rem;
    
  ${_EditPen}, ${_UserRemove} {
    height: 22rem;
    cursor: pointer;
  }
`;

export default (openRevokeModal: (r: User) => void) => {
  /**
   * Hooks
   */
  const [columns, setColumns] = useState<Column<User>[]>([]);
  const navigate = useNavStore(state => state.navigate);

  const { data: deptData } = useQuery({
    queryKey: [api.department.queryKey, "active"],
    queryFn: () => api.department.list("active")
  })

  useEffect(() => {
    const columns: Column<User>[] = [
      {
        title: locale.en.users.table.name,
        render: r => (
          <Username
            imgUrl={r.thumbnail ?? ""}
            name={`${r.firstName} ${r.lastName}`}
          />
        )
      },
      {
        title: locale.en.users.table.department,
        render: r => {
          if (!deptData) return "";

          const dept = deptData.find(d => d.id === r.departmentId);
          return dept?.name || "";
        }
      },
      {
        title: locale.en.users.table.supervisor,
        render: r => {

          const maxLength = 2;
          const delimiter = "...";

          return (
            <div style={{ whiteSpace: "pre" }}>
              {r.supervisors
                .filter((v, i) => i <= maxLength - 1)
                .map((item) => `${item.firstName} ${item.lastName}\n`)
              }

              {r.supervisors.length > maxLength && delimiter}
            </div>
          )
        }
      },
      {
        title: locale.en.users.table.email,
        render: r => r.email
      },
      {
        title: locale.en.users.table.phoneNo,
        render: r => r.contactNo
      },
      {
        title: locale.en.users.table.status,
        render: r => data.status.find(d => d.value === r.status)?.display || ""
      },
      {
        title: "",
        width: "100rem",
        render: r => (
          <ActionCell>
            <EditPen onClick={() => handleEditClick(r)} />
            <UserRemove onClick={() => openRevokeModal(r)} />
          </ActionCell>
        )
      }
    ];

    setColumns(columns);
  }, [deptData]);

  /**
   * Not hook
   */
  const handleEditClick = (r: User) => {
    const route = routes.users.routes!.edit;

    if (navigate && route.render) {
      const url = fillRouteParam(
        route.render!.url,
        [{ param: 'id', value: r.id }]
      );

      navigate(route, url);
    }
  }

  return columns;
}