import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";

export const initialState = {
  loading: false,
  hasErrors: false,
  departments: [],
};

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {
    // getRepos: (state) => {
    //   state.loading = true;
    // },
    getdeptSucess: (state, action) => {
      state.departments = action.payload;
      //   state.loading = false;
      //   state.hasErrors = false;
    },
    create: (state, action) => {},
    // getReposFailure: (state) => {
    //   state.loading = false;
    //   state.hasErrors = true;
    // },
  },
});

export const {
  // getRepos,
  getdeptSucess,
  // getReposFailure
} = departmentSlice.actions;

// The reducer
export default departmentSlice.reducer;

export const fetchDepartments = createAsyncThunk(
  "department/fetchDepartments",
  async (check, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());
    console.log({ thunkAPI });

    try {
      //   console.log("dispatching now");

      const response = await api.department.list();
      //   console.log(response);

      // Set the data
      //   thunkAPI.dispatch(getdeptSucess(response));
      thunkAPI.dispatch(getdeptSucess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);

export const createDepartments = createAsyncThunk(
  "department/create",
  async (data: any, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());

    try {
      //   console.log("dispatching now");

      const response = await api.department.create(data);
      //   console.log(response);

      // Set the data
      //   thunkAPI.dispatch(getdeptSucess(response));
      thunkAPI.dispatch(getdeptSucess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);
