export default {
  company: {
    name: "Corporate Name",
    alias: "Last Alias",
    incorporationDate: "Date of Incorporation",
    contactNo: "Contact No",
    nationalId: "National ID",
    stateRegion: "State/Region",
    city: "City",
    postalCode: "Postal Code"
  },

  holiday: {
    deleteDesc: "Are you sure to delete this holiday?",
    form: {
      name: "Holiday Name",
      duration: "Total Duration",
      description: "Description"
    },
    table: {
      event: "Event",
      start: "Start Time",
      end: "End Time",
      duration: "Duration",
      description: "Description"
    }
  },

  department: {
    deleteDesc: "Are you sure to delete '{{ 1 }}' department?",
    form: {
      name: "Name",
      status: "Status",
      comments: "Comments"
    },
    table: {
      name: "Name",
      status: "Status",
      description: "Description"
    }
  },

  leaveType: {
    deleteDesc: "Are you sure to delete '{{ 1 }}' leave type?",
    form: {
      name: "Name",
      status: "Status",
      comments: "Comments"
    },
    table: {
      name: "Name",
      status: "Status",
    }
  },

  leaveSetting: {
    annualLeaveDefault: "Default Annual Leave",
    additionalLeaveDefault: "Default Additional Leave",
    perAnnualMonthGain: "Per Annual Month Gain",
    annualLeaveMaxCarryOver: "Annual Leave Max Carry Over",
    additionalMaxCarryOver: "Additional Leave Max Carry Over"
  },

  workingDay: {
    createSchedule: "Create Schedule",
    tableColumn: {
      day: "Day",
      startTime: "Start Time",
      endTime: "End Time",
      totalHour: "Total Hours"
    }
  }
}