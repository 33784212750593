import React, { useEffect, useState } from "react";

import locale from "locale";

import { Wrapper } from "../styled";
import Card from "components/common/Card";
import { useQuery } from "react-query";
import api from "api";
import useNavStore from "stores/useNavStore";
import Leave from "types/leave";
import routes from "config/routes";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchLeaves } from "redux/features/leave/leaveSlice";

export default () => {
  const navigate = useNavStore((state) => state.navigate);
  const dispatch = useDispatch<any>();
  const { leavePending } = useSelector((state: any) => state.leave);
  const [state, setState] = useState<Leave[]>([]);
  // let pending = state.filter((leave) => leave.hr.status == "pending");

  const { data: userData } = useQuery({
    queryKey: api.user.queryKey,
    queryFn: api.user.list,
  });

  const handleDetails = () => {
    navigate!(routes.users);
  };
  const handlePendingDetails = () => {
    navigate!(routes.pendingMod);
  };

  // useEffect(() => {
  //   dispatch(fetchLeaves(setState));
  // }, [dispatch]);
  return (
    <Wrapper>
      <Card.Numeric
        title={locale.en.dashboard.admin.totalUsers}
        number={userData?.length || 0}
        onClick={handleDetails}
      />

      <Card.Numeric
        title={locale.en.dashboard.admin.pendingMod}
        number={leavePending.length || 0}
        onClick={handlePendingDetails}
      />
    </Wrapper>
  );
};
