import Classic from "./variants/Classic";
import Ghost from "./variants/Ghost";
import Outline from "./variants/Outline";

import _Wrapper from "./styled";

export const Wrapper = _Wrapper;

export default {
  Classic,
  Ghost,
  Outline,
};
