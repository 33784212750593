export default {
  title: "List Users",

  table: {
    name: "Name",
    department: "Department",
    supervisor: "Supervisor",
    email: "Email",
    phoneNo: "Phone No.",
    status: "Status",
  },

  form: {
    general: {
      title: "General Information",
      firstName: "First name",
      lastName: "Last name",
      userName: "username",
      email: "Email",
      contactNo: "Contact no",
      nationalId: "National id",
      dob: "Date of birth",
      department: "Department",
      designation: "Designation",
      reportTo: "Report to",
      status: "Status",
    },
    contact: {
      title: "Contact Information",
      postalCode: "Postal Code",
    },
    others: {
      roles: {
        title: "Roles",
        field: "Roles",
      },
      permission: {
        title: "Additional Permissions",
        field: "Permissions",
      },
      workDay: {
        title: "Work Days",
        field: "Work Days",
      },
    },
  },

  revoke: {
    description: "Are you sure to revoke this user access?",
    button: "Revoke",
  },
};
