import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 1/1;
  height: auto;
  width: auto;
  display: block;

  & path {
    transition: 0.25s fill;
  }
`;

const EditPen = ({ color = "black", onClick }: {
  color?: string,
  onClick?: () => void
}) => (
  <Wrapper
    width={25} height={25}
    viewBox="0 0 25 25"
    fill="none"
    onClick={onClick}
  >
    <path fill={color} d="M21.3711 22.3477H3.37109C2.96109 22.3477 2.62109 22.0077 2.62109 21.5977C2.62109 21.1877 2.96109 20.8477 3.37109 20.8477H21.3711C21.7811 20.8477 22.1211 21.1877 22.1211 21.5977C22.1211 22.0077 21.7811 22.3477 21.3711 22.3477Z" />
    <path fill={color} d="M19.3917 3.82928C17.4517 1.88928 15.5517 1.83928 13.5617 3.82928L12.3517 5.03928C12.2517 5.13928 12.2117 5.29928 12.2517 5.43928C13.0117 8.08928 15.1317 10.2093 17.7817 10.9693C17.8217 10.9793 17.8617 10.9893 17.9017 10.9893C18.0117 10.9893 18.1117 10.9493 18.1917 10.8693L19.3917 9.65928C20.3817 8.67928 20.8617 7.72928 20.8617 6.76928C20.8717 5.77928 20.3917 4.81928 19.3917 3.82928Z" />
    <path fill={color} d="M15.9814 11.8784C15.6914 11.7384 15.4114 11.5984 15.1414 11.4384C14.9214 11.3084 14.7114 11.1684 14.5014 11.0184C14.3314 10.9084 14.1314 10.7484 13.9414 10.5884C13.9214 10.5784 13.8514 10.5184 13.7714 10.4384C13.4414 10.1584 13.0714 9.79844 12.7414 9.39844C12.7114 9.37844 12.6614 9.30844 12.5914 9.21844C12.4914 9.09844 12.3214 8.89844 12.1714 8.66844C12.0514 8.51844 11.9114 8.29844 11.7814 8.07844C11.6214 7.80844 11.4814 7.53844 11.3414 7.25844C11.3202 7.21304 11.2997 7.16788 11.2799 7.12297C11.1323 6.78966 10.6976 6.69221 10.4399 6.94997L4.71141 12.6784C4.58141 12.8084 4.46141 13.0584 4.43141 13.2284L3.89141 17.0584C3.79141 17.7384 3.98141 18.3784 4.40141 18.8084C4.76141 19.1584 5.26141 19.3484 5.80141 19.3484C5.92141 19.3484 6.04141 19.3384 6.16141 19.3184L10.0014 18.7784C10.1814 18.7484 10.4314 18.6284 10.5514 18.4984L16.2727 12.7771C16.5323 12.5176 16.4344 12.0721 16.0968 11.928C16.0588 11.9118 16.0203 11.8953 15.9814 11.8784Z" />
  </Wrapper>
);

export default EditPen;