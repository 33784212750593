import React, { useState } from "react";
import styled from "styled-components/macro";

import locale from "locale";
import Label from "components/lib/Label";
import Input from "components/lib/Input";
import Checkbox from "components/lib/Checkbox";

const Fields = styled.div`
  display: flex;
  flex-direction: column;

  gap: 15rem;
`;

const Extra = styled.div`
  margin-top: 7rem;
  display: flex;
  justify-content: space-between;

  font-size: 11rem;
  color: #777;
`;

const RememberMe = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  cursor: pointer;
`;

export default ({
  email,
  password,
  remember,
}: {
  email: [string, (s: string) => void];
  password: [string, (s: string) => void];
  remember: [boolean, (b: boolean) => void];
}) => {
  /**
   * Hooks
   */
  const [errorEmail, setErrorEmail] = useState<string>();
  const [errorPw, setErrorPw] = useState<string>();

  /**
   * Not hook
   */
  const handleEmailChange = (s: string) => {
    if (!s) setErrorEmail(locale.en.common.formValidation.required);
    else setErrorEmail(undefined);

    email[1](s);
  };

  const handlePasswordChange = (s: string) => {
    if (!s) setErrorPw(locale.en.common.formValidation.required);
    else setErrorPw(undefined);

    password[1](s);
  };

  /**
   * Render
   */
  return (
    <>
      <Fields>
        <Label title={locale.en.login.email} error={errorEmail}>
          <Input value={email[0]} onChange={handleEmailChange} />
        </Label>

        <Label title={locale.en.login.password} error={errorPw}>
          <Input
            value={password[0]}
            onChange={handlePasswordChange}
            type={"password"}
          />
        </Label>
      </Fields>

      <Extra>
        <RememberMe onClick={() => remember[1](!remember[0])}>
          <Checkbox active={remember[0]} />

          {locale.en.login.rememberMe}
        </RememberMe>

        <p style={{cursor: "pointer"}} onClick={() => window.open(`${process.env.REACT_APP_URL}/forgetpassword`)}>{locale.en.login.forgot}</p>
      </Extra>
    </>
  );
};
