import React from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";

import locale from "locale";
import api from "api";
import data from "../../data";
import type { Role } from "types/user";
import type { Setter } from "../useForm";
import { Title, Fields as _Fields } from "../styled";

import Label from "components/lib/Label";
import CheckboxInputs from "components/common/CheckboxInputs";

const Fields = styled(_Fields)`
  gap: var(--gap-gutter);
`;

export default ({
  workDayIds,
  roles,
  setForm,
  readonly,
}: {
  workDayIds: string[];
  roles: Role[];
  setForm: Setter;
  readonly?: Boolean;
}) => {
  const { data: workdayData } = useQuery({
    queryKey: api.workingDay.queryKey,
    queryFn: api.workingDay.get,
  });

  return (
    <Fields>
      <div
        style={{
          pointerEvents: readonly ? "none" : "auto",
          opacity: readonly ? 0.7 : 1,
        }}
      >
        <Title>{locale.en.users.form.others.roles.title}</Title>

        <Label title={locale.en.users.form.others.roles.field}>
          <CheckboxInputs
            data={data.roles}
            indexFor={{ display: "display", value: "id" }}
            value={roles}
            disabled={(d) => d.disabled}
            onChange={(v) => setForm({ roles: v as Role[] })}
          />
        </Label>
      </div>

      <div
        style={{
          pointerEvents: readonly ? "none" : "auto",
          opacity: readonly ? 0.7 : 1,
        }}
      >
        <Title>{locale.en.users.form.others.workDay.title}</Title>

        <Label title={locale.en.users.form.others.workDay.field}>
          <CheckboxInputs
            data={workdayData ?? []}
            value={workDayIds}
            indexFor={{ display: "type", value: "id" }}
            onChange={(v) => setForm({ workDayIds: v })}
          />
        </Label>
      </div>
    </Fields>
  );
};
