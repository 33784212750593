import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import api from 'api';
import type Audit from 'types/audit';
import type { Column } from 'components/lib/Table/types';

export default () => {
  const [columns, setColumns] = useState<Column<Audit>[]>([]);

  const { data: deptData } = useQuery({
    queryKey: [api.department.queryKey, "active"],
    queryFn: () => api.department.list("active")
  })

  useEffect(() => {
    const columns: Column<Audit>[] = [
      {
        title: "Date",
        render: r => r.date
      },
      {
        title: "Status",
        render: r => r.status
      },
      {
        title: "Type",
        render: r => r.type
      },
      {
        title: "Operator",
        render: r => r.userName,
      },
      {
        title: "Subject",
        render: r => r.subject
      },
      {
        title: "Description",
        width: "600px",
        render: r => (
          <div style={{whiteSpace: "break-spaces"}}>
            {r.description}
          </div>
        )
      },
    ];

    setColumns(columns);
  }, [deptData]);

  return columns;
}