import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { parse } from 'date-fns';
import { toast } from 'react-toastify';

import locale from 'locale';
import datetime from 'config/datetime';
import api from 'api';
import days from './data';
import type { Day } from 'types/workday';
import type WorkDay from 'types/workday';
import useColumns from './useColumns';

import PrunableCheckbox from 'components/common/PrunableCheckbox';
import Table from 'components/lib/Table';
import Button, { Wrapper as _Button } from 'components/lib/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30rem;

  padding: var(--container-padding);
  border-radius: var(--container-border-radius);
  background: var(--container-bg-color);

  ${_Button} {
    align-self: end;
  }
`;

export default () => {
  /**
   * Hooks
   */
  const queryClient = useQueryClient();

  const { data } = useQuery(api.workingDay.queryKey, api.workingDay.get);

  const mutation = useMutation(api.workingDay.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.workingDay.queryKey);
      toast.success(locale.en.toastMessages.updateScheuleSuccess);
    }
  });

  const [workDays, setWorkDays] = useState<WorkDay[]>([]);

  const columns = useColumns([workDays, setWorkDays]);

  useEffect(() => {
    data && setWorkDays(data);
  }, [data ? data.length : 0]);

  /**
   * Not hook
   */
  const modifyWorkDay = (days: string[]) => {
    const aggregated: WorkDay[] = [];

    days.forEach(d => {
      const day = workDays.find(w => w.type === d);
      const now = new Date();

      const startDate = parse(
        datetime.default.settings.workingDay.startTime,
        datetime.format.timePicker,
        now
      );

      const endDate = parse(
        datetime.default.settings.workingDay.endTime,
        datetime.format.timePicker,
        now
      );

      if (day) aggregated.push(day);
      else {
        aggregated.push({
          id: "",
          type: d as Day,
          start: startDate,
          end: endDate
        });
      }
    });

    setWorkDays(aggregated);
  }

  const createSchedule = () => {
    mutation.mutate(workDays);
  }

  /**
   * Render
   */
  return (
    <Wrapper>
      <PrunableCheckbox
        options={days}
        max={7}
        actives={workDays.map(i => i.type)}
        onChange={modifyWorkDay}
      />

      <Table
        data={workDays}
        columns={columns}
        pageSize={10}
      />

      <Button.Classic onClick={createSchedule}>
        {locale.en.settings.workingDay.createSchedule}
      </Button.Classic>
    </Wrapper>
  );
}