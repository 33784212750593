export type Key = "tokenUser" | "orgId" | "uId" | "path" | "role";

//Sets the name/key to store in the local storage
const localStorage: { [k in Key]: string } = {
  tokenUser: "tokenUser",
  orgId: "orgId",
  uId: "uId",
  path: "path",
  role: "role"
}

export default localStorage;