import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";

export const initialState = {
  loading: false,
  hasErrors: false,
  holidays: [],
};

const holidaySlice = createSlice({
  name: "holiday",
  initialState,
  reducers: {
    // getRepos: (state) => {
    //   state.loading = true;
    // },
    getholiSucess: (state, action) => {
      state.holidays = action.payload;
      //   state.loading = false;
      //   state.hasErrors = false;
    },
    create: (state, action) => {},
    // getReposFailure: (state) => {
    //   state.loading = false;
    //   state.hasErrors = true;
    // },
  },
});

export const {
  // getRepos,
  getholiSucess,
  // getReposFailure
} = holidaySlice.actions;

// The reducer
export default holidaySlice.reducer;

export const fetchHolidays = createAsyncThunk(
  "holiday/fetchHolidays",
  async (year: any, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());
    // console.log({ thunkAPI });

    try {
      //   console.log("dispatching now");
      const response = await api.holiday.list(year);
      //   console.log(response);
      // Set the data
      thunkAPI.dispatch(getholiSucess(response));
      //   thunkAPI.dispatch(getdeptSucess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);

export const createHoliday = createAsyncThunk(
  "holiday/create",
  async (data: any, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());

    try {
      //   console.log("dispatching now");

      const response = await api.holiday.create(data);
      //   console.log(response);

      // Set the data
      //   thunkAPI.dispatch(getdeptSucess(response));
      thunkAPI.dispatch(getholiSucess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);
