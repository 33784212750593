import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 1/1;
  height: auto;
  width: auto;
`;

export default ({ color = "black" }: {
  color?: string
}) => (
  <Wrapper
    width={24} height={24}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path fill={color} d="M6.32162 2.9987C6.32162 3.77225 6.01432 4.51411 5.46734 5.06109C4.92036 5.60807 4.1785 5.91537 3.40495 5.91537C2.6314 5.91537 1.88953 5.60807 1.34255 5.06109C0.795572 4.51411 0.488281 3.77225 0.488281 2.9987C0.488281 2.22515 0.795572 1.48328 1.34255 0.936303C1.88953 0.389322 2.6314 0.0820313 3.40495 0.0820312C4.1785 0.0820313 4.92036 0.389322 5.46734 0.936303C6.01432 1.48328 6.32162 2.22515 6.32162 2.9987Z" />
    <path fill={color} d="M6.32162 11.7487C6.32162 12.5222 6.01432 13.2641 5.46734 13.8111C4.92036 14.3581 4.1785 14.6654 3.40495 14.6654C2.6314 14.6654 1.88953 14.3581 1.34255 13.8111C0.795572 13.2641 0.488281 12.5222 0.488281 11.7487C0.488281 10.9751 0.795572 10.2333 1.34255 9.6863C1.88953 9.13932 2.6314 8.83203 3.40495 8.83203C4.1785 8.83203 4.92036 9.13932 5.46734 9.6863C6.01432 10.2333 6.32162 10.9751 6.32162 11.7487Z" />
    <path fill={color} d="M3.40495 23.4154C4.1785 23.4154 4.92036 23.1081 5.46734 22.5611C6.01432 22.0141 6.32162 21.2722 6.32162 20.4987C6.32162 19.7251 6.01432 18.9833 5.46734 18.4363C4.92036 17.8893 4.1785 17.582 3.40495 17.582C2.6314 17.582 1.88953 17.8893 1.34255 18.4363C0.795572 18.9833 0.488281 19.7251 0.488281 20.4987C0.488281 21.2722 0.795572 22.0141 1.34255 22.5611C1.88953 23.1081 2.6314 23.4154 3.40495 23.4154Z" />
    <path fill={color} d="M15.0716 2.9987C15.0716 3.77225 14.7643 4.51411 14.2173 5.06109C13.6704 5.60807 12.9285 5.91537 12.1549 5.91537C11.3814 5.91537 10.6395 5.60807 10.0926 5.06109C9.54557 4.51411 9.23828 3.77225 9.23828 2.9987C9.23828 2.22515 9.54557 1.48328 10.0926 0.936303C10.6395 0.389322 11.3814 0.0820313 12.1549 0.0820312C12.9285 0.0820313 13.6704 0.389322 14.2173 0.936303C14.7643 1.48328 15.0716 2.22515 15.0716 2.9987Z" />
    <path fill={color} d="M12.1549 14.6654C12.9285 14.6654 13.6704 14.3581 14.2173 13.8111C14.7643 13.2641 15.0716 12.5222 15.0716 11.7487C15.0716 10.9751 14.7643 10.2333 14.2173 9.6863C13.6704 9.13932 12.9285 8.83203 12.1549 8.83203C11.3814 8.83203 10.6395 9.13932 10.0926 9.6863C9.54557 10.2333 9.23828 10.9751 9.23828 11.7487C9.23828 12.5222 9.54557 13.2641 10.0926 13.8111C10.6395 14.3581 11.3814 14.6654 12.1549 14.6654Z" />
    <path fill={color} d="M15.0716 20.4987C15.0716 21.2722 14.7643 22.0141 14.2173 22.5611C13.6704 23.1081 12.9285 23.4154 12.1549 23.4154C11.3814 23.4154 10.6395 23.1081 10.0926 22.5611C9.54557 22.0141 9.23828 21.2722 9.23828 20.4987C9.23828 19.7251 9.54557 18.9833 10.0926 18.4363C10.6395 17.8893 11.3814 17.582 12.1549 17.582C12.9285 17.582 13.6704 17.8893 14.2173 18.4363C14.7643 18.9833 15.0716 19.7251 15.0716 20.4987Z" />
    <path fill={color} d="M20.9049 5.91537C21.6785 5.91537 22.4204 5.60807 22.9673 5.06109C23.5143 4.51411 23.8216 3.77225 23.8216 2.9987C23.8216 2.22515 23.5143 1.48328 22.9673 0.936303C22.4204 0.389322 21.6785 0.0820313 20.9049 0.0820312C20.1314 0.0820313 19.3895 0.389322 18.8426 0.936303C18.2956 1.48328 17.9883 2.22515 17.9883 2.9987C17.9883 3.77225 18.2956 4.51411 18.8426 5.06109C19.3895 5.60807 20.1314 5.91537 20.9049 5.91537Z" />
    <path fill={color} d="M23.8216 11.7487C23.8216 12.5222 23.5143 13.2641 22.9673 13.8111C22.4204 14.3581 21.6785 14.6654 20.9049 14.6654C20.1314 14.6654 19.3895 14.3581 18.8426 13.8111C18.2956 13.2641 17.9883 12.5222 17.9883 11.7487C17.9883 10.9751 18.2956 10.2333 18.8426 9.6863C19.3895 9.13932 20.1314 8.83203 20.9049 8.83203C21.6785 8.83203 22.4204 9.13932 22.9673 9.6863C23.5143 10.2333 23.8216 10.9751 23.8216 11.7487Z" />
    <path fill={color} d="M20.9049 23.4154C21.6785 23.4154 22.4204 23.1081 22.9673 22.5611C23.5143 22.0141 23.8216 21.2722 23.8216 20.4987C23.8216 19.7251 23.5143 18.9833 22.9673 18.4363C22.4204 17.8893 21.6785 17.582 20.9049 17.582C20.1314 17.582 19.3895 17.8893 18.8426 18.4363C18.2956 18.9833 17.9883 19.7251 17.9883 20.4987C17.9883 21.2722 18.2956 22.0141 18.8426 22.5611C19.3895 23.1081 20.1314 23.4154 20.9049 23.4154Z" />
  </Wrapper>
)