import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";

import api from "api";
import { generateRegex } from "components/common/SearchInput/utils";
import useUserStore from "stores/useUserStore";

import Table from "components/lib/Table/customTable";
import SearchInput from "components/common/SearchInput";
import { useDispatch } from "react-redux";
import { listUsersAsync } from "redux/features/users/userSlice";

import useColumns from "./useColumns";

const Wrapper = styled.div`
  background: var(--container-bg-color);
  padding: var(--container-padding);
  border-radius: var(--container-border-radius);
`;

const Header = styled.header`
  margin-bottom: 20rem;

  display: flex;
  align-items: start;
  gap: 10rem;
`;

const Title = styled.h2`
  flex-grow: 1;
`;

export default () => {
  //Filter
  const userRole = useUserStore((state) => state.role);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const dataLimit = 10;

  const { data: auditData } = useQuery({
    queryKey: `audits${currentPage}`,
    queryFn: () => api.audit.list(userRole, (currentPage - 1) * dataLimit, dataLimit)
  });

  const totalData = auditData?.totalData ?? 0;
  const columns = useColumns();
  const dispatch = useDispatch<any>();

  const searchRegex = generateRegex(search);

  const filteredData = auditData?.data?.filter((d) => {
    const matchSearch = `${d.status}`.match(searchRegex);

    return matchSearch;
  });

  useEffect(() => {
    dispatch(listUsersAsync());
  }, [dispatch]);

  const handleChangePage = (index: number) => {
    setCurrentPage(index);
  }

  return (
    <>
      <Wrapper>
        <Header>
          <Title>Activity Logs</Title>
          <SearchInput value={search} onChange={setSearch} />
        </Header>

        <Table 
          data={filteredData ?? []} 
          columns={columns} 
          pageSize={dataLimit} 
          totalData={totalData}
          currentPage={currentPage}
          setCurrentPage={(index: number) => handleChangePage(index)}
        />
      </Wrapper>
    </>
  );
};
