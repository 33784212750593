import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useDispatch } from "react-redux";

import locale from "locale";
import routes from "config/routes";
import type Leave from "types/leave";
import type { Column } from "components/lib/Table/types";
import { fillRouteParam, getLeaveDuration } from "utils";
import useNavStore from "stores/useNavStore";

import DateTime from "components/lib/Table/plugins/DateTime";
import Vertical3Dot, {
  Wrapper as _Vertical3Dot,
} from "components/svg/Vertical3Dot";
import Tag from "components/common/Tag";
import Trash from "components/svg/Trash";
import Edit from "components/svg/EditPen";
import api from "api";
import { fetchRequest } from "redux/features/myRequest/myRequestSlice";
import useUserStore from "stores/useUserStore";
import { toast } from "react-toastify";
import { endOfDay, startOfDay } from "date-fns";

// import Trash from "components/svg/Trash";

const MoreDetail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${_Vertical3Dot} {
    height: 13rem;
  }
`;

export default () => {
  const navigate = useNavStore((state) => state.navigate);
  const user = useUserStore((state) => state.user);
  const dispatch = useDispatch<any>();

  const [columns, setColumns] = useState<Column<Leave>[]>([]);
  const [show, setShow] = useState({ id: "", state: false });
  // console.log(show);

  const handleDetailClick = (r: Leave) => {
    const route = routes.myRequest.routes!.view;

    if (navigate && route.render) {
      const url = fillRouteParam(route.render!.url, [
        { param: "id", value: r.id },
      ]);

      navigate(route, url);
    }
  };

  const handleShow = (r: any) => {
    if (r.id === show.id) setShow({ id: "", state: false });
    setShow({ id: r.id, state: !show.state });
  };

  // const handleView = (r: any) => {
  //   console.log("view");
  // };
  const handleEdit = (r: any) => {
    if (r.supervisor.status === "approved" || r.hr.status === "approved") {
      return toast.error("You can not update this leave!");
    }

    const route = routes.myRequest.routes!.edit;

    if (navigate && route.render) {
      const url = fillRouteParam(route.render!.url, [
        { param: "id", value: r.id },
      ]);

      navigate(route, url);
    }
  };
  const handleDelete = (r: any) => {
    if (r.supervisor.status === "approved" || r.hr.status === "approved") {
      return toast.error("You can not delete this leave!");
    }
    let check = window.confirm("Are you sure you want to delete");
    if (check) {
      api.leave.deleteLeave(r.id).then(() => {
        dispatch(fetchRequest(user?.id!));
      });
    }
  };
  // console.log(show);

  useEffect(() => {
    if (navigate) {
      const columns: Column<Leave>[] = [
        {
          title: locale.en.myRequest.table.type,
          render: (r) => r.typeName,
        },
        {
          title: locale.en.myRequest.table.start,
          render: (r) => <DateTime date={r.startAPM === "AM" ? startOfDay(r.start) : endOfDay(r.start)} />,
        },
        {
          title: locale.en.myRequest.table.end,
          render: (r) => <DateTime date={r.endAPM === "AM" ? startOfDay(r.end) : endOfDay(r.end)} />,
        },
        {
          title: locale.en.myRequest.table.duration,
          render: (r) => getLeaveDuration(r.duration),
        },
        {
          title: locale.en.myRequest.table.supervisor,
          render: (r) => <Tag status={r.supervisor.status} />,
        },
        {
          title: locale.en.myRequest.table.hr,
          render: (r) => <Tag status={r.hr.status} />,
        },
        {
          title: "",
          width: "30rem",
          render: (r) => (
            <MoreDetail>
              <div onClick={() => handleShow(r)} className="menu dropdown">
                <div className="dropbtn">
                  <div>
                    <Vertical3Dot />
                  </div>
                </div>
                <div
                  className="dropdown-content"
                  style={{
                    display: r.id === show.id && show.state ? "block" : "none",
                  }}
                >
                  <a>
                    <i
                      className="fa-solid fa-eye"
                      style={{ fontSize: "20px" }}
                      onClick={() => handleDetailClick(r)}
                    ></i>
                  </a>
                  <a onClick={() => handleEdit(r)}>
                    <Edit />
                  </a>
                  <a>
                    <Trash onClick={() => handleDelete(r)} />
                  </a>
                </div>
              </div>
            </MoreDetail>
          ),
        },
      ];

      setColumns(columns);
    }
  }, [navigate, show]);

  return columns;
};
