import axios from './axios';
import backend from 'config/backend';
import type Audit from 'types/audit';
import { format } from 'date-fns';

/**
 * Mapping
 */

type ServerSchema = {
  organizationId: string,
  description: string,
  status: string,
  type: string,
  subject: string,
  date: string,
  operator: any,
  _id: string,
  createdAt: string,
  updatedAt: string
}

function mapToReact(response: ServerSchema): Audit {
  return {
    date: format(new Date(response.date), backend.format.dateTime),
    subject: response.subject,
    description: response.description,
    status: response.status,
    userName: response.operator?.userName ?? "",
    type: response.type,
  }
}

/**
 * API Implementation
 */
//For react-query

async function list(role: string, dataSkip: number, dataLimit: number) {
  let url = backend.audit.list;
  if (role === "user") {
    url = backend.audit.getOwnList;
  }

  const response = await axios.get<{ data: ServerSchema[], totalCount: number }>(url, {
    params: { skip: dataSkip, limit: dataLimit }
  });

  const data: Audit[] = [];

  response.data.data.forEach((item) => {
    data.push(mapToReact(item));
  });

  return {
    data,
    totalData: response.data.totalCount
  };
}

//getById
async function getOwnList() {
  const response = await axios.get<{ data: ServerSchema }>(backend.audit.getOwnList);
  return mapToReact(response.data.data);
}

export default {
  list,
  getOwnList,
}