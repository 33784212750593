import { parseISO, format } from "date-fns";

import axios from "./axios";
import backend from "config/backend";
import { formatDate } from "config/common";
import Leave, { Status, Type } from "types/leave";

/**
 * Mapping
 */
type LeaveType = "annualLeave" | "sickLeave";

type LeaveStatus = "pending" | "approved" | "rejected";

type ServerSchema = {
  _id: string;
  userUid: string;
  leaveType: string;
  leaveTypeInfo: {
    name: string | undefined
  };
  deductOn: string;
  startDate: string;
  startAPM: string;
  endDate: string;
  endAPM: string;
  duration: number;
  reason: string;
  comments: string;
  status: {
    byHr: {
      userUid: string;
      status: LeaveStatus;
    };
    bySupervisor: {
      userUid: string;
      status: LeaveStatus;
    };
  };
  recipients: {
    approvers: { uId: string }[];
    ccTo: { uId: string }[];
  };
  createdAt: string;
  updatedAt: string;
  proof: string,
};

function mapToReact(response: ServerSchema): Leave {
  // let type: Type;
  // switch (response.leaveType) {
  //   case "annualLeave":
  //     type = "annual";
  //     break;
  //   case "sickLeave":
  //     type = "sick";
  //     break;
  // }

  const approvers = response.recipients.approvers.map((d) => d.uId);
  const recipients = response.recipients.ccTo?.map((d) => d.uId);

  return {
    id: response._id,
    userId: response.userUid,
    type: response.leaveType,
    typeName: response.leaveTypeInfo?.name ?? "",
    deductOn: response.deductOn,
    start: formatDate(response.startDate),
    startAPM: response.startAPM ? response.startAPM : "AM",
    end: formatDate(response.endDate),
    endAPM: response.endAPM ? response.endAPM : "PM",
    duration: response.duration,
    leaveReason: response.reason,
    approverComment: response.comments,
    hr: {
      userId: response.status.byHr.userUid,
      status: response.status.byHr.status,
    },
    supervisor: {
      userId: response.status.bySupervisor.userUid,
      status: response.status.bySupervisor.status,
    },
    approvers,
    recipients,
    proof: response.proof,
  };
}

function mapToServer(leave: Partial<Leave>): Partial<ServerSchema> {
  // let leaveType: LeaveType;
  // switch (leave.type) {
  //   case "annual":
  //     leaveType = "annualLeave";
  //     break;
  //   case "sick":
  //     leaveType = "sickLeave";
  //     break;
  // }

  return {
    userUid: leave.userId,
    leaveType: leave.type,
    leaveTypeInfo: {
      name: leave.typeName
    },
    deductOn: leave.deductOn,
    startDate: leave.start
      ? format(leave.start, backend.format.date)
      : undefined,
    startAPM : leave.startAPM,
    endDate: leave.end ? format(leave.end, backend.format.date) : undefined,
    endAPM : leave.endAPM,
    duration: leave.duration,
    reason: leave.leaveReason,
    recipients: {
      approvers: leave.approvers?.map((id) => ({ uId: id })) ?? [],
      ccTo: leave.recipients?.map((id) => ({ uId: id })) ?? [],
    },
    proof: leave.proof
  };
}

function mapToServer1(leave: Partial<Leave>): Partial<ServerSchema> {
  // let leaveType: LeaveType;
  // switch (leave.type) {
  //   case "annual":
  //     leaveType = "annualLeave";
  //     break;
  //   case "sick":
  //     leaveType = "sickLeave";
  //     break;
  // }

  return {
    // userUid: leave.userId,
    leaveType: leave.type,
    leaveTypeInfo: {
      name: leave.typeName
    },
    deductOn: leave.deductOn,
    startDate: leave.start
      ? format(leave.start, backend.format.date)
      : undefined,
    endDate: leave.end ? format(leave.end, backend.format.date) : undefined,
    duration: leave.duration,
    reason: leave.leaveReason,
    comments: leave.approverComment,
    recipients: {
      approvers: leave.approvers?.map((id: any) => ({ uId: id.id })) ?? [],
      ccTo: leave.recipients?.map((id: any) => ({ uId: id.id })) ?? [],
    },
    proof: leave.proof
  };
}

/**
 * API Implementation
 */
const queryKey = "leave";

async function list(params?: {
  userUid?: string;
  approverUid?: string;
  startDate?: Date;
  endDate?: Date;
  searchByName?: string;
  departmentId?: string;
}) {
  const response = await axios.get<{ data: ServerSchema[] }>(
    backend.leave.list,
    { params }
  );

  const data: Leave[] = response.data.data.map((d) => mapToReact(d));

  return data;
}

async function chartList(params?: {
  userUid?: string;
  approverUid?: string;
  startDate?: Date;
  endDate?: Date;
  searchByName?: string;
  department?: string;
  role?: string;
}) {
  // console.log(
  //   "requesturl",
  //   `${backend.leave.list}?startDate=${params?.startDate}&endDate=${params?.endDate}&searchByName=${params?.searchByName}&${params?.department}`
  // );
  const urlIs = params?.role === "user" ? backend.leave.listChart : backend.leave.listChartAdmin
  const response = await axios.get<{ data: ServerSchema[] }>(
    `${urlIs}?startDate=${params?.startDate}&endDate=${
      params?.endDate
    }&searchByName=${params?.searchByName}&${
      params?.department
    }&status_hr=approved&${params?.userUid && `userUid=${params?.userUid}`}&${`status_supervisor=approved`}`
  );

  const data: Leave[] = response.data.data.map((d) => mapToReact(d));

  return data;
}

async function getById(reqId: string) {
  const url = backend.leave.getById.replace("{{ 1 }}", reqId);
  const response = await axios.get<{ data: ServerSchema }>(url);

  return mapToReact(response.data.data);
}

type CreateLeave = Pick<
  Leave,
  | "userId"
  | "type"
  | "deductOn"
  | "start"
  | "startAPM"
  | "end"
  | "endAPM"
  | "duration"
  | "leaveReason"
  | "recipients"
  | "approvers"
>;

async function createFormData(data: FormData) {
  await axios.post(backend.leave.create, data);
}

async function create(data: CreateLeave) {
  const request = mapToServer(data);
  await axios.post(backend.leave.create, request);
}

async function updateFormData(data: FormData, id: string | undefined) {
  const url = backend.leave.update.replace("{{ 1 }}", id ? id : "");
  await axios.put(url, data);
}

async function update(data: any) {
  console.log("server", data);

  const url = backend.leave.update.replace("{{ 1 }}", data?.id);
  const request = mapToServer1(data);
  await axios.put(url, request);
}
async function deleteLeave(id: any) {
  const url = backend.leave.delete.replace("{{ 1 }}", id);
  // const request = mapToServer1(data);
  await axios.delete(url);
}
async function approve(
  leaveId: string,
  status: string,
  by: string,
  comments: string
) {
  console.log("comments", comments);

  const url = `${backend.leave.list}/${leaveId}/approval/?status=${status}&by=${by}`;

  const response = await axios.put<{ data: ServerSchema }>(url, { comments });
  return response.data;
  // return mapToReact(response.data.data);
}

export default {
  queryKey,
  list,
  getById,
  create,
  createFormData,
  approve,
  chartList,
  update,
  updateFormData,
  deleteLeave,
};
