import React, { useState, useEffect, ChangeEvent } from "react";
import { format, isAfter, isEqual } from "date-fns";
import styled from "styled-components/macro";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { DropdownList } from "react-widgets/cjs";
import { toast } from "react-toastify";
import FormBootsrap from 'react-bootstrap/Form';
import { addDays, isBefore, startOfToday } from "date-fns";

import locale from "locale";
import api from "api";
import { deductType } from "../../data";
import type { Type } from "types/leave";
import type { Supervisor } from "types/user";
import { getLeaveDuration } from "utils";
import useForm from "./useForm";
import useLeaveTypes from "./useLeaveTypes";
import useApprovers from "./useApprovers";
import useUserStore from "stores/useUserStore";
import useNavStore from "stores/useNavStore";

import XWithRing, { Wrapper as _XWithRing } from "components/svg/XWithRing";
import Input from "components/lib/Input";
import Label from "components/lib/Label";
import Button from "components/lib/Button";
import _Button from "components/lib/Button/styled";
import DateRangePicker from "components/common/DateRangePicker";
import TextArea from "components/lib/TextArea";
import Multiselect from "components/common/Multiselect";
import routes from "config/routes";
import backend from "config/backend";

const Wrapper = styled.div`
  --gap: 20rem;

  position: relative;
  max-width: var(--form-max-width);
  padding: var(--container-padding);
  padding-top: 35rem;

  margin: 0 auto;
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  & > ${_XWithRing} {
    position: absolute;
    top: 12rem;
    right: 12rem;
    height: 20rem;
  }

  ${_Button} {
    align-self: end;
  }
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);

  & .row {
    grid-column: 1 / span 2;
  }
`;

export type FormState = {
  type: string;
  deductOn: string;
  start: Date | null;
  startAPM: string;
  end: Date | null;
  endAPM: string;
  leaveReason: string;
  approvers: Supervisor[];
  recipients: Supervisor[];
};

export default () => {
  /**
   * Hooks
   */
  const queryClient = useQueryClient();
  const navigate = useNavStore((state) => state.navigate);
  const nativeNavigate = useNavigate();

  const user = useUserStore((state) => state.user);
  const [proof, setProof] = useState<File | null>(null);
  const [workdays, setWorkdays] = useState<string[]>([]);
  const [holidays, setHolidays] = useState<string[]>([]);
  const uId = localStorage.getItem("uId");
  // const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { form, dayDiff, setForm } = useForm({
    workdays: workdays,
    holidays: holidays
  });
  const { approvers, recipients } = useApprovers();
  const { leaveTypes } = useLeaveTypes();

  // const { data: leaveTypes } = useQuery({
  //   queryKey: api.leaveType.queryKey,
  //   queryFn: () => api.leaveType.list(),
  // });

  // console.log(leaveTypes)

  useEffect(() => {
    api.user.getById(uId ? uId : "")
    .then((res) => {
      setWorkdays(res.workDayDetails ? res.workDayDetails : []);
    });

    const year = new Date().getFullYear();
    api.holiday.list(year)
    .then((res) => {
      const holidayStr: string[] = [];
      res.map((holiday) => {
        let currentDate = holiday.start;
        while (currentDate <= holiday.end) {
          const day = currentDate.getDate().toString().padStart(2, '0');
          const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
          const year = currentDate.getFullYear().toString();

          const formattedDate = `${day}-${month}-${year}`;
          holidayStr.push(formattedDate);

          currentDate.setDate(currentDate.getDate() + 1);
        }

        setHolidays(holidayStr);
      })
    });
  }, [])


  const mutation = useMutation(api.leave.createFormData, {
    onSuccess: () => {
      queryClient.invalidateQueries([api.leave.queryKey, user!.id]);

      const message = locale.en.toastMessages.createSuccess.replace(
        "{{ 1 }}",
        "Leave request"
      );
      toast.success(message);

      navigate && navigate(routes.myRequest);
    },
  });

  // fetch leave type

  /**
   * Not hook
   */

  const handleSubmit = () => {
    if (!user || !navigate) return;

    let list = form.recipients.map((r) => r.id);

    // check api/leave schema mapToServer
    const formData = new FormData();
    formData.append("userUid", user.id);
    formData.append("leaveType", form.type ?? "");
    formData.append("deductOn", form.deductOn ?? "annual");
    formData.append("startDate", JSON.stringify(format(form.start ?? new Date(), backend.format.date)));
    formData.append("startAPM", form.startAPM ?? "AM");
    formData.append("endDate", JSON.stringify(format(form.end ?? new Date(), backend.format.date)));
    formData.append("endAPM", form.endAPM ?? "PM");
    formData.append("duration", JSON.stringify(dayDiff));
    formData.append("reason", form.leaveReason);
    formData.append("recipients", JSON.stringify({
      approvers: form.approvers?.map((a) => ({ uId: a.id })) ?? [],
      recipients: form.recipients?.map((r) => ({ uId: r.id })) ?? []
    }));
    if (proof) {
      formData.append("proof", proof);
    }

    mutation.mutate(formData);

    // without form data / file
    // mutation.mutate({
    //   userId: user.id,
    //   type: form.type ?? "",
    //   deductOn: form.deductOn ?? "annual",
    //   start: form.start ?? new Date(),
    //   startAPM: form.startAPM ?? "AM",
    //   end: form.end ?? new Date(),
    //   endAPM: form.endAPM ?? "PM",
    //   duration: dayDiff,
    //   leaveReason: form.leaveReason,
    //   approvers: form.approvers.map((a) => a.id),
    //   recipients: form.recipients.map((r) => r.id)
    // });
  };

  const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files  = event.target.files;
    if (files && files.length > 0) {
      setProof(files[0]);
    }
  }

  const today = startOfToday();
  const handleStartDateChange = (d: Date | null | undefined) => {
    if (!d || isBefore(d, today)) {
      setForm({ ...form, start: null, end: null });
    } else if (form.end && isAfter(d, form.end)) {
      setForm({ ...form, start: d });
    } else if (form.end && isBefore(d, form.end)) {
      setForm({ ...form, start: d });
    } else if (form.end && isEqual(d, form.end)) {
      setForm({ ...form, start: d });
    } else {
      setForm({ ...form, start: d, end: null });
    }
  };
  
  const handleEndDateChange = (d: Date | null | undefined) => {
    if (!d || isBefore(d, today) || (form.start && isBefore(d, form.start))) {
      setForm({ ...form, end: null });
    } else {
      setForm({ ...form, end: d });
    }
  };
  /**
   * Render
   */
  return (
    <Wrapper>
      <XWithRing
        color="var(--primary-color)"
        onClick={() => nativeNavigate(-1)}
      />

      <Form>
        <DateRangePicker
          className="row"
          workdays={workdays}
          holidays={holidays}
          start={{
            value: form.start,
            onChange: (d) => setForm({ start: d }),
          }}
          end={{
            value: form.end,
            onChange: (d) => setForm({ end: d }),
          }}
        />
        {/* <DateRangePicker
          className="row"
          start={{
            value: form.start,
            onChange: handleStartDateChange,
          }}
          end={{
            value: form.end,
            onChange: handleEndDateChange,
          }}
        /> */}

          <DropdownList
            data={["AM","PM"]}
            value={form.startAPM ?? "AM"}
            onChange={(d) => setForm({ startAPM: d})}
          />
          <DropdownList
            data={["AM","PM"]}
            value={form.endAPM ?? "PM"}
            onChange={(d) => setForm({ endAPM: d})}
          />
        <Label title={locale.en.myRequest.form.duration}>
          <Input
            value={dayDiff !== 0 ? getLeaveDuration(dayDiff) : "-"}
            readOnly
          />
        </Label>

        <Label title={locale.en.myRequest.form.type}>
          <DropdownList
            placeholder={locale.en.myRequest.form.reasonPlaceholder}
            data={leaveTypes}
            dataKey="id"
            textField="name"
            value={form.type ?? null}
            onChange={(d) => setForm({ type: d.id })}
          />
        </Label>

        <Label title={locale.en.myRequest.form.deductType}>
          <DropdownList
            placeholder={locale.en.myRequest.form.deductType}
            data={deductType}
            dataKey="id"
            textField="name"
            value={form.deductOn ?? null}
            onChange={(d) => setForm({ deductOn: d.id })}
          />
        </Label>

        <Label title={locale.en.myRequest.form.reason} className="row">
          <TextArea
            value={form.leaveReason}
            onChange={(s) => setForm({ leaveReason: s })}
          />
        </Label>

        <Label title={"Attachment"} className="row">
          <FormBootsrap.Group controlId="formFileMultiple" onChange={handleFileInputChange}>
            <FormBootsrap.Control type="file" multiple />
          </FormBootsrap.Group>
        </Label>

        <Label title={locale.en.myRequest.form.approver}>
          <Multiselect
            dataKey="id"
            textField="firstName"
            data={approvers}
            bgColor="#45BF4322"
            color="#45BF43"
            value={form.approvers}
            onChange={(s) => setForm({ approvers: s })}
          />
        </Label>

        <Label title={locale.en.myRequest.form.recipient}>
          <Multiselect
            dataKey="id"
            textField="firstName"
            data={recipients}
            bgColor="#FF424222"
            color="#FF4242"
            value={form.recipients}
            onChange={(s) => setForm({ recipients: s })}
          />
        </Label>
      </Form>

      <Button.Classic onClick={handleSubmit}>
        {locale.en.common.button.submit}
      </Button.Classic>
    </Wrapper>
  );
};
