import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";
import { getYear } from "date-fns";

import holiday from "api/holiday";
import type Holiday from "types/holiday";
import useColumns from "./useColumns";

import MonthYearPicker, {
  Wrapper as _MonthYearPicker,
  CalendarContainer as _MonthYearPicker__CalendarContainer,
} from "components/common/MonthYearPicker";

import Table, { Wrapper as _Table } from "components/lib/Table";

import Modal from "components/lib/Modal";
import DeleteHoliday from "./DeleteHoliday";
import { fetchHolidays } from "redux/features/holidays/holidaySlice";
import { useDispatch, useSelector } from "react-redux";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;

  ${_MonthYearPicker} {
    align-self: end;
  }

  ${_MonthYearPicker__CalendarContainer} {
    left: unset;
    right: 0;
  }

  ${_Table} {
    padding: var(--container-padding);
    padding-top: 5rem;

    background: var(--container-bg-color);
    border-radius: var(--container-border-radius);
  }
`;

export default () => {
  /**
   * Hooks
   */
  const [year, setYear] = useState(new Date());
  const dispatch = useDispatch<any>();
  const { holidays } = useSelector((state: any) => state.holiday);

  const { data } = useQuery([holiday.queryKey, getYear(year)], () =>
    holiday.list(getYear(year))
  );

  console.log(holidays);

  const [modalVisible, setModalVisible] = useState(false);
  const [delHoliday, setDelHoliday] = useState<Holiday>();

  const columns = useColumns((h: Holiday) => {
    setDelHoliday(h);
    setModalVisible(true);
  });

  useEffect(() => {
    dispatch(fetchHolidays(getYear(year)));
  }, [dispatch, year]);

  /**
   * Render
   */
  return (
    <>
      <Wrapper>
        <MonthYearPicker level="year" value={year} onChange={setYear} />

        <Table columns={columns} data={data ?? []} pageSize={10} />
      </Wrapper>

      <Modal visible={modalVisible} setVisible={setModalVisible}>
        <DeleteHoliday
          setModalVisible={setModalVisible}
          holiday={[delHoliday, setDelHoliday]}
        />
      </Modal>
    </>
  );
};
