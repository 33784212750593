import api from "api";
import routes from "config/routes";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import useAuthStore from "stores/useAuthStore";
import useNavStore from "stores/useNavStore";
import { parseISO, format } from "date-fns";

import Form from "../Form";

export default () => {
  const { id } = useParams();
  const getRole = useAuthStore.getState().role;

  /**
   * Hooks
   */
  const name = useState("");
  const supervisor = useState("");
  const start = useState<Date>();
  const end = useState<Date>();
  const type = useState("");
  const typeName = useState("");
  const duration = useState<any>(0);
  const reason = useState("");
  const comments = useState("");
  const deductOn = useState("");
  const [proof, setProof] = useState("");
  const [leaveBalance, setLeaveBalance] = useState<any>([]);
  const [supervisorStatus, setSupervisorStatus] = useState<any>([]);
  const navigate = useNavStore((state) => state.navigate);

  /**
   * Not hooks
   */
  const handleReject = () => {
    if (id !== undefined) {
      const role = getRole === "admin" ? "hr" : "supervisor";
      api.leave.approve(id, "rejected", role, comments[0]).then((data) => {
        navigate!(routes.pendingMod);
      });
    }
  };

  const handleModify = () => {
    if (id !== undefined) {
      const role = getRole === "admin" ? "hr" : "supervisor";
      api.leave.approve(id, "approved", role, comments[0]).then((data) => {
        navigate!(routes.pendingMod);
      });
    }
  };

  const fetchById = async (id: any) => {
    const res: any = await api.leave.getById(id);
    console.log(res);
    const res1 = await api.leaveBalance.list({ userUid: res.userId?.uId });
    setLeaveBalance(res1);
    setSupervisorStatus(res.supervisor.status);
    name[1](res?.userId?.userName);
    supervisor[1](
      `${res?.approvers[0]?.firstName} ${res?.approvers[0]?.lastName}`
    );
    start[1](res.start);
    end[1](res.end);
    type[1](res.type);
    typeName[1](res.typeName);
    duration[1](res.duration);
    reason[1](res.leaveReason);
    comments[1](res.approverComment);
    deductOn[1](res.deductOn);
    setProof(res.proof);
  };

  useEffect(() => {
    if (id) {
      fetchById(id);
    }
  }, [id]);

  /**
   * Render
   */
  return (
    <Form
      name={name}
      supervisor={supervisor}
      start={start}
      end={end}
      type={type}
      typeName={typeName}
      duration={duration}
      reason={reason}
      comments={comments}
      deductOn={deductOn}
      proof={proof}
      button={{
        onReject: handleReject,
        onModify: handleModify,
      }}
      balance={leaveBalance}
      supervisorStatus={supervisorStatus}
    />
  );
};
