import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 1/1;
  height: auto;
  width: auto;
`;

export default ({ color = "black" }: {
  color?: string
}) => (
  <Wrapper
    width={15} height={15}
    viewBox="0 0 15 15"
  >
    <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M9.2051 1.20495C9.33637 1.07372 9.51439 1 9.7 1C9.88562 1 10.0636 1.07372 10.1949 1.20495L12.9949 4.00495C13.1261 4.13622 13.1998 4.31423 13.1998 4.49985C13.1998 4.68546 13.1261 4.86348 12.9949 4.99475L10.1949 7.79475C10.0629 7.92225 9.88606 7.99285 9.70252 7.99125C9.51898 7.98965 9.34341 7.91605 9.21363 7.78625C9.08384 7.65645 9.01022 7.48085 9.00863 7.29735C9.00703 7.11375 9.07759 6.93695 9.2051 6.80495L10.8102 5.19985H2.7C2.51435 5.19985 2.3363 5.1261 2.20503 4.99482C2.07375 4.86355 2 4.6855 2 4.49985C2 4.3142 2.07375 4.13615 2.20503 4.00487C2.3363 3.8736 2.51435 3.79985 2.7 3.79985H10.8102L9.2051 2.19475C9.07387 2.06348 9.00015 1.88546 9.00015 1.69985C9.00015 1.51423 9.07387 1.33622 9.2051 1.20495Z" />
    <path fill={color} fillRule="evenodd" clipRule="evenodd" d="M5.99475 13.7863C5.86348 13.9175 5.68547 13.9912 5.49985 13.9912C5.31424 13.9912 5.13622 13.9175 5.00495 13.7863L2.20495 10.9863C2.07372 10.855 2 10.677 2 10.4914C2 10.3058 2.07372 10.1278 2.20495 9.9965L5.00495 7.1965C5.13697 7.06899 5.31379 6.99843 5.49733 7.00003C5.68087 7.00162 5.85644 7.07524 5.98622 7.20502C6.11601 7.33481 6.18963 7.51038 6.19122 7.69392C6.19282 7.87745 6.12226 8.05428 5.99475 8.1863L4.38965 9.7914H12.4998C12.6855 9.7914 12.8635 9.86515 12.9948 9.99642C13.1261 10.1277 13.1998 10.3057 13.1998 10.4914C13.1998 10.677 13.1261 10.8551 12.9948 10.9864C12.8635 11.1176 12.6855 11.1914 12.4998 11.1914H4.38965L5.99475 12.7965C6.12598 12.9277 6.1997 13.1057 6.1997 13.2914C6.1997 13.477 6.12598 13.655 5.99475 13.7863Z" />
  </Wrapper>
)