import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 18/19;
  height: auto;
  width: auto;
`;

export default ({ color = "black" }: {
  color?: string
}) => {
  return (
    <Wrapper
      width={18} height={19}
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={color} d="M17.7094 17.4972L13.3869 13.1746C14.5464 11.8416 15.2484 10.1023 15.2484 8.20135C15.2484 4.01685 11.8448 0.617188 7.66424 0.617188C3.47974 0.617188 0.0800781 4.02079 0.0800781 8.20135C0.0800781 12.3819 3.48368 15.7855 7.66424 15.7855C9.56521 15.7855 11.3045 15.0835 12.6375 13.924L16.9601 18.2465C17.0626 18.3491 17.2006 18.4043 17.3347 18.4043C17.4688 18.4043 17.6069 18.353 17.7094 18.2465C17.9145 18.0414 17.9145 17.7023 17.7094 17.4972ZM1.14099 8.20135C1.14099 4.60449 4.06738 1.68205 7.66029 1.68205C11.2572 1.68205 14.1796 4.60844 14.1796 8.20135C14.1796 11.7943 11.2572 14.7246 7.66029 14.7246C4.06738 14.7246 1.14099 11.7982 1.14099 8.20135Z" />
    </Wrapper>
  )
}