import React, { useEffect, useState } from "react";

import locale from "locale";

import { Wrapper } from "../styled";
import Card from "components/common/Card";
import routes from "config/routes";
import useNavStore from "stores/useNavStore";
import { useQuery } from "react-query";
import api from "api";
import useUserStore from "stores/useUserStore";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchApprovals } from "redux/features/myApproval/myApprovalSlice";

export default () => {
  const year = new Date().getFullYear().toString();

  const navigate = useNavStore((state) => state.navigate);
  const { approvals } = useSelector((state: any) => state.approval);
  const user = useUserStore((state) => state.user);
  const [state, setState] = useState<any>([]);
  const dispatch = useDispatch<any>();
  const calculateRemaining = () => {
    if (data) {
      // let consumed = data[0]?.balance[year]?.consumed || 0;
      let total = data[0]?.balance[year]?.projectedBalance || 0;
      // return total - consumed;
      return total;
    }
  };
  const percentage = () => {
    if (data) {
      let consumed = data[0]?.balance[year]?.consumed || 0;
      let total = data[0]?.balance[year]?.projectedBalance || 0;
      return (consumed / (total + consumed)) * 100;
    }
  };

  // let pending = approvals.filter((leave: any) => leave.hr.status == "pending");
  let pending = state.filter((leave: any) => leave.hr.status == "pending");

  const { data } = useQuery({
    queryKey: [api.leaveBalance.queryKey, user?.id],
    queryFn: () => api.leaveBalance.list({ userUid: user?.id }),
    enabled: !!user,
  });
  const data1 = useQuery({
    queryKey: [api.leave.queryKey, user?.id],
    queryFn: () => api.leave.list({ approverUid: user?.id }),
    enabled: !!user,
  });

  // console.log("percentage", percentage());
  const handleMyRequests = () => {
    navigate!(routes.myRequest);
  };
  const handleMyApprovals = () => {
    navigate!(routes.myApprovals);
  };
  const handleAddnew = () => {
    navigate!(routes.myRequest);
  };

  useEffect(() => {
    // fetchApprovalByUser(user?.id)
    const obj = {
      approverUid: user?.id,
      setState,
    };
    dispatch(fetchApprovals(obj));
  }, [user?.id, dispatch]);

  return (
    <Wrapper>
      <Card.Statistic.Classic
        title={locale.en.dashboard.user.myRequests}
        consumed={
          `${
            data && data[0]?.balance[year]?.consumed
              ? data[0]?.balance[year]?.consumed
              : 0
          }` || "0"
        }
        remaining={`${calculateRemaining()}` || "0"}
        percentage={Math.floor(percentage() || 0) || 0}
        onPlusClick={handleAddnew}
        onViewClick={handleMyRequests}
      />

      <Card.Numeric
        title={locale.en.dashboard.user.myApprovals}
        number={pending.length || 0}
        // remaining="04"
        onClick={handleMyApprovals}
      />
    </Wrapper>
  );
};
