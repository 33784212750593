import axios from './axios';
import backend from 'config/backend';
import type AdjustmentHistory from 'types/adjustmentHistory';
import { BalanceId, dataAdjustment } from 'types/adjustmentHistory';
import { format } from 'date-fns';

/**
 * Mapping
 */

type ServerSchema = {
  data: dataAdjustment;
  leaveBalanceId: any;
  organizationId: string,
  description: string,
  operator: any,
  _id: string,
  createdAt: string,
  updatedAt: string
}

function mapToReact(response: ServerSchema): AdjustmentHistory {
  return {
    id: response._id,
    organizationId: response.organizationId,
    operator: response.operator,
    balanceOwner: response.leaveBalanceId.userDetail.userName,
    data: response.data,
    createdAt: format(new Date(response.createdAt), backend.format.dateTime),
  }
}

/**
 * API Implementation
 */
//For react-query

async function list(role: string, dataSkip: number, dataLimit: number) {
  let url = backend.adjustmentHistory.list;
  if (role === "user") {
    url = backend.adjustmentHistory.getOwnList;
  }

  const response = await axios.get<{ data: ServerSchema[], totalCount: number }>(url, {
    params: { skip: dataSkip, limit: dataLimit }
  });

  const data: AdjustmentHistory[] = [];

  response.data.data.forEach((item) => {
    data.push(mapToReact(item));
  });

  return {
    data,
    totalData: response.data.totalCount
  };
}

//getById
async function getOwnList() {
  const response = await axios.get<{ data: ServerSchema }>(backend.adjustmentHistory.getOwnList);
  return mapToReact(response.data.data);
}

export default {
  list,
  getOwnList,
}