import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";

export const initialState = {
  loading: false,
  hasErrors: false,
  leaveTypes: [],
};

const leaveTypeSlice = createSlice({
  name: "leaveType",
  initialState,
  reducers: {
    // getRepos: (state) => {
    //   state.loading = true;
    // },
    getLeaveTypeSuccess: (state, action) => {
      state.leaveTypes = action.payload;
      //   state.loading = false;
      //   state.hasErrors = false;
    },
    create: (state, action) => {},
    // getReposFailure: (state) => {
    //   state.loading = false;
    //   state.hasErrors = true;
    // },
  },
});

export const {
  // getRepos,
  getLeaveTypeSuccess,
  // getReposFailure
} = leaveTypeSlice.actions;

// The reducer
export default leaveTypeSlice.reducer;

export const fetchLeaveTypes = createAsyncThunk(
  "leaveType/fetchLeaveTypes",
  async (check, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());
    console.log({ thunkAPI });

    try {
      //   console.log("dispatching now");

      const response = await api.leaveType.list();
      //   console.log(response);

      // Set the data
      //   thunkAPI.dispatch(getLeaveTypeSuccess(response));
      thunkAPI.dispatch(getLeaveTypeSuccess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);

export const createLeaveTypes = createAsyncThunk(
  "leaveType/create",
  async (data: any, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(getRepos());

    try {
      //   console.log("dispatching now");

      const response = await api.leaveType.create(data);
      //   console.log(response);

      // Set the data
      //   thunkAPI.dispatch(getLeaveTypeSuccess(response));
      thunkAPI.dispatch(getLeaveTypeSuccess(response));
    } catch (error) {
      // Set any erros while trying to fetch
      //   thunkAPI.dispatch(getReposFailure());
    }
  }
);
