import type { Status, Role } from 'types/user';

const status: { display: string, value: Status }[] = [
  { display: "Full-time", value: "full" },
  { display: "Part-time", value: "part" },
  { display: "Temporary", value: "temporary" },
  { display: "Seasonal", value: "seasonal" },
];

const roles: { id: Role, display: string, disabled: boolean }[] = [
  { id: "superadmin", display: "Superadmin", disabled: true },
  { id: "hr-admin", display: "HR Admin", disabled: false },
  { id: "user", display: "User", disabled: false }
]

export default {
  status,
  roles
}