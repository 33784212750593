import React, { useState } from "react";
import styled from "styled-components/macro";
import { DropdownList } from "react-widgets/cjs";
import { toast } from "react-toastify";
import { parseISO, format } from "date-fns";

import locale from "locale";
import api from "api";
import routes from "config/routes";
import User, { Organization } from "types/auth";
import useAuthStore from "stores/useAuthStore";
import useUserStore, { getRole } from "stores/useUserStore";
import useNavStore from "stores/useNavStore";

// import LeaveLogo from "components/svg/LeaveLogo";
import LeaveLogo from "components/common/LeaveLogo";
import Button, { Wrapper as _Button } from "components/lib/Button";
import Fields from "./Fields";
// import SSOGroup from "./SSOGroup";

//REDUX
import { loginAsync } from "redux/features/auth/authSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import backend from "config/backend";

const Wrapper = styled.div`
  min-height: 100vh;
  width: 100%;

  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Wrapp = styled.div`
  display: flex;
  gap: 27rem;
  width: 100%;
`;
const Container = styled.div`
  background: inherit;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20rem;
`;

const Form = styled.form`
  margin-top: 10rem;
  background: var(--container-bg-color);
  border-radius: 10rem;
  width: 800rem;

  padding: 40rem 60rem;
  padding-bottom: 30rem;

  ${_Button} {
    margin-top: 30rem;
    padding: 8rem 15rem;
    width: 100%;
    justify-content: center;
  }
`;

const Title = styled.h3`
  font-size: 18rem;
  text-align: center;
  margin-bottom: 35rem;
`;

export default ({
  setHasLogin,
  setShowRegister,
  showRegister,
}: {
  setHasLogin: (b: boolean) => void;
  setShowRegister: (b: boolean) => void;
  showRegister: boolean;
}) => {
  const setAuth = useAuthStore((state) => state.setAuth);
  const _setUser = useUserStore((state) => state.setUser);
  const setRole = useUserStore((state) => state.setRole);
  // const authState = useAuthStore.getState();
  const orgId = useAuthStore.getState().orgId;

  const { user: userData } = useSelector((state: any) => state?.auth);

  const navigate = useNavStore((state) => state.navigate);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [orgName, setOrgName] = useState("");
  const [uen, setUen] = useState("");
  const [date, setDate] = useState<Date>();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useDispatch<any>();

  const [user, setUser] = useState<User>();

  const [org, setOrg] = useState<Organization>();

  const loginUser = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Email and password required");
      return;
    }
    // api.auth.login(email, password).then(setUser);
    // api.auth.login(email, password).then((data) => {
    //   setUser(data);
    //   dispatch(login(data));
    // });
    dispatch(loginAsync({ email, password, setUser, setAuth }));
  };

  const loginOrg = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!user || !org || !navigate) return;

    const role = getRole(user.roles);

    setRole(role);
    api.user.getById(user.id).then(_setUser);

    // if (cacheLocal) {
    //   setAuth({
    //     tokenUser: user.bearerToken,
    //     orgId: org.id,
    //     uId: user.id,
    //     path: routes.dashboard.render.url,
    //     role,
    //   });
    // }

    setHasLogin(true);
    navigate(routes.dashboard);
  };

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const registerPage = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!email) {
      return toast.error("Emaill is required!");
    }
    if (!validateEmail(email)) {
      return toast.error("Emaill must be valid!");
    }
    if (!userName) {
      return toast.error("userName is required!");
    }
    if (!password) {
      return toast.error("password is required!");
    }
    if (password !== confirmPassword) {
      return toast.error("Password must be same!");
    }
    if (!orgName) {
      return toast.error("Organization Name is required!");
    }
    if (!uen) {
      return toast.error("uen number is required!");
    }
    if (!date) {
      return toast.error("Date of Incorporation is required!");
    }
    const user = {
      firstName,
      lastName,
      userName,
      email,
      password,
    };
    const organization = {
      corpName: orgName,
      uen,
      dateOfIncorporation: format(date, backend.format.date),
    };

    api.auth.register({ user, organization }).then((data) => {
      if (data !== undefined) setShowRegister(!showRegister);
    });

    // navigate!()
  };

  return (
    <Wrapper>
      <Container>
        {/* <LeaveLogo color="black" /> */}
        <LeaveLogo color="black" />
        <Form>
          <Title className="montserrat">
            {!user ? locale.en.register.title[1] : locale.en.register.title[2]}
          </Title>

          {!user && (
            <Fields
              firstName={[firstName, setFirstName]}
              lastName={[lastName, setLastName]}
              email={[email, setEmail]}
              userName={[userName, setUserName]}
              password={[password, setPassword]}
              confirmPassword={[confirmPassword, setConfirmPassword]}
              uen={[uen, setUen]}
              orgName={[orgName, setOrgName]}
              date={[date, setDate]}
              // remember={cacheLocal}
            />
          )}
          {user && (
            <DropdownList
              data={user.organizations}
              textField="name"
              onChange={setOrg}
            />
          )}

          <Wrapp>
            <Button.Outline>{locale.en.register.button.cancel}</Button.Outline>
            <Button.Classic onClick={!user ? registerPage : loginOrg}>
              {locale.en.login.button.register}
            </Button.Classic>
          </Wrapp>
        </Form>
      </Container>
    </Wrapper>
  );
};
