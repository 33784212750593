import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";

import backend from "config/backend";
import useAuthStore from "stores/useAuthStore";

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    const tokenUser = useAuthStore.getState().tokenUser;
    const orgId = useAuthStore.getState().orgId;
    useAuthStore.getState();

    config.url = `${backend.url}/${config.url}`;
    config.headers!.authorization = tokenUser;
    config.headers!.orgId = orgId;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error: any) => {
    const status = error.response.status;
    const message = error?.response?.data?.message;
    // const {message:errorMessage}=error?.response?.data;

    toast.error(`${status}: ${message}`);
    console.error(status, JSON.stringify(error.response?.data));

    return Promise.reject(error);
  }
);

export default axiosInstance;
