//https://date-fns.org/v2.28.0/docs/format
export default {
  default: {
    //Settings page
    settings: {
      workingDay: {
        //Follow itmePicker format below
        startTime: "09 : 00 AM",
        endTime: "06 : 00 PM"
      }
    }
  },
  format: {
    datePicker: "MMMM do yyyy",
    monthPicker: "MMMM yyyy",
    yearPicker: "yyyy",

    timePicker: "hh : mm a"
  }
}