import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import type { User } from "pages/MyApprovals/types";
import mock from "../mock-data";

import Form from "pages/MyApprovals/Form";
import { fetchLeaveById } from "redux/features/leave/leaveSlice";
import api from "api";
import useAuthStore from "stores/useAuthStore";
import useNavStore from "stores/useNavStore";
import routes from "config/routes";

export default () => {
  const { id } = useParams<{ id: string }>();
  const getRole = useAuthStore.getState().role;
  const navigate = useNavStore((state) => state.navigate);
  const [data, setData] = useState<User>();
  const name = useState<any>("");
  const start = useState<any>("");
  const end = useState<any>("");
  const type = useState<any>("");
  const typeName = useState<any>("");
  const duration = useState<any>("");
  const reason = useState<any>("");
  const comments = useState<any>("");
  const deductOn = useState<any>("");
  const proof = useState<any>("");

  const [leaveBalance, setLeaveBalance] = useState<any>();

  const fetchById = async (id: any) => {
    const res: any = await api.leave.getById(id);
    const res1 = await api.leaveBalance.list({ userUid: res.userId?.uId });
    setLeaveBalance(res1);
    name[1](res?.userId?.userName);
    start[1](res.start);
    end[1](res.end);
    type[1](res.type);
    typeName[1](res.typeName);
    duration[1](res.duration);
    reason[1](res.leaveReason);
    comments[1](res.approverComment);
    deductOn[1](res.deductOn);
    proof[1](res.proof);
  };

  const handleReject = () => {
    if (id !== undefined) {
      const role = getRole === "admin" ? "hr" : "supervisor";
      api.leave.approve(id, "rejected", role, comments[0]).then((data) => {
        navigate!(routes.myApprovals);
      });
    }
  };

  // console.log("comments", comments[0]);

  const handleModify = () => {
    if (id !== undefined) {
      const role = getRole === "admin" ? "hr" : "supervisor";
      api.leave.approve(id, "approved", role, comments[0]).then((data) => {
        navigate!(routes.myApprovals);
      });
    }
  };

  useEffect(() => {
    if (id) {
      fetchById(id);
    }
  }, [id]);
  console.log(data)

  return (
    <Form
      defaults={{
        page: "pending",
        username: name[0],
        type: type[0],
        typeName: typeName[0],
        start: start[0],
        end: end[0],
        duration: duration[0].toString(),
        reason: reason[0],
        comments: comments[0],
        deductOn: deductOn[0],
        proof: proof[0],
        handleModify,
        handleReject,
        setComment: comments[1],
      }}
      readOnly
    />
  );
};
