import { format, parseISO } from "date-fns";

import axios from "./axios";
import backend from "config/backend";
import { formatDate } from "config/common";
import type Corporate from "types/corporate";

/**
 * Mapping
 */
type ServerSchema = {
  _id: string;
  uId: string;
  corpName: string;
  alias: string[];
  uen: string;
  dateOfIncorporation: string;
  contactNo: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  address: string[];
  ownership: string;
  profile: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

function mapToServer(corp: Corporate): Partial<ServerSchema> {
  return {
    corpName: corp.name,
    alias: corp.alias,
    dateOfIncorporation: format(corp.incorporation, backend.format.date),
    contactNo: corp.contact,
    country: corp.country,
    state: corp.stateRegion,
    city: corp.city,
    postalCode: corp.postalCode,
    address: corp.address,
  };
}

function mapToReact(response: ServerSchema): Corporate {
  return {
    name: response.corpName,
    alias: response.alias,
    incorporation: formatDate(response.dateOfIncorporation),
    contact: response.contactNo,
    country: response.country,
    stateRegion: response.state,
    city: response.city,
    postalCode: response.postalCode,
    address: response.address,
  };
}

/**
 * API Implementation
 */
const queryKey = "corporate";

async function get() {
  const response = await axios.get<ServerSchema>(backend.corporate.get);

  return mapToReact(response.data);
}

async function update(corp: Corporate) {
  const request = mapToServer(corp);

  const response = await axios.put<ServerSchema>(
    backend.corporate.update,
    request
  );
  return response.data;
}

export default {
  queryKey,
  get,
  update,
};
