import React from 'react';
import styled from 'styled-components/macro';

import locale from 'locale';
import PrunableCheckbox from 'components/common/PrunableCheckbox';

import SearchInput from 'components/common/SearchInput';
import EditPen, { Wrapper as _EditPen } from 'components/svg/EditPen';

const Wrapper = styled.header`
  margin-bottom: 10rem;

  display: flex;
  justify-content: space-between;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;

  ${_EditPen} {
    height: 20rem;
    cursor: pointer;
  }
`;

export default ({ onPenClick, search, setSearch }: {
  onPenClick: () => void,
  search: string,
  setSearch: (s: string) => void
}) => {
  return (
    <Wrapper>
      <Container>
        <h2>
          {locale.en.adjustLeave.leaveType}
        </h2>

        {/* <PrunableCheckbox
          options={data.type}
          max={10}
          actives={[]}
        /> */}
      </Container>


      <Container>
        <SearchInput value={search} onChange={setSearch} />

        <EditPen onClick={onPenClick} />
      </Container>
    </Wrapper>
  );
}