import React, { useState } from 'react';

import locale from 'locale';
import { Section, Title, Fields } from '../styled';
import { Setter } from '../useForm';

import Label from 'components/lib/Label';
import Input from 'components/lib/Input';
import AddressInputs from 'components/common/AddressInputs';
import RegionInputs from 'components/common/RegionInputs';

export default ({ country, stateRegion, city, postalCode, address, setForm }: {
  country: string,
  stateRegion: string,
  city: string,
  postalCode: string,
  address: string[],
  setForm: Setter
}) => {

  const handleRegionChange = (country: string, state: string, city: string) => {
    setForm({
      country,
      stateRegion: state,
      city
    });
  }

  return (
    <Section>
      <Title>
        {locale.en.users.form.contact.title}
      </Title>

      <Fields>
        <RegionInputs
          country={country}
          stateRegion={stateRegion}
          city={city}
          onChange={handleRegionChange}
        />

        <Label title={locale.en.users.form.contact.postalCode}>
          <Input
            value={postalCode}
            onChange={s => setForm({ postalCode: s })}
          />
        </Label>

        <AddressInputs
          value={address}
          onChange={v => setForm({ address: v })}
        />
      </Fields>

    </Section>
  );
}