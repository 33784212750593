import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import locale from "locale";
import api from "api";
import routes from "config/routes";

import Form from "../Form";
import useNavStore from "stores/useNavStore";
import useFormState from "../Form/useFormState";

export default () => {
  /**
   * Hooks
   */
  const { id } = useParams<{ id: string }>();

  const nativeNavigate = useNavigate();
  const navigate = useNavStore((state) => state.navigate);

  const { name, status, setForm } = useFormState();

  const { data, error } = useQuery({
    queryKey: [api.leaveType.queryKey, id],
    queryFn: () => api.leaveType.getById(id!),
    enabled: !!id && !!navigate,
  });

  const queryClient = useQueryClient();

  const mutation = useMutation(api.leaveType.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.leaveType.queryKey);
      queryClient.invalidateQueries([api.leaveType.queryKey, id]);

      const message = locale.en.toastMessages.updateSuccess.replace(
        "{{ 1 }}",
        "LeaveType"
      );
      toast.success(message);

      navigate!(routes.settings.routes!.leaveType);
    },
  });

  useEffect(() => {
    if (data) {
      setForm("name", data.name);
      setForm("status", data.status);
    }
  }, [data]);

  useEffect(() => {
    if (error && navigate) {
      const route = routes.error;
      navigate(route);
    }
  }, [error, navigate]);

  /**
   * Not hook
   */
  const handleSubmit = () => {
    if (!navigate) return;

    id && mutation.mutate({ id, name, status, deleteAble: false });
  };

  const handleCancel = () => {
    nativeNavigate(-1);
  };

  /**
   * Render
   */
  return (
    <Form
      leaveType={{ name, status }}
      setForm={setForm}
      okText={locale.en.common.button.update}
      onOk={handleSubmit}
      cancelText={locale.en.common.button.cancel}
      onCancel={handleCancel}
    />
  );
};
