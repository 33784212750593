import { parseISO  } from 'date-fns';
import { utcToZonedTime  } from "date-fns-tz";

export function formatDate(date: string): Date {
  const parsedDate = parseISO(date);
  const singaporeTimeZone = 'Asia/Singapore';
  const formattedDate = utcToZonedTime(parsedDate, singaporeTimeZone);

  return formattedDate;
}
