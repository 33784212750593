import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Login from "../../../api/auth";
import { Dispatch } from "redux";
import authService from "./authService";
import User from "../../../types/auth";
import api from "api";
import { toast } from "react-toastify";

// get user from LC

const initialState = {
  user: {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    verifyToken: (state, action) => {
      state.user = action.payload;
    },
    logoutAction: (state) => {
      state.user = {};
    },
  },
});

// export const loginAsync =
//   (email: string, password: string) => async (dispatch: Dispatch) => {
//     try {
//       // console.log(data);
//       const response = await Login.login(email, password);
//       // console.log(response);
//       dispatch(login(response));
//     } catch (err: any) {
//       throw new Error(err);
//     }
//   };

export const { login, verifyToken, logoutAction } = authSlice.actions;

export default authSlice.reducer;

export const loginAsync = createAsyncThunk(
  "auth/login",
  async (user: any, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(login());
    // console.log("lenovo", user);

    try {
      api.auth.login(user?.email, user?.password).then((data) => {
        user.setUser({
          ...user.user,
          organizations: data.organizations.map((item) => {
            return {
              id: item.corpId,
              name: item.corpName,
            };
          }),
          bearerToken: data.token,
        });
        user.setAuth({ ...user.authState, tokenUser: data.token });
        thunkAPI.dispatch(login(data));
      });
      // const data = await response.json();
      // Set the data
      // console.log(response.statusCode);
      // if (response?.statusCode == "Invalid Password!") {
      //   return toast.error("Invalid Password!");
      // }
    } catch (error) {
      // Set any erros while trying to fetch
      // thunkAPI.dispatch(getReposFailure());
    }
  }
);

export const verifyTokenAsync = createAsyncThunk(
  "auth/verifyToken",
  async (user: any, thunkAPI: any) => {
    // Set the loading state to true
    // thunkAPI.dispatch(login());

    try {
      const response = await api.auth.verifyToken(user.bearerToken, user.orgId);
      // const data = await response.json();
      // Set the data

      thunkAPI.dispatch(verifyToken(response));
    } catch (error) {
      // Set any erros while trying to fetch
      // thunkAPI.dispatch(getReposFailure());
    }
  }
);
export const accessOrg = createAsyncThunk(
  "auth/accessOrg",
  async (userObj: any, thunkAPI) => {
    try {
      // const response = await api.auth.accessOrg();
      // console.log(response);
    } catch (error) {}
  }
);
