import React, { useMemo } from "react";
import { useQuery } from "react-query";

import locale from "locale";
import api from "api";
import type { Status, Supervisor } from "types/user";
import type { Setter } from "../useForm";
import data from "pages/Users/data";

import { Section, Title, Fields } from "../styled";
import Label from "components/lib/Label";
import Input from "components/lib/Input";
import { DropdownList } from "react-widgets/cjs";
import Multiselect from "components/common/Multiselect";
import DatePicker from "components/common/DatePicker";
import useUserStore from "stores/useUserStore";

export default ({
  firstName,
  lastName,
  userName,
  email,
  contactNo,
  departmentId,
  designation,
  status,
  supervisors,
  nationalId,
  dob,
  setForm,
}: {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  contactNo: string;
  designation: string;
  departmentId: string;
  status: Status;
  supervisors: Supervisor[];
  nationalId: string;
  dob?: Date | null;
  setForm: Setter;
}) => {
  /**
   * Hooks
   */
  const currentRole = useUserStore((state) => state.role);
  const { data: deptData } = useQuery({
    queryKey: [api.department.queryKey, "active"],
    queryFn: () => api.department.list("active"),
  });

  const { data: userData } = useQuery({
    queryKey: api.user.queryKey,
    queryFn: api.user.list,
  });

  /**
   * Not hook
   */
  const potentialSupervisors = useMemo(() => {
    if (!userData) return [];

    const list: Supervisor[] = userData.map((u) => ({
      id: u.id,
      firstName: u.firstName,
      lastName: u.lastName,
    }));

    return list;
  }, [userData]);

  const re = /^[a-zA-Z ]*$/;

  /**
   * Render
   */
  return (
    <Section>
      <Title>{locale.en.users.form.general.title}</Title>

      <Fields>
        <Label title={locale.en.users.form.general.firstName}>
          <Input
            value={firstName}
            onChange={(s) => {
              if (s === "" || re.test(s)) {
                setForm({ firstName: s });
              }
            }}
          />
        </Label>

        <Label title={locale.en.users.form.general.lastName}>
          <Input
            value={lastName}
            onChange={(s) => {
              if (s === "" || re.test(s)) {
                setForm({ lastName: s });
              }
            }}
          />
        </Label>
        <Label title={locale.en.users.form.general.userName}>
          <Input value={userName} onChange={(s) => setForm({ userName: s })} />
        </Label>

        <Label title={locale.en.users.form.general.email}>
          <Input value={email} onChange={(s) => setForm({ email: s })} />
        </Label>

        <Label title={locale.en.users.form.general.contactNo}>
          <Input
            value={contactNo}
            onChange={(s) => {
              const re = /^[0-9\b]+$/;
              if (s === "" || re.test(s)) {
                setForm({ contactNo: s });
              }
            }}
          />
        </Label>

        <Label title={locale.en.users.form.general.nationalId}>
          <Input
            value={nationalId}
            onChange={(s) => setForm({ nationalId: s })}
          />
        </Label>

        <Label title={locale.en.users.form.general.dob}>
          <DatePicker
            value={dob}
            onChange={(d) => {
              if (new Date() > d) {
                setForm({ dob: d });
              }
            }}
          />
        </Label>

        <Label title={locale.en.users.form.general.department}>
          <DropdownList
            data={deptData ?? []}
            dataKey="uId"
            textField="name"
            value={deptData ? deptData.find((i) => i.id === departmentId) : ""}
            onChange={(d) => {
              d.id && setForm({ departmentId: d.id });
            }}
            readOnly={currentRole === "user"}
            style={{
              opacity: currentRole === "user" ? 0.7 : 1,
            }}
          />
        </Label>

        <Label title={locale.en.users.form.general.designation}>
          <Input
            value={designation}
            onChange={(s) => {
              if (s === "" || re.test(s)) {
                setForm({ designation: s });
              }
            }}
            readOnly={currentRole === "user"}
          />
        </Label>

        <Label title={locale.en.users.form.general.reportTo}>
          <Multiselect<Supervisor>
            data={potentialSupervisors}
            value={supervisors}
            dataKey="id"
            textField="firstName"
            onChange={(v) => setForm({ supervisors: v })}
            bgColor="var(--primary-color)"
            color="white"
            readonly={currentRole === "user"}
          />
        </Label>

        <Label title={locale.en.users.form.general.status}>
          <DropdownList
            data={data.status}
            textField="display"
            dataKey="value"
            value={
              data.status.find((s) => s.value === status)?.display ??
              locale.en.common.select.placeholder
            }
            onChange={(v) => setForm({ status: v.value })}
            readOnly={currentRole === "user"}
          />
        </Label>
      </Fields>
    </Section>
  );
};
