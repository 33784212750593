import React from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import locale from "locale";
import api from "api";
import routes from "config/routes";
import useFormState from "../Form/useFormState";
import useNavStore from "stores/useNavStore";
import Form from "../Form";

export default () => {
  /**
   * Hooks
   */
  const { name, status, setForm } = useFormState();
  const navigate = useNavStore((state) => state.navigate);

  const queryClient = useQueryClient();

  const mutation = useMutation(api.leaveType.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.leaveType.queryKey);

      const message = locale.en.toastMessages.createSuccess.replace(
        "{{ 1 }}",
        "LeaveType"
      );
      toast.success(message);

      resetForm();
      navigate!(routes.settings.routes!.leaveType);
    },
  });

  /**
   * Not hook
   */
  const handleSubmit = () => {
    if (!navigate) return;
    if (!name) {
      return toast.error("Name is required!");
    }

    mutation.mutate({ name, status, deleteAble: false });
  };

  const resetForm = () => {
    setForm("name", "");
    setForm("status", "");
  };

  /**
   * Render
   */
  return (
    <Form
      leaveType={{ name, status }}
      setForm={setForm}
      okText={locale.en.common.button.create}
      onOk={handleSubmit}
    />
  );
};
