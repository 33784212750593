import type { Day } from 'types/workday';

type DayOption = {
  id: Day,
  display: string
}

const days: DayOption[] = [
  { id: "Monday", display: "Monday" },
  { id: "Tuesday", display: "Tuesday" },
  { id: "Wednesday", display: "Wednesday" },
  { id: "Thursday", display: "Thursday" },
  { id: "Friday", display: "Friday" },
  { id: "Saturday", display: "Saturday" },
  { id: "Sunday", display: "Sunday" },
]

export default days;