import React from 'react';
import styled from 'styled-components/macro';

import Checkbox from 'components/lib/Checkbox';

export const Wrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 5rem;
`;

export const Entry = styled.div(({ $disabled }: { $disabled: boolean }) => `
  display: inline-flex;
  gap: 10rem;
  align-items: center;
  cursor: pointer;

  ${$disabled ? `
  color: #ccc;
  cursor: not-allowed;
  ` : ''}
`);

export default <T extends { [k: string]: any }>({ data, value, indexFor, disabled, onChange }: {
  data: T[],
  indexFor: { display: keyof T, value: keyof T },
  value: string[],
  disabled?: (row: T) => boolean
  onChange?: (v: string[]) => void
}) => {

  const handleClick = (v: string, disable: boolean) => {
    if (!onChange || disable) return;

    const clone = [...value];

    if (value.includes(v)) {
      const index = value.indexOf(v);
      clone.splice(index, 1);
    }
    else {
      clone.push(v);
    }

    onChange(clone);
  }


  return (
    <Wrapper>
      {data.map((d, i) => {
        const isDisable = disabled ? disabled(d) : false;

        return (
          <Entry
            key={i}
            onClick={() => handleClick(d[indexFor.value], isDisable)}
            $disabled={isDisable}
          >
            <Checkbox
              active={value.includes(d[indexFor.value])}
              activeColor="var(--primary-color)"
              disabled={isDisable}
            />
            {d[indexFor.display]}
          </Entry>
        )
      })}
    </Wrapper>
  );
}