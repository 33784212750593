import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  display: block;
  aspect-ratio: 28/29;
  height: auto;
  width: auto;
`;

export default () => {
  return (
    <Wrapper width={28} height={29} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="var(--primary-color)" d="M22.1673 6.33594H21.0007V4.0026C21.0007 3.69318 20.8777 3.39644 20.6589 3.17765C20.4401 2.95885 20.1434 2.83594 19.834 2.83594C19.5246 2.83594 19.2278 2.95885 19.009 3.17765C18.7902 3.39644 18.6673 3.69318 18.6673 4.0026V6.33594H9.33398V4.0026C9.33398 3.69318 9.21107 3.39644 8.99228 3.17765C8.77348 2.95885 8.47674 2.83594 8.16732 2.83594C7.8579 2.83594 7.56115 2.95885 7.34236 3.17765C7.12357 3.39644 7.00065 3.69318 7.00065 4.0026V6.33594H5.83398C4.90573 6.33594 4.01549 6.70469 3.35911 7.36106C2.70273 8.01744 2.33398 8.90768 2.33398 9.83594V11.0026H25.6673V9.83594C25.6673 8.90768 25.2986 8.01744 24.6422 7.36106C23.9858 6.70469 23.0956 6.33594 22.1673 6.33594Z" />
      <path fill="var(--primary-color)" d="M2.33398 22.6693C2.33398 23.5975 2.70273 24.4878 3.35911 25.1441C4.01549 25.8005 4.90573 26.1693 5.83398 26.1693H22.1673C23.0956 26.1693 23.9858 25.8005 24.6422 25.1441C25.2986 24.4878 25.6673 23.5975 25.6673 22.6693V13.3359H2.33398V22.6693Z" />
    </Wrapper>
  );
}