import { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { differenceInDays } from 'date-fns';

import locale from 'locale';
import routes from 'config/routes';
import type Holiday from 'types/holiday';
import type { Column } from 'components/lib/Table/types';
import useNavStore from 'stores/useNavStore';
import { fillRouteParam, getLeaveDuration } from 'utils';

import DateTime from 'components/lib/Table/plugins/DateTime';
import Button from 'components/lib/Button';
import EditPen, { Wrapper as _EditPen } from 'components/svg/EditPen';
import Trash, { Wrapper as _Trash } from 'components/svg/Trash';

const ActionCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 13rem;

  ${_EditPen}, ${_Trash} {
    height: 22rem;
    cursor: pointer;
  }
`;

export default (openDeleteModal: (h: Holiday) => void) => {
  const navigate = useNavStore(state => state.navigate);

  const [columns, setColumns] = useState<Column<Holiday>[]>([]);

  const handleNewClick = () => {
    const route = routes.settings.routes!.holiday!.routes!.new;

    navigate && navigate(route);
  }

  const handleEditClick = (r: Holiday) => {
    const route = routes.settings.routes!.holiday!.routes!.edit;

    if (navigate && route.render && r.id) {
      const url = fillRouteParam(
        route.render.url,
        [{ param: 'id', value: r.id }]
      );

      navigate(route, url);
    }
  }

  useEffect(() => {
    const columns: Column<Holiday>[] = [
      {
        title: locale.en.settings.holiday.table.event,
        render: r => r.name
      },
      {
        title: locale.en.settings.holiday.table.start,
        render: r => <DateTime date={r.start} />
      },
      {
        title: locale.en.settings.holiday.table.end,
        render: r => <DateTime date={r.end} />
      },
      {
        title: locale.en.settings.holiday.table.duration,
        render: r => {
          const diff = differenceInDays(r.end, r.start);
          return getLeaveDuration(diff >= 0 ? diff + 1 : diff);
        }
      },
      {
        title: locale.en.settings.holiday.table.description,
        render: r => r.description
      },
      {
        title: (
          <Button.Ghost onClick={handleNewClick}>
            {locale.en.common.button.new}
          </Button.Ghost>
        ),
        width: "100rem",
        style: { justifyContent: "end" },
        render: r => (
          <ActionCell>
            <EditPen onClick={() => handleEditClick(r)} />
            <Trash onClick={() => openDeleteModal(r)} />
          </ActionCell>
        )
      }
    ];

    setColumns(columns);
  }, []);

  return columns;
}