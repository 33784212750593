import React from "react";
import { addDays } from "date-fns";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import locale from "locale";
import api from "api";
import routes from "config/routes";
import useFormState from "../Form/useForm";
import useNavStore from "stores/useNavStore";

import Form from "../Form";

export default () => {
  /**
   * Hooks
   */
  const navigate = useNavStore((state) => state.navigate);
  const { form, dayDiff, setForm } = useFormState();

  const queryClient = useQueryClient();

  const mutation = useMutation(api.holiday.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.holiday.queryKey);

      const message = locale.en.toastMessages.createSuccess.replace(
        "{{ 1 }}",
        "Holiday"
      );
      toast.success(message);

      navigate!(routes.settings.routes!.holiday);
    },
  });

  /**
   * Not hook
   */
  const handleReset = () => {
    const today = new Date();

    setForm({
      name: "",
      start: today,
      end: today,
      description: "",
    });
  };

  const handleOk = () => {
    if (!navigate || !form.start || !form.end) return;
    if (form.name === "") {
      return toast.error("Name is required!");
    }

    mutation.mutate({ ...form });
  };

  /**
   * Render
   */
  return (
    <Form
      holiday={form}
      dayDiff={dayDiff}
      setForm={setForm}
      okText={locale.en.common.button.create}
      onOk={handleOk}
      cancelText={locale.en.common.button.reset}
      onCancel={handleReset}
    />
  );
};
