import React from 'react';
import styled from 'styled-components/macro';

import useAnimation from './useAnimation';

const Wrapper = styled.div`
  position: relative;
`;

const Box = styled.div`
  position: absolute;
  z-index: 50;
  bottom: calc(100% + 6rem);
  left: 50%;
  transform: translate(-50%);

  background: #2F2F2F;
  padding: 6rem 8rem;
  font-size: 10rem;
  color: white;
  border-radius: 7rem;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    border: 5rem solid transparent;

    border-top-color: black;
    border-left-width: 3rem;
    border-right-width: 3rem;
  }
`;

export default ({ text, children }: {
  text: string,
  children: JSX.Element
}) => {

  const ref = useAnimation();

  return (
    <Wrapper>
      <Box ref={ref.popup}>{text}</Box>

      <span ref={ref.hover}>
        {children}
      </span>
    </Wrapper>
  );
}