import api from "api";
import React, { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components/macro";

import mock from "../../mock-data";
import type { Employee } from "../../type";

import Table from "./Table";
import useColumns from "./useColumns";
import type { Option } from "components/common/PrunableCheckbox/types";
import { generateRegex } from "components/common/SearchInput/utils";
import { useSelector } from "react-redux";
import type LeaveType from 'types/leaveType';

const Wrapper = styled.div``;

export default ({
  date,
  // showDept,
  search,
  dataLeaveType,
}: {
  date: Date;
  // showDept: string[];
  search: string;
  dataLeaveType: LeaveType[];
}) => {
  const { leaveHistory } = useSelector((state: any) => state.leave);
  const columns = useColumns(date, leaveHistory);

  const { data } = useQuery({
    queryKey: api.user.queryKey,
    queryFn: () => api.user.list(),
  });

  // let filtered = data?.filter((item) => showDept.includes(item.departmentId));
  // let copyArray: any = [];

  // const searchRegex = generateRegex(search);

  // const filteredData = data
  //   ?.filter((d) => {
  //     const matchDept =
  //       showDept.includes(d.departmentId) || d.departmentId === "";
  //     const matchSearch = `${d.firstName}`.match(searchRegex);

  //     return matchDept && matchSearch;
  //   })
  //   .map((item) => {
  //     copyArray.push({ id: item.id, name: item.firstName });
  //   });

  // console.log("copyarray", copyArray);
  let copyArray: any = [{ id: "sldflsd", name: "Ali" }];

  return (
    <Wrapper>
      <Table data={copyArray} columns={columns} dataLeaveType={dataLeaveType} date={date} />
    </Wrapper>
  );
};
