import { format, parse } from "date-fns";

import axios from "./axios";
import backend from "config/backend";
import type WorkDay from "types/workday";

/**
 * Mapping
 */
type Day =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

type ServerSchema = {
  _id: string;
  organizationId: string;
  days: Array<{
    name: Day;
    startTime: string;
    endTime: string;
    _id?: string;
  }>;
  createdAt: string;
  updatedAt: string;
};

function mapToServer(workday: WorkDay[]): Partial<ServerSchema> {
  const data: Array<{
    name: Day;
    startTime: string;
    endTime: string;
  }> = [];

  workday.forEach((wd) => {
    data.push({
      name: wd.type,
      startTime: format(wd.start, backend.format.time),
      endTime: format(wd.end, backend.format.time),
    });
  });

  return { days: data };
}

function mapToReact(response: ServerSchema): WorkDay[] {
  const data: WorkDay[] = [];

  const now = new Date();

  response.days.forEach((item) => {
    data.push({
      id: item._id || "",
      type: item.name,
      start: parse(item.startTime, backend.format.time, now),
      end: parse(item.endTime, backend.format.time, now),
    });
  });

  return data;
}

/**
 * API Implementation
 */
const queryKey = "workDay";

async function get() {
  const response = await axios.get<{ data: ServerSchema }>(backend.workday.get);

  return mapToReact(response.data.data);
}

async function update(workDays: WorkDay[]) {
  const request = mapToServer(workDays);
  await axios.put<{ data: ServerSchema }>(backend.workday.update, request);
}

export default {
  queryKey,
  get,
  update,
};
