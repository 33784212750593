import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import locale from "locale";
import routes from "config/routes";
import type { User } from "pages/PendingMod/types";

import Table, {
  Header as _Table__Header,
  Cell as _Table__Cell,
} from "components/lib/Table";
import useColumns from "./useColumns";

import mock from "../../mock-data";
import api from "api";
import Leave from "types/leave";
import { fetchLeaves } from "redux/features/leave/leaveSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useUserStore from "stores/useUserStore";
import useAuthStore from "stores/useAuthStore";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40rem;
`;

const Container = styled.div`
  background: var(--container-bg-color);
  padding: var(--container-padding);
  border-radius: var(--container-border-radius);

  ${_Table__Header}:last-of-type,
  ${_Table__Cell}:last-of-type {
    padding: 1rem;
    width: 3%;
  }
`;

const Title = styled.h2`
  margin-bottom: 10rem;
`;

export default () => {
  const [state, setState] = useState<Leave[]>([]);
  const dispatch = useDispatch<any>();
  const userRole = useUserStore((state) => state.role);
  const uId = useAuthStore.getState().uId;
  // const { leavePending } = useSelector((state: any) => state.leave);
  let pending = state.filter((leave) => leave.hr.status == "pending");
  let history = state.filter((leave) => leave.hr.status !== "pending");
  // let copyArray: any;
  let pendingCopy = pending.map((item: any) => ({
    id: item.id,
    username: item?.userId?.userName,
    // supervisors: item?.supervisor?.name,
    supervisors: item?.approvers?.map((x: any) => `${x?.firstName} ${x?.lastName}`) ?? [],
    start: item?.start,
    end: item?.end,
    type: item?.type,
    typeName: item?.typeName,
    duration: item?.duration,
    reason: item?.leaveReason,
    comments: item?.approverComment,
    thumbnail: item?.userId.profile,
    status: item.hr.status,
  }));

  let historyCopy = history?.map((item: any) => ({
    id: item.id,
    username: item?.userId?.userName,
    // supervisors: item?.supervisor?.name,
    supervisors: item?.approvers?.map((x: any) => `${x?.firstName} ${x?.lastName}`) ?? [],
    start: item?.start,
    end: item?.end,
    type: item?.type,
    typeName: item?.typeName,
    duration: item?.duration,
    reason: item?.leaveReason,
    comments: item?.approverComment,
    thumbnail: item?.userId.profile,
    status: item.hr.status,
  }));

  const columns = {
    pending: useColumns(routes.pendingMod.routes!.pending),
    history: useColumns(routes.pendingMod.routes!.history),
  };

  // useEffect(() => {
  //   api.leave.list().then((data) => {
  //     setState(data);
  //   });
  // }, []);
  useEffect(() => {
    dispatch(fetchLeaves({ setState, role: { userRole, uId } }));
  }, []);

  return (
    <Wrapper>
      <Container>
        <Title>{locale.en.pendingMod.pendingMod}</Title>
        <Table<User>
          data={pendingCopy !== undefined ? pendingCopy : []}
          columns={columns.pending}
          pageSize={5}
        />
      </Container>

      <Container>
        <Title>{locale.en.pendingMod.historyMod}</Title>
        <Table<User>
          data={historyCopy !== undefined ? historyCopy : []}
          columns={columns.history}
          pageSize={5}
        />
      </Container>
    </Wrapper>
  );
};
