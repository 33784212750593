import React, { useEffect } from "react";
import styled from "styled-components/macro";

import type { Employee } from "../../type";
import { Column } from "components/lib/Table/types";

import Table, { Cell as _Cell, Header as _Header } from "components/lib/Table";
import { useSelector } from "react-redux";
import type LeaveType from 'types/leaveType';

const Wrapper = styled.div`
  --cell-padding: 6rem 0;

  ${_Header} {
    padding: var(--cell-padding);
    text-align: center;
  }

  ${_Cell} {
    padding: var(--cell-padding);
    min-width: 20rem;
    text-align: center;
    font-size: 11rem;
    color: #999;

    border-top: none;
  }

  & ${_Cell}:first-of-type {
    text-align: left;
    padding-right: 5rem;
    width: unset;
    color: black;
  }

  .table > div {
    margin-right: 0px !important;
  }
`;

export default ({
  data,
  columns,
  date,
  dataLeaveType,
}: {
  data: any[];
  columns: Column<Employee>[];
  date: Date;
  dataLeaveType: LeaveType[];
}) => {
  const { chartLeaves } = useSelector((state: any) => state.leave);
  const combinePermissions = () => {
    let combinedLeaves: any = [];
    chartLeaves?.map((e: any) => {
      let check = combinedLeaves.findIndex(
        (dat: any) => dat.id === e.userId.uId
      );

      const typeIndex = dataLeaveType.findIndex((x) => x.id === e.type);

      if (check != -1) {
        let dt = combinedLeaves[check].leaves;
        if (Array.isArray(dt)) {
          dt.push({ start: e.start, end: e.end, type: e.type, typeIndex: typeIndex });
        }
        combinedLeaves[check] = {
          id: e.userId.uId,
          name: e.userId.firstName,
          leaves: dt,
        };
      } else {
        combinedLeaves?.push({
          id: e.userId.uId,
          name: e.userId.firstName,
          leaves: [{ start: e.start, end: e.end, type: e.type, typeIndex: typeIndex }],
        });
      }
    });
    
    return combinedLeaves;
  };

  return (
    <Wrapper>
      <Table
        data={combinePermissions()}
        columns={columns}
        pageSize={10}
        date={date}
      />
    </Wrapper>
  );
};
