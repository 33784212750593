import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";

import locale from "locale";
import api from "api";
import routes from "config/routes";
import type Leave from "types/leave";
import useColumns from "./useColumns";
import useNavStore from "stores/useNavStore";

import Table from "components/lib/Table";
import Button, { Wrapper as _Button } from "components/lib/Button";
import useUserStore from "stores/useUserStore";
import { fetchApprovals } from "redux/features/myApproval/myApprovalSlice";
import { useDispatch } from "react-redux";
import { fetchRequest } from "redux/features/myRequest/myRequestSlice";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  padding: var(--container-padding);
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);

  display: flex;
  flex-direction: column;
  gap: 35rem;

  ${_Button} {
    align-self: end;
  }
`;

export default () => {
  /**
   * Hooks
   */
  const dispatch = useDispatch<any>();
  const user = useUserStore((state) => state.user);
  const navigate = useNavStore((state) => state.navigate);
  const { requests } = useSelector((state: any) => state.request);

  const columns = useColumns();
  // console.log(columns);

  /**
   * Not hook
   */
  const handleNew = () => {
    const route = routes.myRequest.routes!.new;
    navigate && navigate(route);
  };

  useEffect(() => {
    dispatch(fetchRequest(user?.id!));
  }, [user?.id, dispatch]);

  /**
   * Render
   */
  return (
    <Wrapper>
      <Table<Leave> data={requests ?? []} columns={columns} pageSize={5} />

      <Button.Classic onClick={handleNew}>
        {locale.en.myRequest.button.new}
      </Button.Classic>
    </Wrapper>
  );
};
