import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 1/1;
  height: auto;
  width: auto;
`;

export default ({ color = "black", onClick }: {
  color?: string,
  onClick?: () => void
}) => {
  return (
    <Wrapper
      width={25} height={25}
      viewBox="0 0 25 25"
      onClick={onClick}
    >
      <path fill={color} d="M12.1479 15.5244C7.13792 15.5244 3.05792 18.8844 3.05792 23.0244C3.05792 23.3044 3.27792 23.5244 3.55792 23.5244H20.7379C21.0179 23.5244 21.2379 23.3044 21.2379 23.0244C21.2379 18.8844 17.1579 15.5244 12.1479 15.5244Z" />
      <path fill={color} d="M15.8579 4.18441C14.9579 3.16441 13.6179 2.52441 12.1479 2.52441C10.7479 2.52441 9.46795 3.09441 8.55795 4.03441C7.68795 4.93441 7.14795 6.17441 7.14795 7.52441C7.14795 8.46441 7.40795 9.34441 7.87795 10.0944C8.12795 10.5244 8.44795 10.9144 8.82795 11.2344C9.69795 12.0344 10.8579 12.5244 12.1479 12.5244C13.9779 12.5244 15.558 11.5444 16.4279 10.0944C16.688 9.66441 16.8879 9.18441 16.9979 8.68441C17.0979 8.31441 17.1479 7.92441 17.1479 7.52441C17.1479 6.24441 16.6579 5.07441 15.8579 4.18441ZM14.0179 8.44441H13.0879V9.41441C13.0879 9.93441 12.6679 10.3544 12.1479 10.3544C11.6279 10.3544 11.2079 9.93441 11.2079 9.41441V8.44441H10.2779C9.75795 8.44441 9.33795 8.02441 9.33795 7.50441C9.33795 6.98441 9.75795 6.56441 10.2779 6.56441H11.2079V5.67441C11.2079 5.15441 11.6279 4.73441 12.1479 4.73441C12.6679 4.73441 13.0879 5.15441 13.0879 5.67441V6.56441H14.0179C14.5379 6.56441 14.9579 6.98441 14.9579 7.50441C14.9579 8.02441 14.5379 8.44441 14.0179 8.44441Z" />
    </Wrapper>
  );
}