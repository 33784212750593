import { Type } from "types/leave";

export const leaveType: Array<{ id: Type, name: string }> = [
  {
    id: "annual",
    name: "Annual Leave"
  },
  {
    id: "sick",
    name: "Sick Leave"
  }
];

export const deductType: Array<{ id: string, name: string }> = [
  {
    id: "annual",
    name: "Annual"
  },
  {
    id: "additional",
    name: "Additional"
  }
];