import { format  } from 'date-fns';

import axios from './axios';
import backend from 'config/backend';
import { formatDate } from "config/common";
import type Holiday from 'types/holiday';

/**
 * Mapping
 */
type ServerSchema = {
  organizationId: string,
  eventName: string,
  description: string,
  startDate: string,
  endDate: string,
  _id: string,
  createdAt: string,
  updatedAt: string
}

function mapToServer(holiday: Holiday): Partial<ServerSchema> {
  return {
    _id: holiday.id,
    eventName: holiday.name,
    description: holiday.description,
    startDate: format(holiday.start, backend.format.date),
    endDate: format(holiday.end, backend.format.date)
  };
}

function mapToReact(response: ServerSchema): Holiday {
  const start = formatDate(response.startDate);
  const end = formatDate(response.endDate);
  
  return {
    id: response._id,
    name: response.eventName,
    description: response.description,
    start,
    end
  }
}

/**
 * API Implementation
 */
//For react-query
const queryKey = "holiday";

//list
async function list(year: number) {
  const response = await axios.get<{ data: ServerSchema[] }>(backend.holiday.list, {
    params: { year }
  });

  const data: Holiday[] = [];

  response.data.data.forEach((item) => {
    data.push(mapToReact(item));
  });

  return data;
}

//getById
async function getById(_id: string) {
  const url = backend.holiday.getById.replace("{{ 1 }}", _id);

  const response = await axios.get<{ data: ServerSchema }>(url);
  return mapToReact(response.data.data);
}

//create
async function create(holiday: Holiday) {
  const request = mapToServer(holiday);
  delete request._id;

  const response = await axios.post<{ data: ServerSchema }>(backend.holiday.create, request);
  return mapToReact(response.data.data);
}

//update
async function update(holiday: Required<Holiday>) {
  const url = backend.holiday.update.replace("{{ 1 }}", holiday.id);
  const request = mapToServer(holiday);

  const response = await axios.put<{ data: ServerSchema }>(url, request);
  return mapToReact(response.data.data);
}

//delete is a reserved keyword in js, used destroy instead
async function destroy(_id: string) {
  const url = backend.holiday.delete.replace("{{ 1 }}", _id);

  await axios.delete<{ data: "success" }>(url);
  return true;
}

export default {
  queryKey,
  list,
  getById,
  create,
  update,
  delete: destroy
}