import React from "react";
import { useQueryClient, useMutation } from "react-query";
import { toast } from "react-toastify";

import locale from "locale";
import api from "api";
import useForm from "../Form/useForm";
import useNavStore from "stores/useNavStore";

import Form from "../Form";
import routes from "config/routes";

export default () => {
  /**
   * Hooks
   */
  const navigate = useNavStore((state) => state.navigate);

  const { form, setForm } = useForm();

  const queryClient = useQueryClient();

  const mutation = useMutation(api.user.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.user.queryKey);

      const message = locale.en.toastMessages.createSuccess.replace(
        "{{ 1 }}",
        "User profile"
      );
      toast.success(message);

      navigate!(routes.users);
    },
  });

  const validateEmail = (email: any) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  /**
   * Not hook
   */
  const handleCreate = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!form.userName) {
      return toast.error("username is required");
    }
    if (!form.email) {
      return toast.error("email is required");
    } else if (!validateEmail(form.email)) {
      return toast.error("please enter valid email address!");
    }

    if (!navigate) return;

    mutation.mutate({ ...form });
  };

  /**
   * Render
   */
  return (
    <Form
      {...form}
      setForm={setForm}
      okText={locale.en.common.button.confirm}
      onOk={handleCreate}
    />
  );
};
