const configValue: string = process.env.REACT_APP_LEAVE_API_URL as string;

export default {
  url: configValue,

  staleTime: {
    general: 120 * 1000, //2min
    geolocation: 1800 * 1000, //30min
  },

  format: {
    //https://date-fns.org/v2.28.0/docs/format
    time: "KK:mm aa",
    date: "MM/dd/yyyy",
    dateTime: "MM/dd/yyyy KK:mm aa",
  },

  auth: {
    login: "api/auth/login",
    verifyToken: "api/auth/verifyToken",
    accessOrg: "api/auth/access/organization",
    register: "api/auth/register",
  },

  department: {
    list: "api/department",
    getById: "api/department/{{ 1 }}",
    create: "api/department",
    update: "api/department/{{ 1 }}",
    delete: "api/department/{{ 1 }}",
  },

  leaveType: {
    list: "api/leavetype",
    getById: "api/leavetype/{{ 1 }}",
    create: "api/leavetype",
    update: "api/leavetype/{{ 1 }}",
    delete: "api/leavetype/soft/delete/{{ 1 }}",
  },

  holiday: {
    list: "api/holiday",
    getById: "api/holiday/{{ 1 }}",
    create: "api/holiday",
    update: "api/holiday/{{ 1 }}",
    delete: "api/holiday/{{ 1 }}",
  },

  workday: {
    get: "api/workday",
    update: "api/workday",
  },

  user: {
    list: "api/user",
    getById: "api/user/{{ 1 }}",
    update: "api/user/{{ 1 }}",
    create: "api/user",
    revoke: "api/user/revoke/{{1}}",
    app: "api/app",
  },

  corporate: {
    get: "api/corporate",
    update: "api/corporate",
  },

  geolocation: "api/csc",

  leaveBalance: {
    list: "api/leavebalance",
    getByUId: "api/leavebalance/user/{{ 1 }}",
    updates: "api/leavebalance/updates",
  },

  leave: {
    list: "api/leave",
    listChart: "api/leave/emp",
    listChartAdmin: "api/leave",
    getById: "api/leave/{{ 1 }}",
    create: "api/leave",
    update: "api/leave/{{ 1 }}",
    delete: "api/leave/{{ 1 }}",
  },

  leaveSetting: {
    get: "api/leavesetting",
    update: "api/leavesetting",
  },

  audit: {
    list: "api/audit",
    getOwnList: "api/audit/own",
  },
  adjustmentHistory: {
    list: "api/adjustment/history",
    getOwnList: "api/adjustment/history/own",
  }
};
