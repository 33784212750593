import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { Wrapper as Container } from '../lib/Input';
import Tag, { Wrapper as _Tag } from 'components/lib/Tag';

const Wrapper = styled.div(({ $tags }: { $tags: boolean }) => `
  ${Container} {
    padding: ${$tags ? '4.1rem' : '7.1rem'} 6rem;
    flex-grow: 0;
    flex-shrink: 0;
    flex-wrap: wrap;
  }

  ${_Tag} {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 3.7rem 10rem;
    font-size: 12rem;
  }
`);

const Input = styled.input`
  flex-grow: 1;
  width: 50rem; 
  outline: none;
  border: none;
  font-family: var(--font-family);
  background: transparent;

  cursor: inherit;
`;

export default ({
  value,
  onChange,
  readOnly = false
}: {
  value: string[],
  onChange?: (s: string[]) => void,
  readOnly?: boolean
}) => {

  const [focus, setFocus] = useState(false);
  const [input, setInput] = useState("");

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (onChange && e.code === "Enter") {
      onChange([...value, input]);
      setInput("");
    }
  }

  const handleDelete = (index: number) => {
    if (readOnly || !onChange) return;

    const firstHalf = value.slice(0, index);
    const secondHalf = value.slice(index + 1);

    onChange([...firstHalf, ...secondHalf]);
  }

  return (
    <Wrapper $tags={value.length > 0}>
      <Container
        tabIndex={-1}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        $focus={focus}
        $readOnly={readOnly}
      >
        {value.map((v, i) =>
          <Tag
            key={i}
            background="var(--primary-color)"
            color="white"
            onDelete={!readOnly ? () => handleDelete(i) : undefined}
          >
            {v}
          </Tag>
        )}

        <Input
          readOnly={readOnly}
          value={input}
          onChange={e => setInput(e.currentTarget.value)}
          onKeyDown={handleKeyDown}
        />
      </Container>
    </Wrapper>
  )
}