import React from 'react';
import styled from 'styled-components/macro';
import useMeasure from 'react-use-measure';

export const Wrapper = styled.div`
  aspect-ratio: 1/1;
  height: auto;
  width: auto;
  position: relative;
`;

export const Circle = styled.svg`
  aspect-ratio: 1/1;
  height: 100%;
  width: auto;
  display: block;
  transform: rotate(-90deg);
`;

const Fragment = styled.circle(({ $pct, $offset }: { $pct: number, $offset: number }) => `
  stroke-width: 10;
  stroke-dasharray: 440;
  stroke-dashoffset: ${440 - ($pct / 100 * 440)};

  transform-origin: center;
  transform: rotate(${$offset}deg);
`);

const Text = styled.p(({ $fontSize }: { $fontSize: number }) => `
  position: absolute;
  top: 50%;
  lefT: 50%;

  transform: translate(-50%, -50%);

  font-family: var(--font);
  font-weight: 500;
  font-size: ${$fontSize}rem;
`);


export type Line = {
  color: string,
  percentage: number
}

export default ({ lines }: { lines: Line[] }) => {
  const [ref, bounds] = useMeasure();

  let accumulator = 0;
  const totalPct = lines.filter(x => x.color !== "#B9B9B9")?.reduce((prev, current) => prev + current.percentage, 0);

  return (
    <Wrapper ref={ref}>
      <Circle width={150} height={150} viewBox="0 0 150 150" fill="none">
        <Fragment
          cx={75} cy={75}
          r={70}
          stroke="#ccc"
          strokeOpacity={0.3}
          $pct={100}
          $offset={0}
        />

        {lines.map((l, i) => {
          if (i > 0) {
            accumulator += lines[i - 1].percentage;
          }

          return (
            <Fragment
              key={i}
              cx={75} cy={75}
              r={70}
              stroke={l.color}
              $pct={l.percentage}
              $offset={accumulator / 100 * 360}
            />
          );
        })}
      </Circle>

      <Text $fontSize={bounds.width / 5}>
        {isNaN(totalPct) ? 0 : totalPct}%
      </Text>
    </Wrapper>
  );
}