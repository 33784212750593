import React, { useState } from 'react';
import type LeaveType from 'types/leaveType';

export type Setter = <T extends keyof Omit<LeaveType, "id">>(k: T, v: LeaveType[T]) => void

export default () => {
  /**
   * Hooks
   */
  const form: { [T in keyof LeaveType]: [LeaveType[T], React.Dispatch<React.SetStateAction<LeaveType[T]>>] } = {
    name: useState(""),
    status: useState(""),
  }

  /**
   * Not hook
   */
  const setForm = <T extends keyof Omit<LeaveType, "id">>(key: T, value: LeaveType[T]) => {
    form[key][1](value);
  }

  /**
   * Return
   */
  return {
    name: form.name[0],
    status: form.status[0],
    setForm
  }
}