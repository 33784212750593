import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import api from 'api';
import type LeaveType from "types/leaveType";

export default () => {
  const [leaveTypes, setLeaveTypes] = useState<LeaveType[]>([]);

  const { data } = useQuery({
    queryKey: api.leaveType.queryKey,
    queryFn: () => api.leaveType.list(),
  });

  useEffect(() => {
    console.log(data)
    if (data && data.length > 0) {
      setLeaveTypes(data);
    }
  }, [data]);

  return { leaveTypes };
}