import React from "react";
import styled from "styled-components/macro";

import Style from "../styled";

export const Wrapper = styled(Style)`
  background: transparent;
  color: var(--primary-color);

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    background: var(--disabled-color);
    opacity: 1;
  }
`;

export default ({
  children,
  SVGElement,
  onClick,
  disabled = false,
}: {
  children: string;
  SVGElement?: ({ color }: { color?: string }) => JSX.Element;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
}) => {
  return (
    <Wrapper onClick={onClick} disabled={disabled}>
      {children}
      {SVGElement && <SVGElement color="white" />}
    </Wrapper>
  );
};
