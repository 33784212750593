import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

import locale from "locale";
import routes from "config/routes";
import api from "api";
import type Leave from "types/leave";
import useNavStore from "stores/useNavStore";
import useUserStore from "stores/useUserStore";

import Form, { Wrapper as _Form } from "./Form";
import Card, { Wrapper as _Card } from "components/common/Card";

const Wrapper = styled.div`
  position: relative;
  padding: var(--container-padding);

  margin: 0 auto;

  display: flex;
  align-items: start;
  justify-content: center;
  gap: 25rem;

  ${_Form}, ${_Card} {
    width: 100%;
  }
  ${_Form} {
    max-width: var(--form-max-width);
  }
  ${_Card} {
    max-width: 550rem;
  }
`;

export default () => {
  /**
   * Hooks
   */
  const { id } = useParams<{ id: string }>();

  const user = useUserStore((state) => state.user);
  const navigate = useNavStore((state) => state.navigate);

  const [leave, setLeave] = useState<Leave>();
  const [year, setYear] = useState<Date>(new Date());

  const yearNow = year.getFullYear().toString();

  const { data: leaveData, error } = useQuery({
    queryKey: [api.leave.queryKey, user!.id],
    queryFn: () => api.leave.list({ userUid: user!.id }),
    enabled: !!navigate && !!user,
  });

  const { data: leaveBalData } = useQuery({
    queryKey: [api.leaveBalance.queryKey, yearNow, user!.id],
    queryFn: () => api.leaveBalance.list({ userUid: user!.id, year: yearNow }),
    enabled: !!user,
  });

  useEffect(() => {
    if (error && navigate) {
      const route = routes.error;
      navigate(route);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (id) {
      api.leave.getById(id).then(setLeave);
      // setLeave(leaveData.filter((l) => l.id === id)[0]);
    }
  }, [leaveData, id]);

  /**
   * Not hook
   */
  const handleViewClick = () => {
    if (!navigate) return;

    const route = routes.myLeaveBal;
    navigate(route);
  };

  const balance =
    leaveBalData && leaveBalData.length && leaveBalData[0].balance
      ? leaveBalData[0].balance[yearNow]
      : null;

  const consumed = balance?.consumed ?? 0;
  const additionalLeave = balance?.additionalLeave ?? 0;
  const annualEntitlement = balance?.annualEntitlement ?? 0;

  const total = consumed + additionalLeave + annualEntitlement;

  // console.log("leave", leave);

  /**
   * Render
   */
  return (
    <Wrapper>
      {leave && (
        <Form
          start={leave.start}
          end={leave.end}
          duration={leave.duration}
          typeName={leave.typeName}
          deductOn={leave.deductOn}
          leaveReason={leave.leaveReason}
          approverComment={leave.approverComment}
          statusHr={leave.hr.status}
          statusSv={leave.supervisor.status}
          recipient={leave.recipients}
          approver={leave.approvers}
          proof={leave.proof}
        />
      )}

      <Card.Statistic.Leave
        title={locale.en.common.statCard.leaveRecord}
        consumed={consumed}
        remaining={additionalLeave + annualEntitlement}
        leaves={[
          {
            legend: locale.en.myRequest.cardLegend.annLeave,
            color: "#E5429E",
            percentage: balance
              ? Math.floor((annualEntitlement / total) * 100)
              : 0,
          },
          {
            legend: locale.en.myRequest.cardLegend.extraLeave,
            color: "#44C2DE",
            percentage: balance
              ? Math.ceil((additionalLeave / total) * 100)
              : 0,
          },
          {
            legend: locale.en.myRequest.cardLegend.consumed,
            color: "#B9B9B9",
            percentage: balance ? Math.floor((consumed / total) * 100) : 0,
          },
        ]}
        onViewClick={handleViewClick}
        year={year}
        setYear={setYear}
      />
    </Wrapper>
  );
};
