import React, { useState } from "react";
import styled from "styled-components/macro";

import Label from "components/lib/Label";
import Input from "components/lib/Input";
import TextArea from "components/lib/TextArea";
import Button from "components/lib/Button";
import DateRangePicker from "components/common/DateRangePicker";

import Card from "components/common/Card";
import { Wrapper as _Card } from "components/common/Card/styled";
import { getYear } from "date-fns";
import locale from "locale";
import routes from "config/routes";
import useNavStore from "stores/useNavStore";

import DocumentText from "components/svg/DocumentText";

const Wrapper = styled.div`
  --gap: 20rem;

  display: flex;
  align-items: start;
  justify-content: center;
  gap: var(--gap);

  ${_Card} {
    flex-grow: 1;
  }
`;

const Form = styled.div`
  flex-grow: 1;

  padding: var(--container-padding);
  border-radius: var(--container-border-radius);
  background: var(--container-bg-color);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  & > div {
    display: flex;
    gap: var(--gap);
  }

  & span {
    width: 100%;
  }
`;

const ButtonGroup = styled.div`
  align-self: end;
`;

export default ({
  name,
  supervisor,
  start,
  end,
  type,
  typeName,
  duration,
  reason,
  comments,
  deductOn,
  button,
  balance,
  supervisorStatus,
  proof,
}: {
  name: [string, (s: string) => void];
  supervisor: [string, (s: string) => void];
  start: [Date | undefined, (d: Date | undefined) => void];
  end: [Date | undefined, (d: Date | undefined) => void];
  type: [string, (s: string) => void];
  typeName: [string, (s: string) => void];
  duration: [number, (n: number) => void];
  reason: [string, (s: string) => void];
  comments: [string, (s: string) => void];
  deductOn: [string, (s: string) => void];
  button?: {
    onReject: () => void;
    onModify: () => void;
  };
  balance: any;
  supervisorStatus?: string;
  proof: string;
}) => {
  const [year, setYear] = useState(new Date());
  const yearNow = year.getFullYear().toString();
  const navigate = useNavStore((state) => state.navigate);

  const Balance = balance[0]?.balance[yearNow];
  const consumed = Balance?.consumed ?? 0;
  const additionalLeave = Balance?.additionalLeave ?? 0;
  const annualEntitlement = Balance?.annualEntitlement ?? 0;
  const total = consumed + additionalLeave + annualEntitlement;

  const percentage = () => {
    if (balance) {
      let consumed = balance[0]?.balance[`${getYear(year)}`]?.consumed || 0;
      let total =
        balance[0]?.balance[`${getYear(year)}`]?.projectedBalance || 0;
      return (consumed / total) * 100;
    }
  };

  const handleViewClick = () => {
    if (!navigate) return;

    const route = routes.adjustLeave;
    navigate(route);
  };

  console.log("Percent", additionalLeave, annualEntitlement, total)
  return (
    <Wrapper>
      <Form>
        <div>
          <Label title="Username">
            <Input value={name[0]} onChange={name[1]} readOnly />
          </Label>

          <Label title="Supervisor">
            <Input
              value={
                supervisor[0] !== "undefined undefined" ? supervisor[0] : ""
              }
              onChange={supervisor[1]}
              readOnly
            />
          </Label>
        </div>

        <DateRangePicker
          className="row"
          start={{
            value: start[0],
            readOnly: true,
            // onChange: d => start[1]()
          }}
          end={{
            value: end[0],
            readOnly: true,
            // onChange: d => setForm({ end: d })
          }}
          // boolean={true}
        />

        <div>
          <Label title="Type of Leave">
            <Input value={typeName[0]} onChange={typeName[1]} readOnly />
          </Label>

          <Label title="Leave Duration">
            <Input value={`${duration[0]}`} readOnly />
          </Label>
        </div>

        <div>
          <Label title="Deduct Type">
            <Input value={deductOn[0]} onChange={deductOn[1]} readOnly />
          </Label>
          <Label title="Leave Reason">
            <Input value={reason[0]} onChange={reason[1]} readOnly />
          </Label>
        </div>

        <div>
          <Label title="Comments">
            <TextArea value={comments[0]} onChange={comments[1]} readOnly={supervisorStatus !== "pending"}/>
          </Label>
        </div>

        {
          (proof && proof !== "") && (
            <div>
              <Label title={"Attachment"}>
                <a style={{cursor: "pointer", display: "flex", alignItems: "center"}} onClick={() => window.open(proof, "_blank")}>
                  <DocumentText /> Click to open file
                </a>
              </Label>
            </div>
          )
        }

        {button && (
          <ButtonGroup>
            <Button.Ghost hoverColor="red" onClick={button.onReject}>
              Reject
            </Button.Ghost>

            <Button.Classic
              onClick={button.onModify}
              // disabled={supervisorStatus === "pending"}
            >
              Approve
            </Button.Classic>
          </ButtonGroup>
        )}
      </Form>

      <Card.Statistic.Leave
        title="Leave Record"
        consumed={consumed}
        remaining={additionalLeave + annualEntitlement}
        leaves={[
          {
            legend: locale.en.myRequest.cardLegend.annLeave,
            color: "#E5429E",
            percentage: Balance
              ? Math.floor((annualEntitlement / total) * 100)
              : 0,
          },
          {
            legend: locale.en.myRequest.cardLegend.extraLeave,
            color: "#44C2DE",
            percentage: Balance
              ? Math.ceil((additionalLeave / total) * 100)
              : 0,
          },
          {
            legend: locale.en.myRequest.cardLegend.consumed,
            color: "#B9B9B9",
            percentage: Balance ? Math.floor((consumed / total) * 100) : 0,
          },
        ]}
        onViewClick={handleViewClick}
        year={year}
        setYear={setYear}
      />
    </Wrapper>
  );
};
