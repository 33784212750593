import React, { useState, useEffect } from 'react';
import { differenceInDays } from 'date-fns';

import type Holiday from 'types/holiday';
import useForm from 'hooks/common/useForm';

export default () => {
  const { form, setForm } = useForm<Holiday>({
    name: "",
    start: new Date(),
    end: new Date(),
    description: ""
  });

  const [dayDiff, setDiff] = useState<number>(0);

  useEffect(() => {
    const diff = differenceInDays(form.end, form.start);

    setDiff(diff >= 0 ? diff + 1 : diff);
  }, [form.start, form.end]);


  return { form, dayDiff, setForm }
}