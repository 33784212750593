import React, { useState } from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";
// import { CSVLink } from "react-csv";
import { CSVLink } from "react-csv";

import api from "api";
import useColumns from "./useColumns";
import useUserStore from "stores/useUserStore";

import MonthYearPicker, {
  Wrapper as _MonthYearPicker,
  CalendarContainer as _MonthYearPicker__CalendarContainer,
} from "components/common/MonthYearPicker";
import Table, { Wrapper as _Table } from "components/lib/Table";
import Button from "components/lib/Button";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 20rem;

  ${_MonthYearPicker} {
    width: 170rem;
  }

  ${_MonthYearPicker__CalendarContainer} {
    right: 0;
    left: unset;
  }

  ${_Table} {
    width: 100%;
    background: var(--container-bg-color);
    border-radius: var(--container-border-radius);
    padding: var(--container-padding);
  }
`;

export default () => {
  const user = useUserStore((state) => state.user);
  const [year, setYear] = useState<Date>(new Date());

  const yearNow = year.getFullYear().toString();

  const { data } = useQuery({
    queryKey: [api.leaveBalance.queryKey, yearNow, user!.id],
    queryFn: () => api.leaveBalance.list({ userUid: user!.id, year: yearNow }),
    enabled: !!user,
  });

  let excelData: any = [];
  data?.map((leave) => {
    let obj = {
      annualEntitlement: leave.balance[`${yearNow}`].annualEntitlement,
      currentToDate: leave.balance[`${yearNow}`].currentBalance,
      additionalLeave: leave.balance[`${yearNow}`].additionalLeave,
      consumed: leave.balance[`${yearNow}`].consumed,
      totalBalance: leave.balance[`${yearNow}`].totalCurrentBalance,
      projectedBalance: leave.balance[`${yearNow}`].projectedBalance,
    };
    excelData.push(obj);
  });

  const columns = useColumns(yearNow);
  const headers = [
    { label: "Annual Entitlement", key: "annualEntitlement" },
    { label: "Current Balance to date", key: "currentToDate" },
    { label: "Additional Leaves", key: "additionalLeave" },
    { label: "Consumed", key: "consumed" },
    { label: "Total Current Balance", key: "totalBalance" },
    { label: "Projected Balance", key: "projectedBalance" },
  ];
  return (
    <Wrapper>
      <MonthYearPicker level="year" value={year} onChange={setYear} />
      <Table data={data ?? []} columns={columns} />
      <CSVLink
        data={excelData}
        headers={headers}
        filename={`${user?.userName}.csv`}
      >
        <Button.Ghost>Export</Button.Ghost>
      </CSVLink>
    </Wrapper>
  );
};
