import { parseISO, format } from "date-fns";

import axios from "./axios";
import backend from "config/backend";
import { formatDate } from "config/common";

import type { Role as ClientRole } from "types/user";
import type { Status as ClientStatus } from "types/user";
import type User from "types/user";

import { list as listUser } from "../redux/features/users/userSlice";
// import { useDispatch } from "react-redux";

/**
 * Mapping
 */

// const dispatch = useDispatch();
type Status = "fullTime" | "partTime" | "temporary" | "seasonal";

export type Role = "user" | "superAdmin" | "hrAdmin";

type ServerSchema = {
  _id: string;
  uId: string;
  userId: string;
  organizationId: string;
  email: string;
  userName: string;
  firstName: string;
  lastName: string;
  contactNo: string;
  nationalId: string;
  dateOfBirth?: string;
  departmentId: string;
  designation: string;
  reportTo: string[];
  status: Status;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  address: string[];
  roles: Role[];
  workdays: string[];
  workdayDetails: string[];
  createdAt: string;
  updatedAt: string;
  reportToDetail?: ServerSchema[];
  profile: string;
};

type AppSchema = {
  code: string;
  label: string;
  link: string;
  image: string
}

function mapToReact(response: ServerSchema): User {
  const roles: ClientRole[] = [];
  response.roles.forEach((r) => {
    switch (r) {
      case "superAdmin":
        roles.push("superadmin");
        break;
      case "hrAdmin":
        roles.push("hr-admin");
        break;
      case "user":
        roles.push("user");
        break;
    }
  });

  let status: ClientStatus;
  switch (response.status) {
    case "fullTime":
      status = "full";
      break;
    case "partTime":
      status = "part";
      break;
    case "temporary":
      status = "temporary";
      break;
    case "seasonal":
      status = "seasonal";
      break;
  }

  let supervisors: { id: string; firstName: string; lastName: string }[] = [];
  response.reportToDetail?.forEach((user) => {
    supervisors.push({
      id: user.uId,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  });

  return {
    id: response.uId,
    firstName: response.firstName,
    lastName: response.lastName,
    userName: response.userName,
    email: response.email,
    contactNo: response.contactNo,
    nationalId: response.nationalId,
    dob: response.dateOfBirth ? formatDate(response.dateOfBirth) : null,
    departmentId: response.departmentId,
    designation: response.designation,
    supervisors,
    status,
    country: response.country,
    stateRegion: response.state,
    city: response.city,
    postalCode: response.postalCode,
    address: response.address,
    roles,
    workDayIds: response.workdays,
    workDayDetails: response.workdayDetails,
    thumbnail: response.profile,
  };
}

function mapToServer(user: Omit<User, "id">): Partial<ServerSchema> {
  const reportTo = user.supervisors.map((s) => s.id);

  let status: Status;
  switch (user.status) {
    case "full":
      status = "fullTime";
      break;
    case "part":
      status = "partTime";
      break;
    case "seasonal":
      status = "seasonal";
      break;
    case "temporary":
      status = "temporary";
      break;
  }

  let roles: Role[] = user.roles.map((r) => {
    switch (r) {
      case "superadmin":
        return "superAdmin";
      case "hr-admin":
        return "hrAdmin";
      case "user":
        return "user";
    }
  });

  return {
    firstName: user.firstName,
    lastName: user.lastName,
    userName: user.userName,
    email: user.email,
    contactNo: user.contactNo,
    nationalId: user.nationalId,
    dateOfBirth: user.dob ? format(user.dob!, backend.format.date) : undefined,
    departmentId: user.departmentId,
    designation: user.designation,
    reportTo,
    status: status,
    country: user.country,
    state: user.stateRegion,
    city: user.city,
    postalCode: user.postalCode,
    address: user.address,
    roles,
    workdays: user.workDayIds,
    workdayDetails: user.workDayDetails,
  };
}

/**
 * API Implementation
 */
const queryKey = "user";

async function list() {
  const response = await axios.get<{ data: ServerSchema[] }>(backend.user.list);

  const data: User[] = [];
  response.data.data.forEach((r) => {
    data.push(mapToReact(r));
  });
  // dispatch(listUser(data));
  return data;
}

async function getById(uId: string) {
  const url = backend.user.getById.replace("{{ 1 }}", uId);

  const response = await axios.get<{ data: ServerSchema }>(url);
  return mapToReact(response.data.data);
}

async function update(user: User) {
  const url = backend.user.update.replace("{{ 1 }}", user.id);
  const request = mapToServer(user);

  await axios.put(url, request);
}

async function create(user: Omit<User, "id">) {
  const request = mapToServer(user);

  await axios.post(backend.user.create, request);
}

async function revoke(user: User) {
  const url = backend.user.revoke.replace("{{1}}", user.id);
  const request = mapToServer(user);

  await axios.put(url, request);
}

async function getAllApps() {
  const response = await axios.get<{ data: AppSchema[] }>(backend.user.app);

  // dispatch(listUser(data));
  return response.data.data;
}

export default {
  queryKey,
  list,
  getById,
  update,
  create,
  revoke,
  getAllApps,
};
