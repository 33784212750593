import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 1/1;
  height: auto;
  width: auto;
`;

export default ({ color = "black" }: {
  color?: string
}) => (
  <Wrapper
    width="36" height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path fill={color} d="M19.5927 4.51238C19.2645 4.1843 18.8195 4 18.3554 4C17.8914 4 17.4464 4.1843 17.1182 4.51238L4.8682 16.7624C4.54942 17.0924 4.37303 17.5345 4.37702 17.9933C4.38101 18.4522 4.56505 18.8911 4.88952 19.2156C5.21398 19.54 5.6529 19.7241 6.11175 19.7281C6.57059 19.732 7.01264 19.5557 7.3427 19.2369L7.85545 18.7241V30.2496C7.85545 30.7138 8.03982 31.1589 8.36801 31.4871C8.6962 31.8153 9.14132 31.9996 9.60545 31.9996H13.1054C13.5696 31.9996 14.0147 31.8153 14.3429 31.4871C14.6711 31.1589 14.8554 30.7138 14.8554 30.2496V26.7496C14.8554 26.2855 15.0398 25.8404 15.368 25.5122C15.6962 25.184 16.1413 24.9996 16.6054 24.9996H20.1054C20.5696 24.9996 21.0147 25.184 21.3429 25.5122C21.6711 25.8404 21.8554 26.2855 21.8554 26.7496V30.2496C21.8554 30.7138 22.0398 31.1589 22.368 31.4871C22.6962 31.8153 23.1413 31.9996 23.6054 31.9996H27.1054C27.5696 31.9996 28.0147 31.8153 28.3429 31.4871C28.6711 31.1589 28.8554 30.7138 28.8554 30.2496V18.7241L29.3682 19.2369C29.6982 19.5557 30.1403 19.732 30.5991 19.7281C31.058 19.7241 31.4969 19.54 31.8214 19.2156C32.1458 18.8911 32.3299 18.4522 32.3339 17.9933C32.3379 17.5345 32.1615 17.0924 31.8427 16.7624L19.5927 4.51238Z" />
  </Wrapper>
)