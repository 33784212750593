import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

import locale from "locale";
import api from "api";

import Label from "components/lib/Label";
import Input from "components/lib/Input";
import EditPen, { Wrapper as _EditPen } from "components/svg/EditPen";
import Button from "components/lib/Button";
import useForm from "hooks/common/useForm";
import LeaveSetting from "types/leaveSetting";

const Wrapper = styled.div`
  --gap: 20rem;

  position: relative;
  max-width: 800rem;
  padding: var(--container-padding);
  padding-bottom: 30rem;

  margin: 0 auto;
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  ${_EditPen} {
    height: 20rem;
    align-self: end;
    cursor: pointer;
  }
`;

const Fields = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--gap);
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  gap: 10rem;
`;

export default () => {
  /**
   * Hooks
   */
  const [editMode, setEditMode] = useState(false);

  const { form, setForm } = useForm<LeaveSetting>({
    annualLeaveDefault: 12,
    additionalLeaveDefault: 2,
    perAnnualMonthGain: 1,
    annualLeaveMaxCarryOver: 6,
    additionalMaxCarryOver: 6
  });

  const queryClient = useQueryClient();

  const { data } = useQuery(api.leaveSetting.queryKey, api.leaveSetting.get);
  console.log(data)

  const mutation = useMutation(api.leaveSetting.update, {
    onSuccess: () => {
      queryClient.invalidateQueries(api.leaveSetting.queryKey);

      const message = locale.en.toastMessages.updateSuccess.replace(
        "{{ 1 }}",
        "Company information"
      );
      toast.success(message);

      setEditMode(false);
    },
  });

  useEffect(() => {
    data && setForm({ ...data });
  }, [data]);

  useEffect(() => {
    if (!editMode) handleReset();
  }, [editMode]);

  /**
   * Not hooks
   */
  const handleReset = () => {
    if (!data) return;
    setForm({ ...data });
  };

  const handleUpdate = () => {
    if (!editMode) return;
    mutation.mutate({ ...form });
  };

  /**
   * Render
   */
  return (
    <Wrapper>
      <EditPen
        color={editMode ? "var(--primary-color)" : "#ccc"}
        onClick={() => setEditMode(!editMode)}
      />

      <Fields>
        <Label title={locale.en.settings.leaveSetting.annualLeaveDefault}>
          <Input
            readOnly={!editMode}
            value={form.annualLeaveDefault}
            onChangeNumber={(s) => setForm({ annualLeaveDefault: s })}
          />
        </Label>

        <Label title={locale.en.settings.leaveSetting.additionalLeaveDefault}>
          <Input
            readOnly={!editMode}
            value={form.additionalLeaveDefault}
            onChangeNumber={(s) => setForm({ additionalLeaveDefault: s })}
          />
        </Label>

        <Label title={locale.en.settings.leaveSetting.perAnnualMonthGain}>
          <Input
            readOnly={!editMode}
            value={form.perAnnualMonthGain}
            onChangeNumber={(s) => setForm({ perAnnualMonthGain: s })}
          />
        </Label>

        <Label title={locale.en.settings.leaveSetting.annualLeaveMaxCarryOver}>
          <Input
            readOnly={!editMode}
            value={form.annualLeaveMaxCarryOver}
            onChangeNumber={(s) => setForm({ annualLeaveMaxCarryOver: s })}
          />
        </Label>

        <Label title={locale.en.settings.leaveSetting.additionalMaxCarryOver}>
          <Input
            readOnly={!editMode}
            value={form.additionalMaxCarryOver}
            onChangeNumber={(s) => setForm({ additionalMaxCarryOver: s })}
          />
        </Label>
      </Fields>

      {editMode && (
        <ButtonGroup>
          <Button.Ghost onClick={handleReset}>
            {locale.en.common.button.reset}
          </Button.Ghost>

          <Button.Classic onClick={handleUpdate}>
            {locale.en.common.button.update}
          </Button.Classic>
        </ButtonGroup>
      )}
    </Wrapper>
  );
};
