import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";

import api from "api";
import type LeaveType from "types/leaveType";
import useColumns from "./useColumns";

import Table from "components/lib/Table";
import Modal from "components/lib/Modal";
import DeleteLeaveType from "./DeleteLeaveType";
import { useDispatch } from "react-redux";
import { fetchLeaveTypes } from "redux/features/leaveType/leaveTypeSlice";

const Wrapper = styled.div`
  position: relative;
  padding: var(--container-padding);
  padding-top: 10rem;

  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);
`;

export default () => {
  /**
   * Hooks
   */
  const { data } = useQuery({
    queryKey: api.leaveType.queryKey,
    queryFn: () => api.leaveType.list(),
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [delLeaveType, setDelLeaveType] = useState<LeaveType>();

  const dispatch = useDispatch<any>();

  const columns = useColumns((d: LeaveType) => {
    setDelLeaveType(d);
    setModalVisible(true);
  });

  useEffect(() => {
    dispatch(fetchLeaveTypes());
  }, []);

  /**
   * Render
   */

  return (
    <>
      <Wrapper>
        <Table data={data ?? []} columns={columns} pageSize={5} />

        <Modal visible={modalVisible} setVisible={setModalVisible}>
          <DeleteLeaveType
            setModalVisible={setModalVisible}
            leaveType={[delLeaveType, setDelLeaveType]}
          />
        </Modal>
      </Wrapper>
    </>
  );
};
