import React, { useState } from 'react';
import type Department from 'types/department';

export type Setter = <T extends keyof Omit<Department, "id">>(k: T, v: Department[T]) => void

export default () => {
  /**
   * Hooks
   */
  const form: { [T in keyof Department]: [Department[T], React.Dispatch<React.SetStateAction<Department[T]>>] } = {
    name: useState(""),
    description: useState(""),
    isActive: useState<boolean>(true)
  }

  /**
   * Not hook
   */
  const setForm = <T extends keyof Omit<Department, "id">>(key: T, value: Department[T]) => {
    form[key][1](value);
  }

  /**
   * Return
   */
  return {
    name: form.name[0],
    description: form.description[0],
    isActive: form.isActive[0],

    setForm
  }
}