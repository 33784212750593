import create from "zustand";
import User from 'types/user';

import { Role as UserRole } from "types/user";

export type Role = "admin" | "user";

export function getRole(r: UserRole[]): Role {
  if (r.includes("superadmin") || r.includes("hr-admin"))
    return "admin";
  else
    return "user";
}

export function mapRole(r: UserRole): Role {
  switch (r) {
    case "hr-admin":
    case "superadmin":
      return "admin";
    case "user":
      return "user";
  }
}

type StoreSchema = {
  role: Role,
  setRole: (r: Role) => void,

  user?: User,
  setUser: (u: User | undefined) => void
}

export default create<StoreSchema>(set => ({
  role: "user",
  setRole: r => set({ role: r }),

  user: undefined,
  setUser: u => set({ user: u })
}));