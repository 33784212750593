import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";

import { Option } from "./types";
import Button from "./components/Button";
import DropdownList from "./components/DropdownList";

const Wrapper = styled.div`
  display: flex;
  gap: 5rem;
`;

export default ({
  options,
  max,
  actives,
  onChange,
}: {
  options: Option[];
  max: number;
  actives: string[]; //Option ID
  onChange?: (s: string[]) => void;
}) => {
  /**
   * Not hooks
   */
  const visibleOptions = options.slice(0, max);
  const prunedOptions = options.slice(max);

  const sort = (ids: string[]) => {
    const array = Object.values(options).map((v) => v.id);

    ids.sort((a, b) => array.indexOf(a) - array.indexOf(b));
  };

  const handleButtonClick = (value: string) => {
    if (onChange) {
      const index = actives.indexOf(value);
      let clone = [...actives];

      if (index !== -1) {
        //If item doesnt exist, remove from array
        const firstHalf = clone.slice(0, index);
        const secondHalf = clone.slice(index + 1);

        clone = [...firstHalf, ...secondHalf];
      } else {
        //Else, just push
        clone.push(value);
      }

      sort(clone);
      onChange(clone);
    }
  };

  /**
   * Render
   */
  return (
    <Wrapper>
      {visibleOptions.map((item, i) => (
        <Button
          key={i}
          onClick={() => handleButtonClick(item.id)}
          active={actives.includes(item.id)}
        >
          {item.display}
        </Button>
      ))}

      {prunedOptions.length > 0 && (
        <DropdownList
          actives={actives}
          options={prunedOptions}
          onSelectClick={handleButtonClick}
        />
      )}
    </Wrapper>
  );
};
