import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

export default () => {

  const firstTime = useRef(true);

  const ref = {
    hover: useRef<HTMLSpanElement>(null),
    popup: useRef<HTMLDivElement>(null)
  }

  const [hover, setHover] = useState(false);

  useEffect(() => {
    const mouseOver = () => { setHover(true) }
    const mouseLeave = () => { setHover(false) }

    ref.hover.current?.addEventListener("mouseover", mouseOver);
    ref.hover.current?.addEventListener("mouseleave", mouseLeave);

    return () => {
      ref.hover.current?.removeEventListener("mouseover", mouseOver);
      ref.hover.current?.removeEventListener("mouseleave", mouseLeave);
    }
  }, []);


  useEffect(() => {
    gsap.to(ref.popup.current, {
      duration: firstTime.current ? 0 : 0.25,
      ease: "back.out",
      transformOrigin: "center bottom",
      scale: hover ? 1 : 0,
      autoAlpha: hover ? 1 : 0
    });

    firstTime.current = false;
  }, [hover]);

  return ref;
}