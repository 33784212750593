import corporate from "./corporate";
import department from "./department";
import holiday from "./holiday";
import user from "./user";
import workingDay from "./workingDay";
import geolocation from "./geolocation";
import leaveBalance from "./leaveBalance";
import auth from "./auth";
import leave from "./leave";
import audit from "./audit";
import adjustmentHistory from "./adjustment";
import leaveType from "./leaveType";
import leaveSetting from "./leaveSetting";

export default {
  corporate,
  department,
  holiday,
  user,
  workingDay,
  geolocation,
  leaveBalance,
  auth,
  leave,
  audit,
  adjustmentHistory,
  leaveType,
  leaveSetting
}