import Main from "./variants/Main";
import View from "./variants/View";
import New from "./variants/New";
import Calendar from "./variants/Calendar";
import Edit from "./variants/Edit";

export default {
  Main,
  View,
  New,
  Calendar,
  Edit,
};
