import axios from './axios';
import backend from 'config/backend';

export type ServerSchema = {
  name: string,
  state_code?: string,
  country_code: string
}

//For react-query
const queryKey = {
  country: "country",
  region: "region",
  city: "city"
}

async function getCountries() {
  const response = await axios.get<{ data: ServerSchema[] }>(backend.geolocation);
  return response.data.data;
}

async function getStates(country_code: string) {
  const response = await axios.get<{ data: Required<ServerSchema>[] }>(backend.geolocation, {
    params: {
      country_code
    }
  });

  return response.data.data;
}

async function getCities(country_code: string, state_code: string) {
  const response = await axios.get<{ data: Required<ServerSchema>[] }>(backend.geolocation, {
    params: {
      country_code,
      state_code
    }
  });

  return response.data.data;
}

export default {
  queryKey,
  getCountries,
  getStates,
  getCities
}

