import React, { useEffect, useState } from 'react';
import { differenceInDays, isSameDay, endOfDay,startOfDay } from 'date-fns';

import type { FormState } from '.';
import useForm from 'hooks/common/useForm';

export default () => {
  const { form, setForm } = useForm<FormState>({
    type: "",
    deductOn: "",
    start: new Date(),
    startAPM: "AM",
    end: new Date(),
    endAPM: "PM",
    leaveReason: "",
    approvers: [],
    recipients: []
  });

  const [dayDiff, setDiff] = useState<number>(0);

  useEffect(() => {
    if (form.start && form.end) {
      let diff = differenceInDays(endOfDay(form.end), startOfDay(form.start));
      console.log(diff,"A")
      if (diff >= 0) {
        diff += 1
      }
      if (form.startAPM === form.endAPM) {
        if (isSameDay(form.start, form.end)) {
          diff -= 0.5
        } else {
          if (diff > 1) {
            diff -= 0.5
          }
        }
      }
      setDiff(diff);
    }
  }, [form.start, form.end, form.startAPM, form.endAPM]);


  return { form, dayDiff, setForm };
}