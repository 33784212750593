export default {
  date: {
    start: "Start time",
    end: "End time",
    placeholder: "Select date"
  },
  button: {
    submit: "Submit",
    send: "Send",
    yes: "Yes",
    cancel: "Cancel",
    viewDetail: "View Details",
    new: "New",
    delete: "Delete",
    create: "Create",
    reset: "Reset",
    update: "Update",
    confirm: "Confirm"
  },
  statCard: {
    consumed: "Consumed",
    remaining: "Remaining",
    leaveRecord: "Leave Record"
  },
  prunableCheckbox: {
    pruneText: "More"
  },
  csc: {
    country: "Country",
    stateRegion: "State/Region",
    city: "City"
  },
  address: {
    label: "Address",
    add: "Add another address"
  },
  select: {
    placeholder: "Please select"
  },
  logout: "Logout",
  leaveDuration: "{{ 1 }} day(s)",
  formValidation: {
    required: "Required",
  }
}