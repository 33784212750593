import React from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";

import type Holiday from "types/holiday";
import type { Setter } from "hooks/common/useForm";
import { getLeaveDuration } from "utils";

import Button from "components/lib/Button";
import Label from "components/lib/Label";
import Input from "components/lib/Input";
import TextArea from "components/lib/TextArea";
import DateRangePicker from "components/common/DateRangePicker";
import XWithRing, { Wrapper as _XWithRing } from "components/svg/XWithRing";
import locale from "locale";

const Wrapper = styled.div`
  --gap: 20rem;

  position: relative;
  max-width: var(--form-max-width);
  padding: var(--container-padding);
  padding-top: 35rem;

  margin: 0 auto;
  background: var(--container-bg-color);
  border-radius: var(--container-border-radius);

  display: flex;
  flex-direction: column;
  gap: var(--gap);

  & > ${_XWithRing} {
    position: absolute;
    top: 12rem;
    right: 12rem;
    height: 20rem;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: var(--gap);
`;

const ButtonGroup = styled.div`
  margin-top: 20rem;
  align-self: end;

  display: flex;
  gap: 10rem;
`;

export default ({
  holiday,
  setForm,
  dayDiff,
  okText,
  onOk,
  cancelText,
  onCancel,
}: {
  holiday: Holiday;
  setForm: Setter<Holiday>;
  dayDiff: number;

  okText: string;
  onOk?: () => void;

  cancelText: string;
  onCancel?: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <Wrapper>
      <XWithRing color="var(--primary-color)" onClick={() => navigate(-1)} />

      <FlexContainer>
        <Label title={locale.en.settings.holiday.form.name}>
          <Input value={holiday.name} onChange={(s) => setForm({ name: s })} />
        </Label>

        <Label title={locale.en.settings.holiday.form.duration}>
          <Input value={getLeaveDuration(dayDiff)} readOnly />
        </Label>
      </FlexContainer>

      <DateRangePicker
        start={{
          value: holiday.start,
          onChange: (d) => d && new Date() < d && setForm({ start: d }),
          // readOnly: new Date() < holiday.start ? true : false,
        }}
        end={{ value: holiday.end, onChange: (d) => d && setForm({ end: d }) }}
      />

      <Label title={locale.en.settings.holiday.form.description}>
        <TextArea
          value={holiday.description}
          onChange={(s) => setForm({ description: s })}
        />
      </Label>

      <ButtonGroup>
        <Button.Ghost onClick={onCancel}>{cancelText}</Button.Ghost>

        <Button.Classic onClick={onOk}>{okText}</Button.Classic>
      </ButtonGroup>
    </Wrapper>
  );
};
