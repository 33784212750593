import type ClientUser from "types/user";
import useForm, { Setter as _Setter } from "hooks/common/useForm";

type User = Omit<ClientUser, "id">;

export interface Setter extends _Setter<User> {}

export default () => {
  const { form, setForm } = useForm<User>({
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    contactNo: "",
    nationalId: "",
    dob: null,
    departmentId: "",
    designation: "",
    status: "full",
    supervisors: [],
    country: "",
    stateRegion: "",
    city: "",
    postalCode: "",
    address: [],
    roles: [],
    workDayIds: [],
    workDayDetails: [],
  });

  return { form, setForm };
};
