import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { CSVLink } from "react-csv";

import mock from "../mock-data";

import SearchInput from "components/common/SearchInput";
import Button from "components/lib/Button";
import MonthYearPicker, {
  CalendarContainer as _MonthYearPicker__CalendarContainer,
} from "components/common/MonthYearPicker";
import PrunableCheckbox from "components/common/PrunableCheckbox";
import Department from "types/department";
import type { Option } from "components/common/PrunableCheckbox/types";
import { useQuery } from "react-query";
import api from "api";
import useUserStore from "stores/useUserStore";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import backend from "config/backend";

const Wrapper = styled.header`
  --gap: 10rem;

  & > * {
    display: flex;
    align-items: center;
    gap: var(--gap);
  }
`;

const Top = styled.div`
  & > h2 {
    flex-grow: 1;
  }
`;

const Bottom = styled.div`
  margin-top: 10rem;
  margin-bottom: 15rem;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
    gap: var(--gap);
  }

  ${_MonthYearPicker__CalendarContainer} {
    left: unset;
    right: 0;
  }
`;

export default ({
  date,
  setDate,
  showDept,
  setShowDept,
  search,
  setSearch,
}: {
  date: Date;
  setDate: (d: Date) => void;
  showDept: string[];
  setShowDept: (d: any) => void;
  search: string;
  setSearch: (d: string) => void;
}) => {
  // const [showDept, setShowDept] = useState<string[]>([]);
  // const [search, setSearch] = useState("");
  const user = useUserStore((state: any) => state.user);

  const { data: deptData } = useQuery({
    queryKey: api.department.queryKey,
    queryFn: () => api.department.list(),
  });
  const prunables: Option[] =
    deptData?.map((d) => ({ display: d.name, id: d.id! })) || [];
  useEffect(() => {
    if (deptData && deptData.length) {
      setShowDept(deptData.map((d) => d.id!));
    }
  }, [deptData]);

  let copyArray: any = [];
  deptData?.map((item) => {
    let obj = {
      id: item.id,
      display: item.name,
      others: [item.description, item.isActive],
    };
    copyArray.push(obj);
  });

  const { chartLeaves } = useSelector((state: any) => state.leave);

  const headers = [
    { label: "username", key: "userName" },
    { label: "supervisor", key: "supervisor" },
    { label: "type of leave", key: "leaveType" },
    { label: "start time", key: "startDate" },
    { label: "end time", key: "endDate" },
    { label: "leave duration", key: "duration" },
    { label: "status", key: "status" },
  ];
  let excelData: any = [];
  chartLeaves.map((leave: any) => {
    const obj = {
      userName: leave.userId.userName,
      supervisor: leave.supervisor.userId.userName,
      leaveType: leave.typeName,
      startDate: format(leave.start, backend.format.date),
      endDate: format(leave.end, backend.format.date),
      duration: leave.duration,
      status: "approved",
    };
    excelData.push(obj);
  });

  return (
    <Wrapper>
      <Top>
        <h2>Movement Chart</h2>

        <SearchInput value={search} onChange={setSearch} />
        <CSVLink
          data={excelData}
          headers={headers}
          filename={`${user?.userName}.csv`}
        >
          <Button.Ghost>Export</Button.Ghost>
        </CSVLink>
      </Top>

      <Bottom>
        <div>
          <h3>Department</h3>
          {/* <PrunableRadio options={mock.departments} max={4} /> */}
          <PrunableCheckbox
            options={prunables}
            max={4}
            actives={showDept}
            onChange={setShowDept}
          />
        </div>

        <MonthYearPicker level="month" value={date} onChange={setDate} />
      </Bottom>
    </Wrapper>
  );
};
