import api from "api";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchLeaveById } from "redux/features/leave/leaveSlice";

import Form from "../Form";

export default () => {
  const { id } = useParams();

  const [name, setName] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [start, setStart] = useState<Date>();
  const [end, setEnd] = useState<Date>();
  const [type, setType] = useState("");
  const [typeName, setTypeName] = useState("");
  const [duration, setDuration] = useState(0);
  const [reason, setReason] = useState("");
  const [comments, setComments] = useState("");
  const [deductOn, setDeductOn] = useState("");
  const [proof, setProof] = useState("");
  const [leaveBalance, setLeaveBalance] = useState<any>([]);
  const [supervisorStatus, setHrStatus] = useState<any>([]);

  const fetchById = async (id: any) => {
    const res: any = await api.leave.getById(id);
    const res1 = await api.leaveBalance.list({ userUid: res.userId?.uId });
    setLeaveBalance(res1);
    setName(res?.userId?.userName);
    setSupervisor(`${res.approvers[0].firstName} ${res.approvers[0].lastName}`);
    setEnd(res.end);
    setStart(res.start);
    setType(res.type);
    setTypeName(res.typeName);
    setDuration(res.duration);
    setReason(res.leaveReason);
    setProof(res.proof);
    setComments(res.approverComment);
    setDeductOn(res.deductOn);
    setHrStatus(res.hr.status);
  };

  useEffect(() => {
    fetchById(id);
  }, [id]);

  return (
    <Form
      name={[name, setName]}
      supervisor={[supervisor, setSupervisor]}
      start={[start, setStart]}
      end={[end, setEnd]}
      type={[type, setType]}
      typeName={[typeName, setTypeName]}
      duration={[duration, setDuration]}
      reason={[reason, setReason]}
      comments={[comments, setComments]}
      deductOn={[deductOn, setDeductOn]}
      proof={proof}
      balance={leaveBalance}
      supervisorStatus={supervisorStatus}
    />
  );
};
