import styled from "styled-components/macro";

export const Title = styled.h3`
  font-weight: 600;
  font-size: 18rem;
  margin-bottom: 10rem;
`;

export const Fields = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 20rem;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;

  & > div:not(:first-of-type) {
    margin-top: var(--gap);
  }
`;