import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';

import api from 'api';
import type AdjustmentHistory from 'types/adjustmentHistory';
import type { Column } from 'components/lib/Table/types';

export default () => {
  const [columns, setColumns] = useState<Column<AdjustmentHistory>[]>([]);

  const { data: deptData } = useQuery({
    queryKey: [api.department.queryKey, "active"],
    queryFn: () => api.department.list("active")
  })

  useEffect(() => {
    const columns: Column<AdjustmentHistory>[] = [
      {
        title: "Date",
        render: r => r.createdAt
      },
      {
        title: "Balance Owner",
        render: r => r.balanceOwner
      },
      {
        title: "Subject",
        render: r => r.data.field
      },
      {
        title: "Year",
        render: r => r.data.year
      },
      {
        title: "Adjustment",
        render: r => r.data.value
      },
      {
        title: "Operator",
        render: r => r.operator.userName
      }
    ];

    setColumns(columns);
  }, [deptData]);

  return columns;
}