import React from 'react';
import styled from 'styled-components/macro';

export const Wrapper = styled.svg`
  aspect-ratio: 1/1;
  width: auto;
  height: auto;
`;

export default ({ color = "black", onClick }: {
  color?: string,
  onClick?: () => void
}) => {
  return (
    <Wrapper
      width={25} height={25}
      viewBox="0 0 25 25"
      onClick={onClick}
    >
      <path fill={color} d="M12.383 14.2734C7.37303 14.2734 3.29303 17.6334 3.29303 21.7734C3.29303 22.0534 3.51303 22.2734 3.79303 22.2734H20.973C21.253 22.2734 21.473 22.0534 21.473 21.7734C21.473 17.6334 17.393 14.2734 12.383 14.2734Z" />
      <path fill={color} d="M17.2331 6.07346C17.1131 5.58346 16.9131 5.10346 16.6331 4.66346C16.4431 4.34346 16.1931 4.02346 15.9231 3.74346C15.0231 2.84346 13.8531 2.35346 12.6531 2.29346C11.2931 2.20346 9.90308 2.70346 8.85308 3.74346C7.86308 4.72346 7.36308 6.03346 7.38308 7.35346C7.39308 8.60346 7.89308 9.85346 8.84308 10.8135C9.50308 11.4735 10.3131 11.9135 11.1831 12.1135C11.6531 12.2335 12.1531 12.2935 12.6531 12.2535C13.8431 12.2035 15.0031 11.7335 15.9131 10.8135C17.2031 9.52346 17.6431 7.71346 17.2331 6.07346ZM14.3831 9.27346C14.0231 9.63346 13.4231 9.63346 13.0631 9.27346L12.3731 8.58346L11.7131 9.24346C11.3531 9.60346 10.7531 9.60346 10.3931 9.24346C10.0231 8.87346 10.0231 8.28346 10.3831 7.92346L11.0431 7.26346L10.4031 6.64346C10.0431 6.27346 10.0431 5.68346 10.4031 5.30346C10.7731 4.94346 11.3631 4.94346 11.7431 5.30346L12.3631 5.94346L13.0331 5.27346C13.3931 4.91346 13.9831 4.91346 14.3531 5.27346C14.7131 5.63346 14.7131 6.23346 14.3531 6.59346L13.6931 7.25346L14.3831 7.95346C14.7431 8.31346 14.7431 8.91346 14.3831 9.27346Z" />
    </Wrapper>
  );
}