import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import api from "api";
import localKey from "config/localStorage";
import type { Key } from "config/localStorage";
import type { Role as UserRole } from "types/user";
import { getRole } from "stores/useUserStore";
import useAuthStore from "stores/useAuthStore";
import useUserStore from "stores/useUserStore";

function determineRole(localRole: string | null, roles: UserRole[]) {
  if (
    localRole === "admin" &&
    (roles.includes("superadmin") || roles.includes("hr-admin"))
  )
    return "admin";
  else if (localRole === "user" && roles.includes("user")) return "user";
  else return getRole(roles);
}

export default (setHasLogin: (b: boolean) => void, cacheLocal: boolean) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const setAuth = useAuthStore((state) => state.setAuth);
  const setUser = useUserStore((state) => state.setUser);
  const setRole = useUserStore((state) => state.setRole);

  const store: { [k in Key]: string } = {
    tokenUser: useAuthStore((state) => state.tokenUser),
    orgId: useAuthStore((state) => state.orgId),
    uId: useAuthStore((state) => state.uId),
    path: useAuthStore((state) => state.path),
    role: useAuthStore((state) => state.role),
  };

  const local = {
    tokenUser: window.localStorage.getItem(localKey.tokenUser),
    orgId: window.localStorage.getItem(localKey.orgId),
    uId: window.localStorage.getItem(localKey.uId),
    path: window.localStorage.getItem(localKey.path),
    role: window.localStorage.getItem(localKey.role),
  };

  //Writing to localStorage
  useEffect(() => {
    if (!cacheLocal) {
      const clearLocalStorage = () => {
        window.localStorage.clear();
      };

      // window.addEventListener("beforeunload", clearLocalStorage);
      return () => {
        window.removeEventListener("beforeunload", clearLocalStorage);
      };
    }
  }, [cacheLocal]);

  //Writing to localStorage
  useEffect(() => {
    for (const [k, v] of Object.entries(store)) {
      v && window.localStorage.setItem(k, v);
    }
  }, [store.tokenUser, store.orgId, store.path, store.role, store.uId]);

  useEffect(() => {
    if (
      local.tokenUser &&
      local.orgId &&
      local.uId &&
      !store.tokenUser &&
      !store.orgId
    ) {
      let path = "/";

      if (pathname === "/" && local.path) {
        path = local.path;
      } else if (pathname !== "/") {
        path = pathname;
      }

      setAuth({
        tokenUser: local.tokenUser,
        orgId: local.orgId,
        uId: local.uId,
        path,
      });

      //Send a preflight to verify whether the JWT is true or not
      api.user.getById(local.uId).then((user) => {
        const role = determineRole(local.role, user.roles);

        setRole(role);
        setUser(user);

        setAuth({ role });

        setHasLogin(true);
        navigate(path);
      });
    }
  }, []);
};
