import React from 'react';
import styled from 'styled-components/macro';

const Wrapper = styled.svg`
  aspect-ratio: 34/34;
  width: auto;
  height: auto;
`;

export default ({ color = "black" }: {
  color?: string
}) => (
  <Wrapper
    width={34} height={34}
    viewBox="0 0 34 34"
    fill="none"
  >
    <path fill={color} d="M24.8393 22.5267L19.2843 17.4805H15.558L10.003 22.5267C8.44926 23.9292 7.94051 26.088 8.69676 28.0405C9.45301 29.9792 11.2955 31.2305 13.3718 31.2305H21.4705C23.5605 31.2305 25.3893 29.9792 26.1455 28.0405C26.9018 26.088 26.393 23.9292 24.8393 22.5267ZM19.9305 25.923H14.9255C14.403 25.923 13.9905 25.4967 13.9905 24.988C13.9905 24.4792 14.4168 24.053 14.9255 24.053H19.9305C20.453 24.053 20.8655 24.4792 20.8655 24.988C20.8655 25.4967 20.4393 25.923 19.9305 25.923Z" />
    <path fill={color} d="M26.1599 6.92047C25.4036 4.98172 23.5611 3.73047 21.4849 3.73047H13.3724C11.2961 3.73047 9.45363 4.98172 8.69738 6.92047C7.95488 8.87297 8.46363 11.0317 10.0174 12.4342L15.5724 17.4805H19.2986L24.8536 12.4342C26.3936 11.0317 26.9024 8.87297 26.1599 6.92047ZM19.9311 10.9217H14.9261C14.4036 10.9217 13.9911 10.4955 13.9911 9.98672C13.9911 9.47797 14.4174 9.05172 14.9261 9.05172H19.9311C20.4536 9.05172 20.8661 9.47797 20.8661 9.98672C20.8661 10.4955 20.4399 10.9217 19.9311 10.9217Z" />
  </Wrapper>
)