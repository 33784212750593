import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useQuery } from "react-query";

import locale from "locale";
import routes from "config/routes";
import api from "api";
import type User from "types/user";
import type { Option } from "components/common/PrunableCheckbox/types";
import { generateRegex } from "components/common/SearchInput/utils";
import useNavStore from "stores/useNavStore";
import useColumns from "./useColumns";

import Table from "components/lib/Table";
import SearchInput from "components/common/SearchInput";
import UserAdd, { Wrapper as _UserAdd } from "components/svg/UserAdd";
import Modal from "components/lib/Modal";
import RevokeAccess from "./RevokeAccess";
import PrunableCheckbox from "components/common/PrunableCheckbox";
import { useDispatch } from "react-redux";
import { listUsersAsync } from "redux/features/users/userSlice";

const Wrapper = styled.div`
  background: var(--container-bg-color);
  padding: var(--container-padding);
  border-radius: var(--container-border-radius);
`;

const Header = styled.header`
  margin-bottom: 20rem;

  display: flex;
  align-items: start;
  gap: 10rem;

  ${_UserAdd} {
    height: 22rem;
    align-self: center;
    cursor: pointer;
  }
`;

const Title = styled.h2`
  flex-grow: 1;
`;

export default () => {
  /**
   * Hooks
   */
  const navigate = useNavStore((state) => state.navigate);

  const { data: userData, refetch: refetchUser } = useQuery({
    queryKey: api.user.queryKey,
    queryFn: api.user.list
  });

  const { data: deptData } = useQuery({
    queryKey: [api.department.queryKey, "active"],
    queryFn: () => api.department.list("active"),
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [revokeUser, setRevokeUser] = useState<User>();

  const columns = useColumns((u: User) => {
    setRevokeUser(u);
    setModalVisible(true);
  });

  //Filter
  const [showDept, setShowDept] = useState<string[]>([]);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (deptData && deptData.length) {
      setShowDept(deptData.map((d) => d.id!));
    }
  }, [deptData]);

  /**
   * Not hooks
   */
  const handleAddClick = () => {
    navigate && navigate(routes.users.routes!.new);
  };

  const prunables: Option[] =
    deptData?.map((d) => ({ display: d.name, id: d.id! })) || [];

  const searchRegex = generateRegex(search);

  const filteredData = userData?.filter((d) => {
    const matchDept =
      showDept.includes(d.departmentId) || d.departmentId === "";
    const matchSearch = `${d.firstName} ${d.lastName}`.match(searchRegex);

    return matchDept && matchSearch;
  });

  // console.log("showdepartment", showDept);

  useEffect(() => {
    dispatch(listUsersAsync());
  }, [dispatch]);

  /**
   * Render
   */
  return (
    <>
      <Wrapper>
        <Header>
          <Title>{locale.en.users.title}</Title>

          <PrunableCheckbox
            options={prunables}
            max={5}
            actives={showDept}
            onChange={setShowDept}
          />

          <SearchInput value={search} onChange={setSearch} />

          <UserAdd onClick={handleAddClick} />
        </Header>

        <Table data={filteredData ?? []} columns={columns} pageSize={7} />
      </Wrapper>

      <Modal visible={modalVisible} setVisible={setModalVisible}>
        <RevokeAccess
          setModalVisible={setModalVisible}
          refetchUser={refetchUser}
          revokeUser={revokeUser}
        />
      </Modal>
    </>
  );
};
